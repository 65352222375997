import React, { useEffect, useState } from "react";
import {
  sortCaret,
  headerSortingClasses, post,
} from '../../../../helpers';
import * as uiHelpers from "../../UiHelpers";
import { AddressColumnFormatter } from "../../../Addresses/address-table/column-formatters";
import { DataTable, DefaultStatusColumnFormatter } from "../../../../components";

const CompanyAddressTable = ({ company_id }) => {
  const [queryParams, setQueryParams] = useState({ filter: {
    name: "",
    company_id,
  },
  sortOrder: "asc", // asc||desc
  sortField: "id",
  pageNumber: 1,
  pageSize: 10 });
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [total_count, setTotalCount] = useState([]);
  const handleTableChange = (type, { page, sizePerPage }) => {
    const pageNumber = page || 1;
    setQueryParams({ ...queryParams, pageNumber: pageNumber, pageSize: sizePerPage });
  };
  useEffect(() => {
    const getOrders = async () => {
      setLoading(true);
      const { addresses, total_count, status } = await post('/address/admin/list', { ...queryParams });
      setLoading(false);
      if(!status){
        return;
      }
      setTotalCount(total_count);
      setData(addresses);

    };
    getOrders();
  }, [queryParams]);

  const columns = [
    {
      dataField: "id",
      text: "ID",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      style: {
        width: "40px",
      },
    },
    {
      dataField: "title",
      text: "",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "name",
      text: "Adres",
      formatter: AddressColumnFormatter,
    },
    {
      dataField: "phone1",
      text: "Tel",
    },
    {
      dataField: "city.name",
      text: "İl",
    },
    {
      dataField: "county.name",
      text: "İlçe",
    },
    {
      dataField: "is_active",
      text: "Durum",
      formatter: DefaultStatusColumnFormatter,
      style: {
        width: "60px",
      },
    },
  ];
  const paginationOptions = {
    custom:true,
    totalSize: total_count,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: queryParams.pageSize,
    page: queryParams.pageNumber,
  };
  return (
    <div className="card card-custom">
      <div className="card-header py-3">
        <div className="card-title align-items-start flex-column">
          <h3 className="card-label font-weight-bolder text-dark">
              Adresler
          </h3>
          <span className="text-muted font-weight-bold font-size-sm mt-1">
            Firma adreslerini görüntüleyin
          </span>
        </div>
      </div>
      <div className="card-body" style={{ padding: '1rem 2.25rem' }}>
        <DataTable
          data={data}
          defaultSorted={uiHelpers.defaultSorted}
          columns={columns}
          paginationOptions={paginationOptions}
          handleTableChange={handleTableChange}
          listLoading={loading} />
      </div>
    </div>
  );
};
export default CompanyAddressTable;
