import React, { useEffect, useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { ChatBar, ChatContent } from "./pages";
import { initialFilter } from "../Users/UiHelpers";
import { post } from "../../helpers";

const MessagePage = () => {
  const [queryParams, setQueryParams] = useState(initialFilter);
  const [loading, setLoading] = useState(false);
  const [conversations, setConversations] = useState([]);
  const getConversation = async () => {
    const { conversations } = await post('/conversation/admin/list', { ...queryParams });
    setConversations(conversations);
    setLoading(false);
  };

  useEffect(() => {
    getConversation();
  }, [queryParams]);

  const _handleChange = (e) => {
    queryParams.filter.user_name = e.target.value;
    setQueryParams({ ...queryParams });
  };
  const _handleChatContent = () => <ChatContent/>;
  return (
    <div className="d-flex flex-row">
      <ChatBar conversations={conversations} _handleChange={_handleChange}/>
      <div className="offcanvas-mobile-overlay" />
      {conversations.length > 0 &&
          <Switch>
            <Route path="/user_management/messages/:id" render={_handleChatContent} />
            <Redirect from="/user_management/messages" exact={true} to={"/user_management/messages/"+conversations[0].user.id}/>
          </Switch>
      }
    </div>
  );
};
export default MessagePage;
