import React from "react";
import { RoleUIProvider } from './UIContext';
import { Route, useHistory } from 'react-router-dom';
import RoleCard from './RoleCard';
import { RoleAdd, RoleDeleteDialog } from './dialog';

const RolePage = () => {
  const { push } = useHistory();
  const _onHide = () => push("/user_management/roles");
  const _handleAddModal = ({ match }) => <RoleAdd show={match != null} onHide={_onHide}/>;
  const _handleEditModal = ({ match }) => <RoleAdd show={match != null} id={match && match.params.id} onHide={_onHide}/>;
  const _handleDeleteModal = ({ match }) => <RoleDeleteDialog show={match != null} id={match && match.params.id} onHide={_onHide} />;

  return (
    <RoleUIProvider>
      <Route path="/user_management/roles/new" render={_handleAddModal} />
      <Route path="/user_management/roles/:id/edit" render={_handleEditModal} />
      <Route path="/user_management/roles/:id/delete" render={_handleDeleteModal}/>
      <RoleCard />
    </RoleUIProvider>
  );
};
export default RolePage;
