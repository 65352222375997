import React from "react";
import {
  ProductTypeStockTypeClasses,
  ProductTypeStockTypeTitles
} from '../../UiHelpers';


const StatusColumnFormatter = (cellContent, row) => {
  return (
    <span><span className={"label label-dot mr-2 label-"+ProductTypeStockTypeClasses[row.stock_type]}/><span
      className={"font-weight-bold text-"+ProductTypeStockTypeClasses[row.stock_type]}>{ProductTypeStockTypeTitles[row.stock_type]}</span></span>
  );
};
export default StatusColumnFormatter;
