import React, { createContext, useContext, useState, useCallback } from "react";
import { isEqual, isFunction } from "lodash";
import { initialFilter } from './CampaignUiHelpers';

const CampaignUIContext = createContext();

export function useCampaignUIContext() {
  return useContext(CampaignUIContext);
}

export function CampaignUIProvider({ campaignUIEvents, children }) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [state, setState] = useState({ campaigns:[], total_count:0 });
  const [error, setError] = useState(null);
  const [listLoading, setListLoading] = useState(false);
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);
  const initCampaign = {
    id: undefined,
    name: "",
    order: undefined,
    start_date: new Date(),
    end_date: new Date(),
  };
  const value = {
    queryParams,
    setQueryParamsBase,
    setQueryParams,
    initCampaign,
    state,
    setState,
    error,
    setError,
    listLoading,
    setListLoading,
  };

  return (
    <CampaignUIContext.Provider value={value}>
      {children}
    </CampaignUIContext.Provider>
  );
}
