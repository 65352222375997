import React, { createContext, useContext, useState, useCallback } from "react";
import { isEqual, isFunction } from "lodash";
import { initialFilter } from './UiHelpers';

const UIContext = createContext();

export function useProductAttributeUIContext() {
  return useContext(UIContext);
}

export function ProductAttributeUIProvider({ children }) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [state, setState] = useState({ productAttributes:[], total_count:0 });
  const [error, setError] = useState(null);
  const [listLoading, setListLoading] = useState(false);
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);
  const initProductAttribute = {
    id: undefined,
    name: "",
  };
  const value = {
    queryParams,
    setQueryParamsBase,
    setQueryParams,
    initProductAttribute,
    state,
    setState,
    error,
    setError,
    listLoading,
    setListLoading,
  };

  return (
    <UIContext.Provider value={value}>
      {children}
    </UIContext.Provider>
  );
}
