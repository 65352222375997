import React, { useEffect, useMemo, useState } from 'react';
import { Modal } from "react-bootstrap";
import { Typeahead } from 'react-bootstrap-typeahead';
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Input } from '../../../components/common/controls';
import { get, post } from '../../../helpers';
import { useProductAttributeValueUIContext } from '../UIContext';
import DialogModalFooter from "../../../components/DialogModalFooter";


const ProductAttributeValueSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, "En az 3 karakter")
    .max(50, "En fazla 50 karakter")
    .required("Özellik değeri gerekli"),
  attribute_id: Yup.string()
    .required("Özellik adı gerekli"),
});

const ProductAttributeValueForm = ({ saveProductAttributeValue, productAttributeValue, onHide }) => {
  const [closeWindow, setCloseWindow] = useState(false);
  const handleCloseWindow = () => {
    setCloseWindow(!closeWindow);
  };
  const [attributes, setAttributes] = useState([]);
  const [selectedAttribute, setSelectedAttribute] = useState([]);
  const getAttributes = async () => {
    const { product_attributes } = await post('/product_attribute/admin/list', { });
    setAttributes(product_attributes);
  };

  useEffect(()=>{
    const getAttributeValue = async (id) => {
      const { product_attribute } = await get('/product_attribute/admin/'+id);
      setSelectedAttribute([product_attribute]);
    };
    if(productAttributeValue.id !== undefined){
      getAttributeValue(productAttributeValue.attribute_id);
    }
  },[productAttributeValue]);
  useEffect(()=>{getAttributes();},[]);

  const _handleSubmit = (values, { resetForm }) => {
    saveProductAttributeValue(values, closeWindow);
    setSelectedAttribute([]);
    resetForm({});
  };
  return (
    <Formik
      initialValues={productAttributeValue}
      enableReinitialize={true}
      validationSchema={ProductAttributeValueSchema}
      onSubmit={_handleSubmit}
    >
      {({ handleSubmit, setFieldValue, values }) =>
      {
        const _handleTypeChange = (item) => {
          if(item.length > 0){
            setSelectedAttribute(item);
            setFieldValue('attribute_id', item[0].id);
          }
        };

        return(
          <React.Fragment>
            <Modal.Body className="overlay overlay-block cursor-default">
              <Form className="form form-label-right">
                <div className="form-group row">
                  <div className="col-lg-12">
                    <Field type={'hidden'} name={'attribute_id'} />
                    <label>Özellik</label>
                    <Typeahead
                      id={'attributeSearch'}
                      isInvalid={!values.attribute_id}
                      isValid={values.attribute_id}
                      labelKey={'name'}
                      onChange={_handleTypeChange}
                      options={attributes}
                      selected={selectedAttribute}
                      placeholder={'Özellik...'}
                    />
                  </div>
                </div>
                <Field
                  name="name"
                  component={Input}
                  placeholder="Değer"
                  label="Değer"
                />
              </Form>
            </Modal.Body>
            <DialogModalFooter handleSubmit={handleSubmit} onHide={onHide} handleCloseWindow={productAttributeValue.id ? null : handleCloseWindow}/>
          </React.Fragment>
        );}
      }
    </Formik>
  );
};
export default ProductAttributeValueForm;
