import React, { useEffect } from 'react';

const FileUpload = ({ selectedFile, setSelectedFile, type, data }) => {

  //file to base64
  const fileToBase64 = async (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (e) => reject(e);
    });

  //uri to base64
  function toDataUrl(url, callback) {
    const xhr = new XMLHttpRequest();
    xhr.onload = function() {
      const reader = new FileReader();
      reader.onloadend = function() {
        callback(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  }

  const fileData = () => {
    if (selectedFile && type === 'image/*') {
      return (
        <div className={'mb-5 mt-2'}>
          <img style={{ height: 100, width: 100 }} src={selectedFile.previewImage} alt=""/>
        </div>
      );
    }
  };

  const onFileChange = async event => {
    const data = event.target.files[0];
    const imageStr = await fileToBase64(data);
    setSelectedFile({ base64: imageStr.split(',')[1], previewImage: URL.createObjectURL(data) });
  };

  useEffect(() => {
    if (data.id !== undefined) {
      toDataUrl(data.image, function(uriToBase64) {
        setSelectedFile({ base64: uriToBase64.split(',')[1], previewImage: data.image });
      });
    }
  },[data]);

  return (
    <>
      <input className={selectedFile ? 'mb-0' : 'mb-5'} type="file" onChange={onFileChange} accept={type}/>
      {fileData()}
    </>
  );
};

export default FileUpload;
