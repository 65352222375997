import React, { createContext, useContext, useState, useCallback } from "react";
import { isEqual, isFunction } from "lodash";
import { initialFilter } from './CollectionUiHelpers';

const CollectionUIContext = createContext();

export function useCollectionUIContext() {
  return useContext(CollectionUIContext);
}

export function CollectionUIProvider({ collectionUIEvents, children }) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [state, setState] = useState({ collections:[], total_count:0 });
  const [error, setError] = useState(null);
  const [listLoading, setListLoading] = useState(false);
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);
  const initCollection = {
    id: undefined,
    name: "",
    category: undefined,
    sub_category: undefined,
    brand: undefined,
    type: undefined,
  };
  const value = {
    queryParams,
    setQueryParamsBase,
    setQueryParams,
    initCollection,
    state,
    setState,
    error,
    setError,
    listLoading,
    setListLoading,
  };

  return (
    <CollectionUIContext.Provider value={value}>
      {children}
    </CollectionUIContext.Provider>
  );
}
