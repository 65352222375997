import React, { useEffect, useMemo } from "react";
import { get, getHandlerTableChange, post, } from '../../../helpers';
import * as uiHelpers from "../UiHelpers";
import { useOrderUIContext } from '../UIContext';
import { DataTable } from "../../../components";
import columns from "./columns";
import { ServerStatusColumnFormatter, ServerSendStatusColumnFormatter } from "./column-formatters";
import { toast } from "react-toastify";

const OrderTable = () => {
  const UIContext = useOrderUIContext();
  const UIProps = useMemo(() => {
    return {
      setQueryParams: UIContext.setQueryParams,
      setState: UIContext.setState,
      setError: UIContext.setError,
      setListLoading: UIContext.setListLoading,
    };
  }, [UIContext]);

  const { state: { total_count, orders }, listLoading, queryParams } = UIContext;
  useEffect(() => {
    const getOrders = async () => {
      UIProps.setListLoading(true);
      const { orders, status, message, total_count } = await post('/order/admin/list', { ...queryParams });
      UIProps.setListLoading(false);
      if(!status){
        UIProps.setError(message);
        return;
      }
      UIProps.setError(null);
      UIProps.setState({ orders, total_count });
    };
    getOrders();
    return () => {
      UIProps.setError(null);
    };
  }, [queryParams]);

  const paginationOptions = {
    custom: true,
    totalSize: total_count,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: queryParams.pageSize,
    page: queryParams.pageNumber,
  };
  const updateOrder = async (order_id) => {
    const { status, message } = await get('/order/admin/update/'+order_id);
    if(!status){
      toast.info(message);
      return;
    }
    toast.success('Güncelleme isteği alındı!');
  };
  const reSendOrder = async (order_id) => {
    const { status, message } = await get('/order/admin/resend/'+order_id);
    if(!status){
      toast.info(message);
      return;
    }
    toast.success('Yeniden gönderim isteği alındı!');
  };
  const _columns = [...columns, {
    dataField: "is_sent",
    text: "",
    formatter: ServerStatusColumnFormatter,
    formatExtraData: {
      updateOrder,
    },
  },{
    dataField: "",
    text: "",
    formatter: ServerSendStatusColumnFormatter,
    formatExtraData: {
      reSendOrder,
    },
  }];
  return (
    <DataTable
      data={orders || []}
      defaultSorted={uiHelpers.defaultSorted}
      columns={_columns}
      paginationOptions={paginationOptions}
      handleTableChange={getHandlerTableChange(
        UIContext.setQueryParams
      )}
      listLoading={listLoading} />
  );
};
export default OrderTable;
