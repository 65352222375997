import React from "react";
import { useFormikContext } from "formik";
import DatePicker from "react-date-picker";

const getFieldCSSClasses = (touched, errors) => {
  const classes = ["form-control"];
  if (touched && errors) {
    classes.push("is-invalid");
  }

  if (touched && !errors) {
    classes.push("is-valid");
  }

  return classes.join(" ");
};

export function DatePickerField({ label, name, value }) {
  const { setFieldValue, errors, touched } = useFormikContext();

  return (
    <>
      {label && <label>{label}</label>}
      <DatePicker
        format="dd/MM/yyyy"
        locale={'tr'}
        className={getFieldCSSClasses(touched[name], errors[name])}
        style={{ width: "100%", backgroundColor: 'red' }}
        value={value ?? new Date()}
        onChange={val => {
          setFieldValue(name, val);
        }}
      />
      {errors[name] && touched[name] ?
        <div className="invalid-datepicker-feedback">
          {errors[name].toString()}
        </div>
        :
        <div className="feedback">
          Lütfen <b>{label}</b> 'gg/aa/yyyy' formatı girin
        </div>
      }
    </>
  );
}
