import React from 'react';
import { Card, CardBody, FormAlert } from '../../components/common/controls';
import { useCompanyUIContext } from './UIContext';
import { CompanyFilter, CompanyTable } from './company-table';

const CompanyCard = () => {
  const { error } = useCompanyUIContext();

  return (
    <>
      <FormAlert visible={error !== null} message={error} />
      <Card>
        <CardBody>
          <CompanyFilter />
          <CompanyTable />
        </CardBody>
      </Card>
    </>
  );
};
export default CompanyCard;
