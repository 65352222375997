import React from "react";

const UserActionsColumnFormatter = (
  cellContent,
  row,
  rowIndex,
  { openDeleteDialog }
) => {
  return (
    <React.Fragment>
      <a
        title="Delete"
        className="btn btn-icon btn-clean btn-xs btn-hover-danger mx-2"
        onClick={() => openDeleteDialog(row.id)}
      >
        <i className="flaticon2-trash" />
      </a>
    </React.Fragment>
  );
};
export default UserActionsColumnFormatter;
