import { types } from 'mobx-state-tree';
import { UserInfo } from './UserInfo';

export const RootStoreModel = types
  .model('RootStore')
  .props({
    userInfo: types.optional(UserInfo, {}),
  })
  .actions(self => ({

    setAuthState(user, tokens) {
      if (!user || !tokens){
        self.userInfo = UserInfo.create({ user: { }, tokens: { } });
        return;
      }
      self.userInfo = UserInfo.create({ user: user, tokens: tokens });
    },
  }));
