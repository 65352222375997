import React from 'react';
import { Card, CardBody, FormAlert } from '../../components/common/controls';
import { useProductTypeUIContext } from './UIContext';
import { ProductTypeTable, ProductTypeFilter } from './product-type-table';

const ProductTypeCard = () => {
  const { error } = useProductTypeUIContext();

  return (
    <>
      <FormAlert visible={error !== null} message={error} />
      <Card>
        <CardBody>
          <ProductTypeFilter />
          <ProductTypeTable />
        </CardBody>
      </Card>
    </>
  );
};
export default ProductTypeCard;
