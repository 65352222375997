import React from "react";

const UserLog = ({ logs }) => {
  console.log(logs);
  return logs.length > 0 && <div className="table-responsive">
    <table className="table table-borderless table-vertical-center">
      <thead>
        <tr>
          <th className="p-0" style={{ minWidth: "200px" }} />
          <th className="p-0" style={{ minWidth: "100px" }} />
          <th className="p-0" style={{ minWidth: "110px" }} />
        </tr>
      </thead>
      <tbody>
        {logs.map((item, index) =>
          <tr key={index.toString()}>
            <td className="pl-0">
              <div className="text-dark-75 font-weight-bold mb-1">{item.type}</div>
              <div>
                <span className="font-weight-bold">Host:</span>{" "}
                <div className="text-muted">
                  {item.host}
                </div>
              </div>
            </td>
            <td className="text-right">
              <span className="label label-lg label-light-primary label-inline">
                {item.method}
              </span>
            </td>
            <td className="text-right">
              <div>{item.date}</div>
              <div>{item.referer}</div>
              <div className="text-black-50 font-size-sm">
                {item.agent}
              </div>
            </td>
          </tr>
        )}

      </tbody>
    </table>
  </div>
  ;
};
export default UserLog;