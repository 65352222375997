import React from 'react';
import { Card, CardBody, FormAlert } from '../../components/common/controls';
import { useAddressUIContext } from './UIContext';
import { AddressTable, AddressFilter } from './address-table';

const AddressCard = () => {
  const { error } = useAddressUIContext();

  return (
    <>
      <FormAlert visible={error !== null} message={error} />
      <Card>
        <CardBody>
          <AddressFilter />
          <AddressTable />
        </CardBody>
      </Card>
    </>
  );
};
export default AddressCard;
