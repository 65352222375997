import React, { useEffect, useMemo, useState } from 'react';
import { Formik } from "formik";
import Select from 'react-select';
import { isEqual } from "lodash";
import { useProductAttributeValueUIContext } from '../UIContext';
import { post } from '../../../helpers';
import { useHistory } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";

const prepareFilter = (queryParams, values) => {
  const { status, searchText, attribute_id } = values;
  const newQueryParams = { ...queryParams };
  const filter = {};
  if (status) filter.status = status;
  if (attribute_id > 0) {
    filter.attribute_id = attribute_id !== "" ? [attribute_id] : undefined;
  }
  if (searchText) {
    filter.name = searchText;
  }
  newQueryParams.filter = filter;
  return newQueryParams;
};

const ProductAttributeValueFilter = () => {
  const { push } = useHistory();
  const [attributes, setAttributes] = useState([]);
  const attributeOptionUiContext = useProductAttributeValueUIContext();
  const attributeOptionUIProps = useMemo(() => {
    return {
      queryParams: attributeOptionUiContext.queryParams,
      setQueryParams: attributeOptionUiContext.setQueryParams,
    };
  }, [attributeOptionUiContext]);

  const applyFilter = (values) => {
    const newQueryParams = prepareFilter(attributeOptionUIProps.queryParams, values);
    if (!isEqual(newQueryParams, attributeOptionUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      attributeOptionUIProps.setQueryParams(newQueryParams);
    }
  };

  const getAttributes = async () => {
    const { product_attributes } = await post('/product_attribute/admin/list', { });
    let attributes_ = product_attributes.map(item => {
      return { value: item.id, label: item.name };
    });
    attributes_ = [{ value: 0, label: 'Tümü' }, ...attributes_];
    setAttributes(attributes_);
  };

  useEffect(() => {
    getAttributes();
  }, []);

  const initialValues = {
    status: "1",
    searchText: "",
  };
  const statusOptions = [
    { value: '', label: 'Tümü' },
    { value: '1', label: 'Aktif' },
    { value: '0', label: 'Pasif' }
  ];
  const _handleSubmit = (values) => applyFilter(values);
  const openProductAttributeValueAddModal = () => {
    push("/product_management/attribute_values/new");
  };
  return (
    <>
      <Formik initialValues={initialValues} onSubmit={_handleSubmit}>
        {({ values, handleSubmit, handleBlur, handleChange, setFieldValue }) =>
        {
          const _handleSelectChange = (item)=>{
            setFieldValue("status", item.value);
          };
          const _handleAttributeSelectChange = (item)=>{
            setFieldValue("attribute_id", item.value);
          };
          return(
            <form onSubmit={handleSubmit} className="form form-label-right">
              <div className="form-group row">
                <div className="col-lg-2">
                  <input
                    type="text"
                    className="form-control"
                    name="searchText"
                    placeholder="Özellik"
                    onBlur={handleBlur}
                    value={values.searchText}
                    onChange={(e) => {
                      setFieldValue("searchText", e.target.value);
                      handleSubmit();
                    }}
                  />
                </div>

                <div className="col-lg-10">
                  <Dropdown onClick={(e) => e.stopPropagation(e)} drop="down" className="float-right ml-2">
                    <Dropdown.Toggle>
                      Filtreleme Seçenekleri
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
                      <div className="navi navi-spacer-x-0 p-5">
                        <div className="form-group row">
                          <div className="col-lg-12 mt-2">
                            <Select onChange={_handleSelectChange}
                              options={statusOptions}
                              placeholder={'Durum'}
                              value={statusOptions.filter(item => item.value === values.status)}/>
                            <small className="form-text text-muted">
                              Duruma <b>Göre</b>
                            </small>
                          </div>
                          <div className="col-lg-12 mt-2">
                            <Select
                              onChange={_handleAttributeSelectChange}
                              options={attributes}
                              placeholder={'Özellik'}
                            />
                            <small className="form-text text-muted">
                              Özelliğe <b>Göre</b>
                            </small>
                          </div>
                          <div className="col-lg-12 mt-2">
                            <button type="button" className="btn btn-outline-primary btn-block" onClick={handleSubmit}>
                              Uygula
                            </button>
                          </div>
                        </div>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                  <button type="button" className="btn btn-info ml-2 float-right" onClick={openProductAttributeValueAddModal}>
                    Yeni Özellik Değeri
                  </button>
                </div>
              </div>
            </form>
          );}
        }
      </Formik>
    </>
  );
};
export default ProductAttributeValueFilter;
