import React from "react";

const NameColumnFormatter = (cellContent, row) =>{
  const { company_name, code } = row;
  return (
    <React.Fragment>
      <div className="text-dark-75 font-weight-bold mb-1">{company_name}</div>
      <div className="text-dark-50 font-weight-lighter mb-1">{code}</div>
    </React.Fragment>
  );
};
export default NameColumnFormatter;
