import React, { useEffect, useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { ProductTypeForm } from './index';
import { useProductTypeUIContext } from '../UIContext';
import { get, post, put } from '../../../helpers';
import { stockTypes } from "../../../constant/helper";


const ProductTypeAdd = ({ id, show, onHide }) => {
  const [title, setTitle] = useState('');
  const [productTypeForEdit, setProductTypeForEdit] = useState(undefined);
  const productTypeUIContext = useProductTypeUIContext();
  const { queryParams, initProductType } = productTypeUIContext;

  const productTypeUIProps = useMemo(() => {
    return {
      setError: productTypeUIContext.setError,
      setState: productTypeUIContext.setState,
    };
  }, [productTypeUIContext]);

  const getProductType = async () => {
    if(id){
      const { product_type } = await get('product_type/'+id);
      const productType = {
        ...product_type,
        variants: product_type.variants.map(item => { return item.id; }),
        attributes: product_type.attributes.map(item => { return item.id; }),
        stock_type: stockTypes.find(item => item.id === product_type.stock_type)
      };
      setProductTypeForEdit(productType);
    }
  };

  useEffect(() => {
    getProductType();
  }, [id]);

  useEffect(() => {
    let _title = id ? "" : "Yeni Ürün Türü";
    if (productTypeForEdit && id) {
      _title = `${productTypeForEdit.name} Düzenle`;
    }
    setTitle(_title);
  }, [productTypeForEdit]);


  const saveProductType = async (productType) => {

    if (!id) {
      const { status, message } = await post('product_type/add', productType);
      if(!status){
        productTypeUIProps.setError(message);
      }
    } else {
      const { status, message } = await put('product_type/'+id, productType);
      if(!status){
        productTypeUIProps.setError(message);
      }
    }
    const { product_types, status:status_, message:message_, total_count } = await post('/product_type/admin/list', { ...queryParams });
    if(!status_){
      productTypeUIProps.setError(message_);
      return;
    }
    productTypeUIProps.setError(null);
    productTypeUIProps.setState({ product_types, total_count });
    onHide();
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <ProductTypeForm
        saveProductType={saveProductType}
        productType={productTypeForEdit || initProductType}
        onHide={onHide}
      />
    </Modal>
  );
};
export default ProductTypeAdd;
