import React, { useEffect, useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import {
  getHandlerTableChange,
  NoRecordsFoundMessage,
  sortCaret,
  headerSortingClasses, post,
} from '../../../helpers';
import * as uiHelpers from "../UiHelpers";
import { Pagination } from "../../../components/common/controls";
import { useCompanyUIContext } from '../UIContext';
import {
  ImageColumnFormatter,
  NameColumnFormatter
} from './column-formatters';
import { DataTable, DefaultActionsColumnFormatter, DefaultStatusColumnFormatter } from "../../../components";
import { useHistory } from "react-router-dom";

const CompanyTable = () => {
  const { push } = useHistory();
  const UIContext = useCompanyUIContext();

  const openEditDialog = (id) => {
    push(`/seller_management/companies/${id}/edit`);
  };
  const openDeleteDialog = (id) => {
    push(`/seller_management/companies/${id}/delete`);
  };
  const UIProps = useMemo(() => {
    return {
      setQueryParams: UIContext.setQueryParams,
      setState: UIContext.setState,
      setError: UIContext.setError,
      setListLoading: UIContext.setListLoading,
    };
  }, [UIContext]);

  const { state: { total_count, companies }, listLoading, queryParams } = UIContext;
  useEffect(() => {
    const getCompanies = async () => {
      UIProps.setListLoading(true);
      const { companies, status, message, total_count } = await post('/company/admin/list', { ...queryParams });
      UIProps.setListLoading(false);
      if(!status){
        UIProps.setError(message);
        return;
      }
      UIProps.setError(null);
      UIProps.setState({ companies, total_count });
    };
    getCompanies();
    return () => {
      UIProps.setError(null);
    };
  }, [queryParams]);
  // Table columns
  const columns = [
    {
      dataField: "id",
      text: "ID",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      style: {
        width: "40px",
      },
    },
    {
      dataField: "image",
      text: "",
      sort: false,
      formatter: ImageColumnFormatter,
      classes: "text-center",
      headerClasses: "text-center",
      style: {
        width: "60px",
      },
    },
    {
      dataField: "name",
      text: "Firma",
      sort: true,
      sortCaret: sortCaret,
      formatter: NameColumnFormatter,
      headerSortingClasses,
    },
    {
      dataField: "status",
      text: "Durum",
      formatter: DefaultStatusColumnFormatter,
      classes: "text-center",
      headerClasses: "text-center",
      style: {
        width: "60px",
      },
    },
    {
      dataField: "action",
      text: "İşlem",
      formatter: DefaultActionsColumnFormatter,
      formatExtraData: {
        openEditDialog,
        openDeleteDialog,
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        width: "100px",
      },
    },
  ];

  const paginationOptions = {
    custom: true,
    totalSize: total_count,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: queryParams.pageSize,
    page: queryParams.pageNumber,
  };

  return (
    <DataTable
      data={companies || []}
      defaultSorted={uiHelpers.defaultSorted}
      columns={columns}
      paginationOptions={paginationOptions}
      handleTableChange={getHandlerTableChange(
        UIContext.setQueryParams
      )}
      listLoading={listLoading} />
  );
};
export default CompanyTable;
