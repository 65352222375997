import React, { useState, useEffect, useRef } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar, FormAlert,
} from '../../components/common/controls';
import { useHistory } from 'react-router-dom';
import { CollectionForm } from './CollectionForm';
import { post, get, put } from '../../helpers';
import { useCollectionUIContext } from './CollectionUIContext';


const CollectionAdd = ({ id }) => {
  const history = useHistory();
  const { initCollection } = useCollectionUIContext();
  const [error, setError] = useState(null);
  const [collectionForEdit, setCollectionForEdit] = useState(undefined);

  const btnRef = useRef();
  const saveCollectionClick = () => {
    if (btnRef && btnRef.current) {
      btnRef.current.click();
    }
  };

  const backToCollectionsList = () => {
    history.push(`/product_management/collections`);
  };
  const saveCollection = async (collection) => {
    if (!id) {
      const { status, message } = await post('/collection/admin/add', collection);
      if (!status) {
        setError(message);
        return;
      }
    }
    else{
      const { status, message } = await put('/collection/admin/'+id, collection);
      if (!status) {
        setError(message);
        return;
      }
    }
    backToCollectionsList();
  };
  const getCollection = async () => {
    if(!id){
      return;
    }
    const { collection } = await get('/collection/admin/'+ id);
    console.log(collection);
    setCollectionForEdit(collection);

  };
  useEffect(() => {
  }, [collectionForEdit]);
  useEffect(() => {
    getCollection();
  },[id]);

  return (
    <React.Fragment>
      <FormAlert visible={error !== null} message={error} />
      <Card>
        <CardHeader title={'Genel Bilgi'}>
          <CardHeaderToolbar>
            <button
              type="button"
              onClick={backToCollectionsList}
              className="btn btn-light"
            >
              <i className="fa fa-arrow-left" />
              Geri
            </button>
            <button
              type="submit"
              className="btn btn-primary ml-2"
              onClick={saveCollectionClick}
            >
              Kaydet
            </button>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <CollectionForm setError={setError} collection={collectionForEdit || initCollection} btnRef={btnRef} saveCollection={saveCollection} />
        </CardBody>
      </Card>
    </React.Fragment>
  );
};
export default CollectionAdd;
