import React from 'react';
import { Modal } from "react-bootstrap";

const DialogModalFooter = ({ onHide, handleSubmit, handleCloseWindow, type='add' }) => {
  return(
    <Modal.Footer className={'d-flex flex-row'}>
      {
        handleCloseWindow &&
            <div className={'flex-grow-1 form-check'}>
              <input type="checkbox" className="form-check-input" id="close_window" onClick={handleCloseWindow}/>
              <label className="form-check-label" htmlFor="close_window">Pencereyi Kapatma</label>
            </div>
      }
      <button
        type="button"
        onClick={onHide}
        className="btn btn-light btn-elevate"
      >
                İptal
      </button>
      <button
        type="submit"
        onClick={handleSubmit}
        className="btn btn-primary btn-elevate"
      >
        {type === 'delete' ? 'Güncelle' : 'Kaydet'}
      </button>
    </Modal.Footer>
  );
};
export default DialogModalFooter;