import React from "react";
import { Link, Switch, Redirect } from "react-router-dom";
import "../../assets/sass/pages/login/classic/login-1.scss";
import { toAbsoluteUrl } from '../../helpers';
import { ContentRoute } from '../../components';
import Login from "./Login";

const AuthPage = () => {
  return (
    <React.Fragment>
      <div className="d-flex flex-column flex-root">
        <div className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white" id="kt_login">
          <div
            className="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10"
            style={{
              backgroundImage: `url(${toAbsoluteUrl("/media/bg/bg-4.jpg")})`,
            }}
          >
            <div className="d-flex flex-row-fluid flex-column justify-content-between">
              <Link to="/" className="flex-column-auto mt-5 pb-lg-0 pb-10">
                <img alt="Logo" className="max-h-70px" src={toAbsoluteUrl("/media/logos/bufi-letter-1.png")}/>
              </Link>
              <div className="flex-column-fluid d-flex flex-column justify-content-center">
                <h3 className="font-size-h1 mb-5 text-white">
                  Bufi Management Console
                </h3>
                <p className="font-weight-lighter text-white opacity-80">
                  Manage your B2B Content from everywhere with Bufi Management Console.
                </p>
              </div>
            </div>
          </div>
          <div className="d-flex flex-column flex-row-fluid position-relative p-7 overflow-hidden">
            <div className="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
              <Switch>
                <ContentRoute path="/auth/login" component={Login} />
                <Redirect from="/auth" exact={true} to="/auth/login" />
                <Redirect to="/auth/login" />
              </Switch>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default AuthPage;
