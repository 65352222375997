import React from 'react';
import { Card, CardBody, FormAlert } from '../../components/common/controls';
import { useOrderUIContext } from './UIContext';
import { OrderTable, OrderFilter } from './order-table';

const OrderCard = () => {
  const { error } = useOrderUIContext();
  return (
    <>
      <FormAlert visible={error !== null} message={error} />
      <Card>
        <CardBody>
          <OrderFilter />
          <OrderTable />
        </CardBody>
      </Card>
    </>
  );
};
export default OrderCard;
