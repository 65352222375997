import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader, ModalProgressBar,
} from '../../components/common/controls';
import {
  post,
  headerSortingClasses,
  NoRecordsFoundMessage,
  sortCaret, get
} from "../../helpers";
import { Col, Container, Row } from "react-bootstrap";
import { ImageContainer } from "./index";
import BootstrapTable from "react-bootstrap-table-next";
import {
  LogoColumnFormatter,
  NameColumnFormatter
} from "./product-sku-table/column-formatters";
import { ProductFeeTable } from "./product-fee-table";
import { ProductCollectionTable } from "./product-collection-table";
import { ProductCampaignTable } from "./product-campaign-table";
import { ProductStockTable } from "./product-stock-table";
import { ProductFavoriteTable } from "./product-favorite-table";
import { RatingIcons } from "./component";
import { StockStatusCssClasses, StockStatusTitles } from "./UiHelpers";
import {toast} from "react-toastify";

const ProductInformation = ({ id }) => {
  const [tab, setTab] = useState("summary");
  const [data, setData] = useState(undefined);
  const [loading, setLoading] = useState(true);

  const moveToSummaryTab = () => setTab("summary");
  const moveToFeaturesTab = () => setTab("features");
  const moveToStockTab = () => setTab("stock");
  const moveToPriceTab = () => setTab("price");
  const moveToProductTab = () => setTab("image");
  const moveToVariantTab = () => setTab("variant");
  const moveToCollectionTab = () => setTab('collections');
  const moveToCampaignTab = () => setTab('campaigns');
  const moveToOrderTab = () => setTab('orders');
  const moveToFavoriteTab = () => setTab('favorites');

  const getProductSku = async () => {
    if (id) {
      setLoading(true);
      const { product_sku } = await post('product/admin/sku/list/' + id, {});
      setData(product_sku);
      setLoading(false);
    }
  };

  const columns = [
    {
      dataField: "id",
      text: "ID",
      sort: false,
      sortCaret: sortCaret,
      headerSortingClasses,
      style: {
        width: "60px",
      },
    },
    {
      dataField: "image",
      text: "",
      sort: false,
      sortCaret: sortCaret,
      formatter: LogoColumnFormatter,
      headerSortingClasses,
      style: {
        width: "100px",
      },
    },
    {
      dataField: "name",
      text: "Ürün Adı",
      sort: true,
      sortCaret: sortCaret,
      formatter: NameColumnFormatter,
      headerSortingClasses,
    },
    {
      dataField: "stock.quantity",
      text: "Stok",
      sort: false,
      sortCaret: sortCaret,
      style: {
        width: "100px",
      },
    }
  ];

  useEffect(() => {
    getProductSku();
  }, []);
  
  const SkuTitle = () => {
    return (
      <React.Fragment>
        {!data.on_sale ? <span className={'label label-danger mr-3 label-inline'}>
      Satışta Olmayan Ürün
        </span> : <span className={'label label-success mr-3 label-inline'}>
      Satışta
        </span>}
        <span className={`label mr-3 label-${
          StockStatusCssClasses[data.stock.in_stock]
        } label-inline`}>
          {StockStatusTitles[data.stock.in_stock]}
        </span>
        {data.tag && data.tag.length > 0 && <span className={`label mr-3 label-danger label-inline`}>
          {data.tag}
        </span>}
        <span>{data.name}</span>
      </React.Fragment>
    );
  };
  const updateSku = async () => {
    const { status, message } = await get('/product/admin/sku/update/'+id);
    if(!status){
      toast.info(message);
      return;
    }
    toast.success('Güncelleme isteği alındı!');
  };
  return (
    <Card>
      {loading && <ModalProgressBar />}
      <CardHeader title={data && <SkuTitle />} toolbar={
        <div className="p-5">
          <button type="button" className="btn btn-sm btn-info" onClick={updateSku}>
            Güncelle
          </button>
        </div>
      }/>
      <CardBody style={{ padding: "1rem 2rem" }}>
        {data && <React.Fragment>
          <ul className="nav nav-pills" role="tablist">
            <li className="nav-item" onClick={moveToSummaryTab}>
              <a
                className={`nav-link ${tab === "summary" && "active bg-info"}`}
                data-toggle="tab"
                role="tab"
                aria-selected={(tab === "summary").toString()}
              >
                Özet
              </a>
            </li>
            <li className="nav-item" onClick={moveToFeaturesTab}>
              <a
                className={`nav-link ${tab === "features" && "active bg-info"}`}
                data-toggle="tab"
                role="tab"
                aria-selected={(tab === "features").toString()}
              >
                Özellikler
              </a>
            </li>
            <li className="nav-item" onClick={moveToStockTab}>
              <a
                className={`nav-link ${tab === "stock" && "active bg-info"}`}
                data-toggle="tab"
                role="tab"
                aria-selected={(tab === "stock").toString()}
              >
                Stok
              </a>
            </li>
            <li className="nav-item" onClick={moveToPriceTab}>
              <a
                className={`nav-link ${tab === "price" && "active bg-info"}`}
                data-toggle="tab"
                role="tab"
                aria-selected={(tab === "price").toString()}
              >
                Fiyat Listesi
              </a>
            </li>
            <li className="nav-item" onClick={moveToProductTab}>
              <a
                className={`nav-link ${tab === "image" && "active bg-info"}`}
                data-toggle="tab"
                role="tab"
                aria-selected={(tab === "image").toString()}
              >
                Resimler
              </a>
            </li>
            <li className="nav-item" onClick={moveToVariantTab}>
              <a
                className={`nav-link ${tab === "variant" && "active bg-info"}`}
                data-toggle="tab"
                role="tab"
                aria-selected={(tab === "variant").toString()}
              >
                Varyantlar
              </a>
            </li>
            <li className="nav-item" onClick={moveToCollectionTab}>
              <a
                className={`nav-link ${tab === "collections" && "active bg-info"}`}
                data-toggle="tab"
                role="tab"
                aria-selected={(tab === "collections").toString()}
              >
                Koleksiyonlar
              </a>
            </li>
            <li className="nav-item" onClick={moveToCampaignTab}>
              <a
                className={`nav-link ${tab === "campaigns" && "active bg-info"}`}
                data-toggle="tab"
                role="tab"
                aria-selected={(tab === "campaigns").toString()}
              >
                Kampanyalar
              </a>
            </li>
            <li className="nav-item" onClick={moveToOrderTab}>
              <a
                className={`nav-link ${tab === "orders" && "active bg-info"}`}
                data-toggle="tab"
                role="tab"
                aria-selected={(tab === "orders").toString()}
              >
                Siparişler
              </a>
            </li>
            <li className="nav-item" onClick={moveToFavoriteTab}>
              <a
                className={`nav-link ${tab === "favorites" && "active bg-info"}`}
                data-toggle="tab"
                role="tab"
                aria-selected={(tab === "favorites").toString()}
              >
                Favoriler
              </a>
            </li>
          </ul>
          <div style={{ minHeight: 250 }} className="mt-5">
            <React.Fragment>
              {tab === "summary" &&
              <Container>
                <Row>
                  <Col sm={2}><p className={'text-black-50'}>Adı:</p></Col>
                  <Col sm={6}>{data.name}</Col>
                </Row>
                <Row>
                  <Col sm={2}><p className={'text-black-50'}>Marka:</p></Col>
                  <Col sm={6}>{data.brand.name}</Col>
                </Row>
                <Row>
                  <Col sm={2}><p className={'text-black-50'}>Kategori:</p></Col>
                  <Col sm={6}>{data.category.name}</Col>
                </Row>
                <Row>
                  <Col sm={2}><p className={'text-black-50'}>Alt Kategori:</p></Col>
                  <Col sm={6}>{data.sub_category.name}</Col>
                </Row>
                <Row>
                  <Col sm={2}><p className={'text-black-50'}>Satış Sayısı:</p></Col>
                  <Col sm={6}>{data.sold_count}</Col>
                </Row>
                <Row>
                  <Col sm={2}><p className={'text-black-50'}>Satış Adeti:</p></Col>
                  <Col sm={6}>{data.sold_quantity}</Col>
                </Row>
                <Row>
                  <Col sm={2}><p className={'text-black-50'}>Favori Sayısı:</p></Col>
                  <Col sm={6}>{data.favorite_count}</Col>
                </Row>
                <Row>
                  <Col sm={2}><p className={'text-black-50'}>Puan:</p></Col>
                  <Col sm={6}>
                    <RatingIcons rating={data.rating.score} />
                  </Col>
                </Row>
                <Row>
                  <Col sm={2}><p className={'text-black-50'}>Oluşturulma Tarihi:</p></Col>
                  <Col sm={6}>{data.create_date}</Col>
                </Row>
              </Container>
              }
            </React.Fragment>
            <React.Fragment>
              {tab === "features" &&
              <Container>
                {
                  Object.keys(data.properties).map(key =>
                    <Row>
                      <Col sm={2}><p className={'text-black-50'}>{key}:</p></Col>
                      <Col sm={6}>{data.properties[key]}</Col>
                    </Row>
                  )
                }

              </Container>
              }
            </React.Fragment>
            <React.Fragment>
              {tab === "stock" &&
              <Container>
                <Row>
                  <Col sm={2}><p className={'text-black-50'}>Stok:</p></Col>
                  <Col sm={6}>{data.stock.quantity}</Col>
                </Row>
                <Row>
                  <Col sm={2}><p className={'text-black-50'}>Tip:</p></Col>
                  <Col sm={6}>{data.stock.type}</Col>
                </Row>
                <Row>
                  <Col sm={2}><p className={'text-black-50'}>Birim:</p></Col>
                  <Col sm={6}>{data.stock.unit}</Col>
                </Row>
                <Row>
                  <Col sm={2}><p className={'text-black-50'}>Satış Durumu:</p></Col>
                  <Col sm={6}>
                    {!data.on_sale ? <span className={'label label-sm label-light-danger label-inline'}>
      Satışta Olmayan Ürün
                    </span> : <span className={'label label-sm label-light-success label-inline'}>
      Satışta
                    </span>}
                  </Col>
                </Row>
                <Row>
                  <Col sm={2}><p className={'text-black-50'}>Stok Durumu:</p></Col>
                  <Col sm={6}>
                    <span className={`label label-sm label-light-${
                      StockStatusCssClasses[data.stock.in_stock]
                    } label-inline`}>
                      {StockStatusTitles[data.stock.in_stock]}
                    </span>
                  </Col>
                </Row>
                {data.type.id === 3 && <ProductStockTable data={data.stock.detail_list}/>}
              </Container>
              }
            </React.Fragment>
            <React.Fragment>
              {tab === "price" &&
              <ProductFeeTable product_id={id}/>
              }
            </React.Fragment>
            <React.Fragment>
              {tab === "image" &&
              <ImageContainer id={data.id} getProductSku={getProductSku} images={data.images}/>
              }
            </React.Fragment>
            <React.Fragment>
              {tab === "variant" &&
              <BootstrapTable
                wrapperClasses="table-responsive"
                classes="table table-head-custom table-head-bg table-vertical-center overflow-hidden"
                bootstrap4
                loading={false}
                bordered={false}
                remote
                keyField="id"
                data={data.variants || []}
                columns={columns}
                noDataIndication={() => <NoRecordsFoundMessage/>}/>
              }
            </React.Fragment>
            <React.Fragment>
              {tab === "collections" &&
              <ProductCollectionTable product_id={id}/>
              }
            </React.Fragment>
            <React.Fragment>
              {tab === "campaigns" &&
              <ProductCampaignTable product_id={id}/>
              }
            </React.Fragment>
            <React.Fragment>
              {tab === "orders" &&
              <div>Siparişler</div>
              }
            </React.Fragment>
            <React.Fragment>
              {tab === "favorites" &&
              <ProductFavoriteTable product_id={id}/>
              }
            </React.Fragment>
          </div>
        </React.Fragment>}
      </CardBody>
    </Card>
  );
};
export default ProductInformation;
