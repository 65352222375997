import React from "react";
import { BrandUIProvider } from './UIContext';
import { Route, useHistory } from 'react-router-dom';
import BrandCard from './BrandCard';
import { BrandAdd, BrandDeleteDialog, BrandLogoDialog } from './dialog';

const BrandPage = () => {
  const { push } = useHistory();
  const _onHide = () => push("/product_management/brands");
  const _handleAddModal = ({ match }) => <BrandAdd show={match != null} onHide={_onHide}/>;
  const _handleEditModal = ({ match }) => <BrandAdd show={match != null} id={match && match.params.id} onHide={_onHide}/>;
  const _handleDeleteModal = ({ match }) => <BrandDeleteDialog show={match != null} id={match && match.params.id} onHide={_onHide} />;
  const _handleLogoModal = ({ match }) => <BrandLogoDialog show={match != null} id={match && match.params.id} onHide={_onHide} />;

  return (
    <BrandUIProvider>
      <Route path="/product_management/brands/new" render={_handleAddModal} />
      <Route path="/product_management/brands/:id/edit" render={_handleEditModal} />
      <Route path="/product_management/brands/:id/delete" render={_handleDeleteModal}/>
      <Route path="/product_management/brands/:id/logo" render={_handleLogoModal}/>
      <BrandCard />
    </BrandUIProvider>
  );
};
export default BrandPage;
