import React from "react";
import { CompanyUIProvider } from './UIContext';
import { useHistory, Switch } from 'react-router-dom';
import CompanyCard from './CompanyCard';
import { CompanyAdd, CompanyDeleteDialog, CompanyLogoDialog } from './dialog';
import CompanyInformation from "./CompanyInformation";
import { ContentRoute } from "../../components";

const CompanyPage = () => {
  const { push } = useHistory();
  const _onHide = () => push("/seller_management/companies");
  const _handleAddModal = ({ match }) => <CompanyAdd show={match != null} onHide={_onHide}/>;
  const _handleEditModal = ({ match }) => <CompanyAdd show={match != null} id={match && match.params.id} onHide={_onHide}/>;
  const _handleDeleteModal = ({ match }) => <CompanyDeleteDialog show={match != null} id={match && match.params.id} onHide={_onHide} />;
  const _handleLogoModal = ({ match }) => <CompanyLogoDialog show={match != null} id={match && match.params.id} onHide={_onHide} />;
  const CompanyProfile = ({ match }) => <CompanyInformation company_id={match && match.params.id} />;
  return (
    <CompanyUIProvider>
      <Switch>
        <ContentRoute exact path="/seller_management/companies/:id/logo" component={_handleLogoModal}/>
        <ContentRoute path="/seller_management/companies/:id/edit" component={_handleEditModal} />
        <ContentRoute path="/seller_management/companies/:id/delete" component={_handleDeleteModal}/>
        <ContentRoute path="/seller_management/companies/:id" component={CompanyProfile} />
        <ContentRoute path="/seller_management/companies/new" component={_handleAddModal} />
        <ContentRoute component={CompanyCard} />
      </Switch>
    </CompanyUIProvider>
  );
};
export default CompanyPage;
