import React, { useEffect, useState } from 'react';
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import * as Yup from "yup";
import { Input } from '../../../components/common/controls';
import { post } from '../../../helpers';
import DialogModalFooter from "../../../components/DialogModalFooter";
import { useCompanyUIContext } from "../UIContext";


const CompanySchema = Yup.object().shape({
  name: Yup.string()
    .required("Marka adı gerekli"),
});

const CompanyForm = ({ saveCompany, company, onHide }) => {
  const { queryParams } = useCompanyUIContext();
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [closeWindow, setCloseWindow] = useState(false);
  const [loading, setLoading] = useState(true);
  const handleCloseWindow = () => {
    setCloseWindow(!closeWindow);
  };
  const getCompanies = async (queryParams) => {
    setLoading(true);
    const { companies } = await post('/company/list', { ...queryParams });
    setCategories(companies);
    setLoading(false);
  };

  useEffect(() => {getCompanies(queryParams);}, []);

  const _handleSubmit = (values, { resetForm }) => {
    saveCompany(values, closeWindow);
    setSelectedCategory([]);
    resetForm({});
  };

  return (
    <React.Fragment>
      <Formik enableReinitialize={true} initialValues={company} validationSchema={CompanySchema} onSubmit={_handleSubmit}>
        {({ handleSubmit, setFieldValue, values }) =>
        {
          const _handleCompanyChange = (item)=> {
            if(item.length > 0){
              setSelectedCategory(item);
              setFieldValue('parent_id', item[0].id);
            }
          };
          const filterBy = () => true;
          const _handleSearch = async (query) => {
            const newQueryParams = { ...queryParams };
            const filter = {};
            if (query) {
              filter.name = query;
            }
            newQueryParams.filter = filter;
            await getCompanies(newQueryParams);
          };
          return (
            <React.Fragment>
              <Modal.Body className="overlay overlay-block cursor-default">
                <Form className="form form-label-right">
                  <Field
                    name="name"
                    component={Input}
                    placeholder="Firma Adı"
                    label="Firma Adı*"
                  />
                  <div className="form-group row">
                    <div className="col-lg-12">
                      <label>Üst Firma</label>
                      <AsyncTypeahead
                        filterBy={filterBy}
                        id={'companySearch'}
                        isLoading={loading}
                        // isInvalid={values.parent_id === undefined}
                        // isValid={values.parent_id !== undefined}
                        labelKey="name"
                        minLength={3}
                        onChange={_handleCompanyChange}
                        onSearch={_handleSearch}
                        options={categories}
                        selected={selectedCategory}
                        placeholder={'Üst Firma'}
                      />
                    </div>
                  </div>
                </Form>
              </Modal.Body>
              <DialogModalFooter handleSubmit={handleSubmit} onHide={onHide} handleCloseWindow={handleCloseWindow}/>
            </React.Fragment>
          );}
        }
      </Formik>
    </React.Fragment>
  );
};
export default CompanyForm;
