import React from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Input } from '../../../components/common/controls';


const CategorySchema = Yup.object().shape({
  name: Yup.string()
    .min(3, "En az 3 karakter")
    .max(50, "En fazla 50 karakter")
    .required("Kategori adı gerekli"),
});

const CategoryForm = ({ saveCategory, category, onHide }) => {

  const _handleSubmit = (values) => saveCategory(values);

  return (
    <Formik enableReinitialize={true} initialValues={category} validationSchema={CategorySchema} onSubmit={_handleSubmit}>
      {({ handleSubmit }) =>
        <>
          <Modal.Body className="overlay overlay-block cursor-default">
            <Form className="form form-label-right">
              <div className="form-group row">
                <div className="col-lg-12">
                  <Field
                    name="name"
                    component={Input}
                    placeholder="Kategori Adı"
                    label="Kategori Adı"
                  />
                </div>
              </div>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              onClick={onHide}
              className="btn btn-light btn-elevate"
            >
                  İptal
            </button>
            <button type="button" onClick={handleSubmit} className="btn btn-primary btn-elevate">Kaydet</button>
          </Modal.Footer>
        </>
      }
    </Formik>
  );
};
export default CategoryForm;
