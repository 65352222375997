import React from "react";
import Select from 'react-select';

const PaginationToolbar = ({ isLoading, paginationProps }) => {
  const sizes = [
    { label: "3", value: 3 },
    { label: "5", value: 5 },
    { label: "10", value: 10 }
  ];
  const {
    sizePerPage,
    totalSize,
    onSizePerPageChange = sizes
  } = paginationProps;

  const onSizeChange = event => {
    const newSize = +event.value;
    onSizePerPageChange(newSize);
  };
  return (
    <div className="d-flex align-items-center py-3">
      {isLoading &&
        <div className="d-flex align-items-center">
          <div className="mr-2 text-muted">Bekleyin...</div>
          <div className="spinner spinner-primary mr-10" />
        </div>
      }
      <Select
        onChange={onSizeChange}
        disabled={totalSize === 0}
        className={`${totalSize === 0 && "disabled"}`}
        options={sizes}
        value={sizes.filter(item => item.value === sizePerPage )}
        placeholder={'Durum'}
      />
    </div>
  );
};
export default PaginationToolbar;
