import React from 'react';
import { Image } from 'react-bootstrap';

const LogoColumnFormatter = (cellContent, row, rowIndex, { openBrandLogoDialog }) => {
  const _openBrandLogoDialog = () => openBrandLogoDialog(row.id);
  return <a onClick={_openBrandLogoDialog}>
    <Image thumbnail roundedCircle style={{ objectFit:'contain' }} src={row.image} width="50"/>
  </a>;
};

export default LogoColumnFormatter;
