import React from 'react';
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Input } from "../../components/common/controls";
import { useDropzone } from "react-dropzone";

const CollectionEditSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, "En az 5 karakter")
    .max(50, "En fazla 50 karakter")
    .required("Ürün adı gerekli"),
});

export function CollectionForm({
  id,
  collection,
  btnRef,
  setError,
  saveCollection,
}) {
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({ multiple: false });
  const files = acceptedFiles.map(file =>
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  );
  const fileToBase64 = async (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (e) => reject(e);
    });

  const _onSubmit = async (values) => {
    if (id) {
      delete values.image;
    }
    if (files.length > 0){
      const imageStr = await fileToBase64(acceptedFiles[0]);
      values['image'] = imageStr.split(',')[1];
    }
    saveCollection(values);
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={collection}
      validationSchema={CollectionEditSchema}
      onSubmit={_onSubmit}
    >
      {({ handleSubmit, setFieldValue, values }) => {
        return (
          <Form className="form form-label-right">
            <div style={{ minHeight: 250 }} className="mt-5">
              <React.Fragment>
                <div className="form-group row">
                  <div className="col-lg-12">
                    <Field
                      name="name"
                      component={Input}
                      placeholder="Ürün Adı"
                      label="Ad"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <section className="container">
                    <div {...getRootProps({ className: 'dropzone' })} style={{ height: 200, backgroundColor: '#ddd', borderRadius: 10, justifyContent:'center', display:'flex', alignItems:'center' }}>
                      <input {...getInputProps()} />
                      {
                        files.length > 0
                          ?
                          <ul>{files}</ul>
                          :
                          collection.image ?
                            <img src={collection.image} height={175}/>
                            :
                            <p>Kampanya resmini seçin veya buraya sürükleyin</p>
                      }
                    </div>
                  </section>
                </div>
              </React.Fragment>
            </div>
            <button
              type="submit"
              style={{ display: "none" }}
              ref={btnRef}
              onSubmit={handleSubmit}
            />
          </Form>
        );
      }}
    </Formik>
  );
}
