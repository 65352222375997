import React, { useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import { post } from '../../../helpers';
import * as uiHelpers from "../UiHelpers";
import { NameColumnFormatter, } from './column-formatters';
import { ImageColumnFormatter } from "../../Companies/company-table/column-formatters";
import { DataTable } from "../../../components";

const ProductFeeTable = ({ product_id }) => {
  const [queryParams, setQueryParams] = useState({ filter: {
    name: "",
  },
  sortOrder: "asc", // asc||desc
  sortField: "id",
  pageNumber: 1,
  pageSize: 10 });
  const [loading, setLoading] = useState(false);
  const [product_fees, setFees] = useState([]);
  const [total_count, setTotalCount] = useState([]);
  const handleTableChange = (type, { page, sizePerPage }) => {
    const pageNumber = page || 1;
    setQueryParams({ ...queryParams, pageNumber: pageNumber, pageSize: sizePerPage });
  };
  useEffect(() => {
    const getFees = async () => {
      setLoading(true);
      const { product_fees, total_count, status } = await post('/product/fee/'+product_id, { ...queryParams });
      if(!status){
        return;
      }
      setLoading(false);
      setTotalCount(total_count);
      setFees(product_fees);

    };
    getFees();
  }, [queryParams]);

  const columns = [
    {
      dataField: "image",
      text: "",
      formatter: ImageColumnFormatter,
      classes: "text-center",
      headerClasses: "text-center",
      style: {
        width: "60px",
      },
    },
    {
      dataField: "name",
      text: "Firma Adı",
      formatter: NameColumnFormatter,
    },
    {
      dataField: "wholesale",
      text: "Toptan",
      classes: "text-center pr-0",
      headerClasses: "text-center pr-0",
      style: {
        width: "100px",
      },
    },
    {
      dataField: "wholesale_discounted",
      text: "İndirimli",
      classes: "text-center pr-0",
      headerClasses: "text-center pr-0",
      style: {
        width: "100px",
      },
    },
    {
      dataField: "retail",
      text: "Perakende",
      classes: "text-center pr-0",
      headerClasses: "text-center pr-0",
      style: {
        width: "100px",
      },
    },
  ];
  const handleInputChange = (e) => {
    setQueryParams({ ...queryParams, filter: { name:e.target.value } });
  };
  const paginationOptions = {
    custom: true,
    totalSize: total_count,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: queryParams.pageSize,
    page: queryParams.pageNumber,
  };
  return (
    <Container>
      <Row className="mb-5">
        <input
          type="text"
          className="form-control col-lg-3"
          name="name"
          placeholder="Firma"
          onChange={handleInputChange}
        />
      </Row>
      <DataTable
        data={product_fees}
        defaultSorted={uiHelpers.defaultSorted}
        columns={columns}
        paginationOptions={paginationOptions}
        handleTableChange={handleTableChange}
        listLoading={loading} />
    </Container>
    
  );
};
export default ProductFeeTable;
