import React, { useState } from 'react';
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Input } from '../../../components/common/controls';
import DialogModalFooter from "../../../components/DialogModalFooter";

const ProductAttributeSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, "En az 3 karakter")
    .max(50, "En fazla 50 karakter")
    .required("Varyant adı gerekli"),
});

const ProductAttributeForm = ({ saveProductAttribute, productAttribute, onHide }) => {
  const [closeWindow, setCloseWindow] = useState(false);
  const handleCloseWindow = () => {
    setCloseWindow(!closeWindow);
  };
  const _handleSubmit = (values, { resetForm }) => {
    saveProductAttribute(values, closeWindow);
    resetForm({});
  };
  return (
    <Formik
      initialValues={productAttribute}
      enableReinitialize={true}
      validationSchema={ProductAttributeSchema}
      onSubmit={_handleSubmit}
    >
      {({ handleSubmit }) =>
      {
        return(
          <>
            <Modal.Body className="overlay overlay-block cursor-default">
              <Form className="form form-label-right">
                <div className="form-group row">
                  <div className="col-lg-12">
                    <Field
                      name="name"
                      component={Input}
                      placeholder="Özellik Adı"
                      label="Özellik Adı"
                    />
                  </div>
                </div>
              </Form>
            </Modal.Body>
            <DialogModalFooter handleSubmit={handleSubmit} onHide={onHide} handleCloseWindow={productAttribute.id ? null : handleCloseWindow}/>
          </>
        );}
      }
    </Formik>
  );
};
export default ProductAttributeForm;
