import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import {
  NoRecordsFoundMessage,
  sortCaret,
  headerSortingClasses, post,
} from '../../../helpers';
import * as uiHelpers from "../UiHelpers";
import {
  ImageColumnFormatter,
  SourceColumnFormatter,
  NameColumnFormatter
} from "../../Users/user-table/column-formatters";
import {DefaultStatusColumnFormatter} from "../../../components";

const ProductFavoriteTable = ({ product_id }) => {
  const [queryParams, setQueryParams] = useState({ filter: {
    name: "",
  },
  sortOrder: "asc", // asc||desc
  sortField: "id",
  pageNumber: 1,
  pageSize: 10 });
  const [users, setUsers] = useState([]);
  const [total_count, setTotalCount] = useState([]);
  const handleTableChange = (type, { page, sizePerPage }) => {
    const pageNumber = page || 1;
    setQueryParams({ ...queryParams, pageNumber: pageNumber, pageSize: sizePerPage });
  };
  useEffect(() => {
    const getOrders = async () => {
      const { users, total_count, status } = await post('/favorite/admin/'+product_id, { ...queryParams });
      if(!status){
        return;
      }
      setTotalCount(total_count);
      setUsers(users);

    };
    getOrders();
  }, [queryParams]);

  const columns = [
    {
      dataField: "id",
      text: "ID",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      style: {
        width: "50px",
      },
    },
    {
      dataField: "image",
      text: "",
      sort: false,
      formatter: ImageColumnFormatter,
      classes: "text-center",
      headerClasses: "text-center",
      style: {
        width: "60px",
      },
    },
    {
      dataField: "full_name",
      text: "Adı Soyadı",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: NameColumnFormatter,

    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "gsm",
      text: "Gsm",
      sort: false,
    },
    {
      dataField: "is_active",
      text: "Durum",
      sort: false,
      formatter: DefaultStatusColumnFormatter,
      classes: "text-center",
      headerClasses: "text-center",
      style: {
        width: "70px",
      },
    },
    {
      dataField: "source",
      text: "Kaynak",
      sort: false,
      formatter: SourceColumnFormatter,
      classes: "text-center",
      headerClasses: "text-center",
      style: {
        width: "70px",
      },
    },
  ];

  const paginationOptions = {
    totalSize: total_count,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: queryParams.pageSize,
    page: queryParams.pageNumber,
  };
  return (

        <BootstrapTable
          remote
          keyField="id"
          wrapperClasses="table-responsive"
          classes="table table-head-custom table-head-bg table-vertical-center overflow-hidden"
          bootstrap4
          bordered={false}
          data={users === null ? [] : users}
          columns={columns}
          onTableChange={handleTableChange}
          defaultSorted={uiHelpers.defaultSorted}
          pagination={paginationFactory(paginationOptions)}
          noDataIndication={ () => <NoRecordsFoundMessage /> } />


  );
};
export default ProductFavoriteTable;
