import React from "react";

export function DashboardPage() {

  return (
    <div>
      <div>Dashboard</div>
    </div>
  );
}
