import React from 'react';
import { Card, CardBody, FormAlert } from '../../components/common/controls';
import { useProductSkuUIContext } from './UIContext';
import { ProductSkuTable, ProductSkuFilter } from './product-sku-table';


const ProductSkuCard = () => {
  const { error } = useProductSkuUIContext();
  return (
    <React.Fragment>
      <FormAlert visible={error !== null} message={error} />
      <Card>
        <CardBody>
          <ProductSkuFilter />
          <ProductSkuTable />
        </CardBody>
      </Card>
    </React.Fragment>
  );
};
export default ProductSkuCard;
