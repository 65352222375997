import React, { useEffect, useRef, useState } from 'react';
import { useUserUIContext } from './UIContext';
import { useHistory } from 'react-router-dom';
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar, FormAlert,
} from '../../components/common/controls';
import UserForm from './UserForm';
import { get, post, put } from '../../helpers';
import {toast} from "react-toastify";
import {MESSAGES} from "../../constant/helper";

const UserAdd = ({ id }) => {
  const history = useHistory();
  const [error, setError] = useState(null);
  const [userForEdit, setUserForEdit] = useState(undefined);
  const [tab, setTab] = useState("general");
  const [title, setTitle] = useState('Kişisel Bilgiler');
  const { initUser } = useUserUIContext();
  const btnRef = useRef();
  const saveProductClick = () => {
    if (btnRef && btnRef.current) {
      btnRef.current.click();
    }
  };
  const backToUsersList = () => {
    history.push(`/user_management/users`);
  };
  const moveToGeneralTab = () => {
    setTab("general");
    setTitle('Kişisel Bilgiler');
  };
  const moveToRoleTab = () => {
    setTab("roles");
    setTitle('Roller');
  };
  const moveToAccountTab = () => {
    setTab("company");
    setTitle('Firma');
  };
  const saveUser = async (values) => {
    toast.info(MESSAGES.loading);
    if (id) {
      const { status, message } = await put('/user/admin/edit/'+id, values);
      if(!status){
        setError(message);
        return;
      }
    } else {

      const { status, message } = await post('/user/admin/add', values);
      if(!status){
        setError(message);
        return;
      }
    }
    toast.success(MESSAGES.success);
    backToUsersList();
  };
  const getUser = async () => {
    if(id){
      const { user } = await get('/user/'+id);
      setUserForEdit(user);
    }
  };
  useEffect(() => {
    getUser();
  }, []);
  return (
    <React.Fragment>
      <FormAlert visible={error !== null} message={error} />
      <Card>
        <CardHeader title={title}>
          <CardHeaderToolbar>
            <button
              type="button"
              onClick={backToUsersList}
              className="btn btn-light"
            >
              <i className="fa fa-arrow-left" />
                Geri
            </button>
            <button
              type="submit"
              className="btn btn-primary ml-2"
              onClick={saveProductClick}
            >
                Kaydet
            </button>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <ul className="nav nav-tabs nav-tabs-line " role="tablist">
            <li className="nav-item" onClick={moveToGeneralTab}>
              <a
                className={`nav-link ${tab === "general" && "active"}`}
                data-toggle="tab"
                role="tab"
                aria-selected={(tab === "general").toString()}
              >
                  Kişisel Bilgiler
              </a>
            </li>
            <li className="nav-item" onClick={moveToRoleTab}>
              <a
                className={`nav-link ${tab === "roles" && "active"}`}
                data-toggle="tab"
                role="button"
                aria-selected={(tab === "roles").toString()}
              >
                  Roller
              </a>
            </li>
            <li className="nav-item" onClick={moveToAccountTab}>
              <a
                className={`nav-link ${tab === "company" && "active"}`}
                data-toggle="tab"
                role="tab"
                aria-selected={(tab === "company").toString()}
              >
                Firma
              </a>
            </li>
          </ul>
          <UserForm
            tab={tab}
            saveUser={saveUser}
            setError={setError}
            user={userForEdit || initUser}
            btnRef={btnRef} />
        </CardBody>
      </Card>
    </React.Fragment>

  );
};
export default UserAdd;
