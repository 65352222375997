import React from "react";
import { StockStatusCssClasses, StockStatusTitles } from '../../UiHelpers';


const StockStatusColumnFormatter = (cellContent, row) => {
  const getLabelCssClasses = () => {
    return `label label-light-${
      StockStatusCssClasses[row.stock.in_stock]
    } label-inline`;
  };
  return (
    <span className={getLabelCssClasses()}>
      {StockStatusTitles[row.stock.in_stock]}
    </span>
  );
};
export default StockStatusColumnFormatter;