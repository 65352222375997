import React, { useEffect, useState } from 'react';
import { Modal } from "react-bootstrap";
import Select from 'react-select';
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Input } from '../../../components/common/controls';
import { get, post } from '../../../helpers';
import DialogModalFooter from "../../../components/DialogModalFooter";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { useAddressUIContext } from "../UIContext";

const AddressSchema = Yup.object().shape({});

const AddressForm = ({ saveAddress, address, onHide }) => {
  const [cities, setCities] = useState([]);
  const [counties, setCounties] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState([]);
  const [loading, setLoading] = useState(true);
  const { queryParams } = useAddressUIContext();

  const getCities = async () => {
    const { cities } = await get('/address/cities/list');
    setCities(cities);
  };
  const getCounties = async (city_id) => {
    const { counties } = await get('/address/counties/'+city_id);
    setCounties(counties);
  };
  const getCompanies = async (queryParams) => {
    setLoading(true);
    const { companies } = await post('/company/list', { ...queryParams });
    setCompanies(companies);
    setLoading(false);
  };

  useEffect(()=>{
    getCities();
    getCompanies(queryParams);
  },[]);

  const _handleSubmit = (values) => {

    const city_id = values.city.id;
    const county_id = values.county.id;
    delete values.city;
    delete values.county;
    const _values = { ...values, city_id, county_id };
    saveAddress(_values);
    console.log('values', _values);
  };

  return (
    <React.Fragment>
      <Formik enableReinitialize={true} initialValues={address} validationSchema={AddressSchema} onSubmit={_handleSubmit}>
        {({ handleSubmit, setFieldValue, values }) => {
          const filterBy = () => true;
          const _handleCompanyChange = (item)=> {
            if(item.length > 0){
              setSelectedCompany(item);
              setFieldValue('parent_id', item[0].id);
            }
          };
          const _handleSearch = async (query) => {
            const newQueryParams = { ...queryParams };
            const filter = {};
            if (query) {
              filter.name = query;
            }
            newQueryParams.filter = filter;
            await getCompanies(newQueryParams);
          };
          const _handleCityChange = (item) => {
            getCounties(item.id);
            setFieldValue('city', item);
          };
          const _handleCountyChange = (item) => {
            setFieldValue('county', item);
          };
          return (
            <React.Fragment>
              <Modal.Body className="overlay overlay-block cursor-default">
                <Form className="form form-label-right">

                  <div className="form-group row">
                    <div className="col-lg-12">
                      <label>İl*</label>
                      <Select
                        placeholder={'İl'}
                        className="basic-single"
                        classNamePrefix="select"
                        name="city"
                        options={cities}
                        getOptionLabel={ x => x.name}
                        getOptionValue={ x => x.id}
                        value={values.city}
                        onChange={_handleCityChange}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <div className="col-lg-12">
                      <label>İlçe*</label>
                      <Select
                        placeholder={'İlçe'}
                        className="basic-single"
                        classNamePrefix="select"
                        name="county"
                        options={counties}
                        getOptionLabel={ x => x.name}
                        getOptionValue={ x => x.id}
                        value={values.county}
                        onChange={_handleCountyChange}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <div className="col-lg-12">
                      <label>Firma*</label>
                      <AsyncTypeahead
                        filterBy={filterBy}
                        id={'companySearch'}
                        isLoading={loading}
                        // isInvalid={values.parent_id === undefined}
                        // isValid={values.parent_id !== undefined}
                        labelKey="name"
                        minLength={3}
                        onChange={_handleCompanyChange}
                        onSearch={_handleSearch}
                        options={companies}
                        selected={selectedCompany}
                        placeholder={'Firma'}
                      />
                    </div>
                  </div>

                  <Field
                    name="email"
                    component={Input}
                    placeholder="Email"
                    label="Email"
                  />
                  <Field
                    name="name"
                    component={Input}
                    placeholder="Adres Adı"
                    label="Adres Adı"
                  />
                  <Field
                    name="phone1"
                    component={Input}
                    placeholder="Telefon Numarası"
                    label="Telefon Numarası"
                  />
                  <Field
                    name="phone2"
                    component={Input}
                    placeholder="Yedek Telefon Numarası"
                    label="Yedek Telefon Numarası"
                  />
                  <Field
                    name="title"
                    component={Input}
                    placeholder="Başlık"
                    label="Başlık"
                  />
                  <Field
                    name="address1"
                    component={Input}
                    placeholder="Adres"
                    label="Adres"
                  />
                  <Field
                    name="address2"
                    component={Input}
                    placeholder="Adres Açıklaması"
                    label="Adres Açıklaması"
                  />
                  <Field
                    name="tax_office"
                    component={Input}
                    placeholder="Vergi Dairesi"
                    label="Vergi Dairesi"
                  />
                  <Field
                    name="tax_number"
                    component={Input}
                    placeholder="Vergi Numarası"
                    label="Vergi Numarası"
                  />

                </Form>
              </Modal.Body>
              <DialogModalFooter handleSubmit={handleSubmit} onHide={onHide}/>
            </React.Fragment>
          );
        }
        }
      </Formik>
    </React.Fragment>
  );
};
export default AddressForm;
