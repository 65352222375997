import React from "react";
import { OrderStatusTitles, OrderStatusClasses } from '../../UiHelpers';
const StatusColumnFormatter = (cellContent, row) => {
  const { status } = row;
  const getLabelCssClasses = (key) => {
    return `label label-light-${
      OrderStatusClasses[key]
    } label-inline ml-2`;
  };
  return <div><span className={getLabelCssClasses(status)}>{OrderStatusTitles[status]}</span></div>;
};
export default StatusColumnFormatter;
