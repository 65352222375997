import React from "react";
import { OrderSourceClasses, OrderSourceTitles } from '../../UiHelpers';


const SourceColumnFormatter = (cellContent, row) => {
  const { source } = row;
  const getLabelCssClasses = () => {
    return `label label-sm  label-${
      OrderSourceClasses[source]
    } label-inline`;
  };
  return (
    <span className={getLabelCssClasses()}>
      {OrderSourceTitles[source]}
    </span>
  );
};
export default SourceColumnFormatter;
