import React, { useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Link, useHistory } from "react-router-dom";
import { ModalProgressBar } from "../../../components/common/controls";
import { put } from "../../../helpers";
import { useUserUIContext } from "../UIContext";

const PersonaInformation = () => {
  const { push } = useHistory();
  const { userForEdit: user } = useUserUIContext();
  const [loading, setloading] = useState(false);

  const saveUser = async (values, setStatus, setSubmitting) => {
    setloading(true);
    const { status } = await put('/user/admin/edit/'+user.id, values);
    setloading(false);
    setSubmitting(false);
    if(!status){
      return;
    }
    push('/user_management/users/'+user.id+'/overview');
  };
  const initialValues = {
    first_name: user && user.first_name,
    last_name: user && user.last_name,
    gsm: user && user.gsm,
    email: user && user.email,
  };
  const Schema = Yup.object().shape({
    first_name: Yup.string().required("Ad yazınız"),
    last_name: Yup.string().required("Soyad yazınız"),
    email: Yup.string()
      .email("Geçersiz email formatı")
      .required("Email yazınız"),
  });
  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };
  const formik = useFormik({
    initialValues,
    validationSchema: Schema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      saveUser(values, setStatus, setSubmitting);
    },
    onReset: (values, { resetForm }) => {
      resetForm();
    },
  });

  return (
    <form className="card card-custom card-stretch" onSubmit={formik.handleSubmit}>
      {loading && <ModalProgressBar />}
      <div className="card-header py-3">
        <div className="card-title align-items-start flex-column">
          <h3 className="card-label font-weight-bolder text-dark">
                        Kişisel Bilgiler
          </h3>
          <span className="text-muted font-weight-bold font-size-sm mt-1">
           Kullanıcı bilgilerini güncelleyin!
          </span>
        </div>
        <div className="card-toolbar">
          <button
            type="submit"
            className="btn btn-success mr-2"
            disabled={formik.isSubmitting || formik.touched && !formik.isValid}>
            Güncelle
            {formik.isSubmitting}
          </button>
          <Link to={'/user_management/users/'+user.id+'/overview'} className="btn btn-secondary">İptal</Link>
        </div>
      </div>
      <div className="form">
        <div className="card-body">
          <div className="row">
            <label className="col-xl-3" />
            <div className="col-lg-9 col-xl-6">
              <h5 className="font-weight-bold mb-6">Kullanıcı Bilgileri</h5>
            </div>
          </div>
          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">
                            Ad
            </label>
            <div className="col-lg-9 col-xl-6">
              <input
                type="text"
                placeholder="Ad"
                className={`form-control form-control-lg form-control-solid ${getInputClasses(
                  "first_name"
                )}`}
                name="first_name"
                {...formik.getFieldProps("first_name")}
              />
              {formik.touched.first_name && formik.errors.first_name ?
                <div className="invalid-feedback">
                  {formik.errors.first_name}
                </div>
                : null}
            </div>
          </div>
          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">
                            Soyad
            </label>
            <div className="col-lg-9 col-xl-6">
              <input
                type="text"
                placeholder="Soyad"
                className={`form-control form-control-lg form-control-solid ${getInputClasses(
                  "last_name"
                )}`}
                name="last_name"
                {...formik.getFieldProps("last_name")}
              />
              {formik.touched.last_name && formik.errors.last_name ?
                <div className="invalid-feedback">{formik.errors.last_name}</div>
                : null}
            </div>
          </div>
          <div className="row">
            <label className="col-xl-3" />
            <div className="col-lg-9 col-xl-6">
              <h5 className="font-weight-bold mt-10 mb-6">İletişim Bilgileri</h5>
            </div>
          </div>
          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">
                            Telefon
            </label>
            <div className="col-lg-9 col-xl-6">
              <div className="input-group input-group-lg input-group-solid">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="fa fa-phone" />
                  </span>
                </div>
                <input
                  type="text"
                  placeholder="+90 532 112 11 11"
                  className={`form-control form-control-lg form-control-solid ${getInputClasses(
                    "gsm"
                  )}`}
                  name="gsm"
                  {...formik.getFieldProps("gsm")}
                />
              </div>
              {formik.touched.gsm && formik.errors.gsm ?
                <div className="invalid-feedback display-block">
                  {formik.errors.gsm}
                </div>
                : null}
            </div>
          </div>
          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">
                            Email Adresi
            </label>
            <div className="col-lg-9 col-xl-6">
              <div className="input-group input-group-lg input-group-solid">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="fa fa-at" />
                  </span>
                </div>
                <input
                  type="email"
                  placeholder="Email"
                  className={`form-control form-control-lg form-control-solid ${getInputClasses(
                    "email"
                  )}`}
                  name="email"
                  {...formik.getFieldProps("email")}
                />
              </div>
              {formik.touched.email && formik.errors.email ?
                <div className="invalid-feedback display-block">
                  {formik.errors.email}
                </div>
                : null}
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};
export default PersonaInformation;
