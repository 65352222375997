import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import objectPath from "object-path";
import SVG from "react-inlinesvg";
import { useHtmlClassService } from "../../core/MetronicLayout";
import { toAbsoluteUrl } from "../../../helpers";

export function Brand() {
  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      brandClasses: uiService.getClasses("brand", true),
      asideSelfMinimizeToggle: objectPath.get(
        uiService.config,
        "aside.self.minimize.toggle"
      ),
      headerLogo: uiService.getLogo(),
      headerStickyLogo: uiService.getStickyLogo()
    };
  }, [uiService]);

  return (
    <React.Fragment>
      <div className={`brand flex-column-auto ${layoutProps.brandClasses}`} id="kt_brand">
        <Link to="" className="brand-logo">
          {layoutProps.headerLogo}
        </Link>
        {layoutProps.asideSelfMinimizeToggle &&
          <React.Fragment>
            <button className="brand-toggle btn btn-sm px-0" id="kt_aside_toggle">
              <span className="svg-icon svg-icon-xl">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Angle-double-left.svg")}/>
              </span>
            </button>
          </React.Fragment>
        }
      </div>
    </React.Fragment>
  );
}
