import React, { useEffect, useMemo, useState } from "react";
import { Formik } from "formik";
import Select from 'react-select';
import { isEqual } from "lodash";
import { useAddressUIContext } from '../UIContext';
import { get } from "../../../helpers";
import { useHistory } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";

const prepareFilter = (queryParams, values) => {
  const { status, searchText, city_id, county_id, company_name } = values;
  const newQueryParams = { ...queryParams };
  const filter = {};
  filter.status = status !== "" ? + status : undefined;
  if (company_name) filter.company_name = company_name;
  if (city_id) filter.city_id = city_id;
  if (county_id) filter.county_id = county_id;
  if (searchText) {
    filter.address1 = searchText;
  }
  newQueryParams.filter = filter;
  return newQueryParams;
};

const AddressFilter = () => {
  const [cities, setCities] = useState([]);
  const [counties, setCounties] = useState([]);
  const addressUiContext = useAddressUIContext();
  const addressUIProps = useMemo(() => {
    return {
      queryParams: addressUiContext.queryParams,
      setQueryParams: addressUiContext.setQueryParams,
    };
  }, [addressUiContext]);

  const getCities = async () => {
    const { cities } = await get('/address/cities/list');
    const _cities = cities.map(item => {return { label: item.name, value: item.id };});
    setCities(_cities);
  };
  const getCounties = async (city_id) => {
    const { counties } = await get('/address/counties/'+city_id);
    const _counties = counties.map(item => {return { label: item.name, value: item.id };});
    setCounties(_counties);
  };
  const applyFilter = (values) => {
    const newQueryParams = prepareFilter(addressUIProps.queryParams, values);
    if (!isEqual(newQueryParams, addressUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      addressUIProps.setQueryParams(newQueryParams);
    }
  };
  const initialValues = {
    status: "1",
  };
  const statusOptions = [
    { value: '', label: 'Tümü' },
    { value: '1', label: 'Aktif' },
    { value: '0', label: 'Pasif' }
  ];
  const _handleSubmit = (values) => applyFilter(values);
  const { push } = useHistory();
  const openAddressAddModal = () => {
    push("/seller_management/addresses/new");
  };
  useEffect(()=> {
    getCities();
  },[]);
  return (
    <Formik initialValues={initialValues} onSubmit={_handleSubmit}>
      {({ values, handleSubmit, handleBlur, handleChange, setFieldValue }) =>
      {
        const _handleSelectChange = (item)=>{
          setFieldValue("status", item.value);
        };
        const _handleCityChange = (item)=>{
          setFieldValue("city_id", item.value);
          setFieldValue("county_id", undefined);
          getCounties(item.value);
        };
        const _handleCountyChange = (item)=>{
          setFieldValue("county_id", item.value);
        };
        return(
          <form onSubmit={handleSubmit} className="form form-label-right">
            <div className="form-group row">
              <div className="col-lg-2">
                <input
                  type="text"
                  className="form-control"
                  name="company_name"
                  placeholder="Firma"
                  onBlur={handleBlur}
                  value={values.company_name}
                  onChange={(e) => {
                    setFieldValue("company_name", e.target.value);
                    handleSubmit();
                  }}
                />
              </div>
              <div className="col-lg-2">
                <input
                  type="text"
                  className="form-control"
                  name="search"
                  placeholder="Adres"
                  onBlur={handleBlur}
                  value={values.searchText}
                  onChange={(e) => {
                    setFieldValue("searchText", e.target.value);
                    handleSubmit();
                  }}
                />
              </div>

              <div className="col-lg-8">
                <Dropdown onClick={(e) => e.stopPropagation(e)} drop="down" className="float-right ml-2">
                  <Dropdown.Toggle>
                    Filtreleme Seçenekleri
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
                    <div className="navi navi-spacer-x-0 p-5">
                      <div className="form-group row">
                        <div className="col-lg-12 mt-2">
                          <Select onChange={_handleSelectChange}
                            options={statusOptions}
                            placeholder={'Durum'}
                            value={statusOptions.filter(item => item.value === values.status)}/>
                          <small className="form-text text-muted">
                            Duruma <b>Göre</b>
                          </small>
                        </div>
                        <div className="col-lg-12 mt-2">
                          <Select onChange={_handleCityChange}
                            options={cities}
                            placeholder={'İl'}
                            value={cities.filter(item => item.value === values.city_id)}/>
                          <small className="form-text text-muted">
                            İle <b>Göre</b>
                          </small>
                        </div>
                        <div className="col-lg-12 mt-2">
                          <Select onChange={_handleCountyChange}
                            options={counties}
                            placeholder={'İlçe'}
                            value={counties.filter(item => item.value === values.county_id)}/>
                          <small className="form-text text-muted">
                            İlçeye <b>Göre</b>
                          </small>
                        </div>
                        <div className="col-lg-12 mt-2">
                          <button type="button" className="btn btn-outline-primary btn-block" onClick={handleSubmit}>
                            Uygula
                          </button>
                        </div>
                      </div>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
                <button type="button" className="btn btn-info ml-2 float-right" onClick={openAddressAddModal}>
                  Yeni Adres
                </button>
              </div>
            </div>
          </form>
        );}
      }
    </Formik>
  );
};
export default AddressFilter;
