import React, { useEffect, useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { get, post, put, toAbsoluteUrl } from '../../../helpers';
import { useDropzone } from 'react-dropzone';
import { useBrandUIContext } from '../UIContext';
import { toast } from "react-toastify";
import { MESSAGES } from "../../../constant/helper";

const BrandLogoDialog = ({ id, show, onHide }) => {
  const brandUIContext = useBrandUIContext();
  const { queryParams } = brandUIContext;
  const [brandForEdit, setBrandForEdit] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const { acceptedFiles, getInputProps } = useDropzone();

  const brandUIProps = useMemo(() => {
    return {
      setError: brandUIContext.setError,
      setState: brandUIContext.setState
    };
  }, [brandUIContext]);

  const getBrand = async () => {
    if(!id){
      return;
    }
    const { brand } = await get('/brand/admin/'+id);
    setBrandForEdit(brand);
  };
  useEffect(() => {
    getBrand();
  }, [id]);

  const fileToBase64 = async (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (e) => reject(e);
    });
  const _handleUpload = async () => {
    setLoading(true);
    toast.info(MESSAGES.uploadStart);
    const imageStr = await fileToBase64(acceptedFiles[0]);
    brandForEdit['image'] = imageStr.split(',')[1];
    const { status, message } = await put('/brand/admin/'+id, brandForEdit);
    if(!status){
      brandUIProps.setError(message);
      onHide();
      return;
    }
    toast.success(MESSAGES.uploadEnd);
    const { brands, status:status_, message:message_, total_count } = await post('/brand/admin/list', { ...queryParams });
    if(!status_){
      brandUIProps.setError(message_);
      return;
    }
    await brandUIProps.setState({ brands, total_count });
    setLoading(false);
    onHide();
  };
  const getBrandPic = () => {
    if (!brandForEdit?.image) {
      return "none";
    }
    return brandForEdit.image;
  };

  const changeImage = async () => {
    const imageStr = await fileToBase64(acceptedFiles[0]);
    setBrandForEdit({ ...brandForEdit, image : imageStr });
  };

  useEffect(() => {
    if (acceptedFiles.length > 0) {
      changeImage();
    }
  },[acceptedFiles]);
  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>{brandForEdit && brandForEdit.name+' Logo'}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="overlay overlay-block cursor-default">
        {/*<aside>*/}
        {/*  <h4>Dosya</h4>*/}
        {/*  <ul>{files}</ul>*/}
        {/*</aside>*/}

        <div className="d-flex justify-content-center">
          <div className="col-lg-9 col-xl-6">
            <div
              className="image-input image-input-outline d-flex justify-content-center"
              id="kt_profile_avatar"
              style={{
                backgroundImage: `url(${toAbsoluteUrl(
                  "/media/users/blank.png"
                )}`,
              }}
            >
              <div
                className="image-input-wrapper"
                style={{ backgroundImage: `url(${getBrandPic()})` }}
              />
              <label
                className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                data-action="change"
                data-toggle="tooltip"
                title=""
                data-original-title="Change avatar"
              >
                <i className="fa fa-pen icon-sm text-muted" />
                <input
                  {...getInputProps()}
                  type="file"
                  accept=".png, .jpg, .jpeg"
                />
                <input type="hidden" name="profile_avatar_remove" />
              </label>
              <span
                className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                data-action="cancel"
                data-toggle="tooltip"
                title=""
                data-original-title="Cancel avatar"
              >
                <i className="ki ki-bold-close icon-xs text-muted" />
              </span>
            </div>
            <span className="form-text text-muted">
                Dosya Türleri: png, jpg, jpeg.
            </span>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          onClick={onHide}
          className="btn btn-light btn-elevate"
        >
          İptal
        </button>
        <button type="button" onClick={_handleUpload} className="btn btn-info btn-elevate" disabled={loading}>Güncelle</button>
      </Modal.Footer>
    </Modal>
  );
};
export default BrandLogoDialog;
