import React from "react";
import { Badge } from 'react-bootstrap';
import { Link } from "react-router-dom";

const NameColumnFormatter = (cellContent, row) =>{
  return (
    <React.Fragment>
      <Link to={`/user_management/users/${row.id}`} className="text-dark-75 font-weight-bolder text-hover-primary mb-1">{row.full_name}</Link>
      {row.company && <div className={'font-weight-light'}>{row.company.name}</div>}
      <div className={'text-muted'}>{row.create_date}
        {row.is_new && <Badge className={'ml-2'} pill variant="danger">Yeni</Badge>}
      </div>
    </React.Fragment>
  );
};
export default NameColumnFormatter;
