import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { useCollectionUIContext } from '../CollectionUIContext';
import { delete_, post } from '../../../helpers';
import DialogModalFooter from "../../../components/DialogModalFooter";

const CollectionDeleteDialog = ({ id, show, onHide }) => {
  const collectionUIContext = useCollectionUIContext();
  const collectionUIProps = useMemo(() => {
    return {
      setError: collectionUIContext.setError,
      setState: collectionUIContext.setState
    };
  }, [collectionUIContext]);

  const { queryParams } = collectionUIContext;

  useEffect(() => {
    if (!id) {
      onHide();
    }
  }, [id]);


  const deleteCollection = async () => {
    const { status, message } = await delete_(`collection/delete/${id}`);
    if(!status){
      collectionUIProps.setError(message);
      onHide();
      return;
    }
    const { collections, status:_status, message:_message, total_count } = await post('/collection/list', { ...queryParams });
    if(!_status){
      collectionUIProps.setError(_message);
      return;
    }
    await collectionUIProps.setState({ collections, total_count });
    onHide();
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
    >
      <Modal.Header closeButton>
        <Modal.Title>
            Durum Güncelle
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span>Seçtiğiniz kampanyanın durumu değiştirilecek. Devam etmek istiyor musunuz?</span>
      </Modal.Body>
      <DialogModalFooter type={'delete'} handleSubmit={deleteCollection} onHide={onHide}/>
    </Modal>
  );
};
export default CollectionDeleteDialog;
