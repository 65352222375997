import React from "react";
import { PaginationLinks, PaginationToolbar } from "./index";

const Pagination = ({ children, isLoading, paginationProps }) => {
  return (
    <React.Fragment>
      {children}
      <div className="d-flex justify-content-between align-items-center flex-wrap">
        <PaginationToolbar
          isLoading={isLoading}
          paginationProps={paginationProps}
        />
        <PaginationLinks paginationProps={paginationProps} />
      </div>
    </React.Fragment>
  );
};
export default Pagination;
