import { types } from 'mobx-state-tree';

export const User = types.model('User', {
  id: types.optional(types.integer, 0),
  status: types.optional(types.integer,0 ),
  email: types.optional(types.string, ''),
  full_name: types.optional(types.string, ''),
  first_name: types.optional(types.string, ''),
  last_name: types.optional(types.string, ''),
  gsm: types.optional(types.string, ''),
  image: types.optional(types.string,''),
});
