import React from "react";

const NameColumnFormatter = (cellContent, row) =>{
  return (
    <React.Fragment>
      <div className="text-dark-75 font-weight-bold text-hover-info mb-1">{row.name}</div>
      <div className={'font-size-xs font-weight-light'}>Oluşturma Tarihi: {row.create_date}</div>
    </React.Fragment>
  );
};
export default NameColumnFormatter;
