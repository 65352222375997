import React, { useEffect, useState } from "react";
import { ModalProgressBar } from "../../../components/common/controls";
import { headerSortingClasses, post, sortCaret } from "../../../helpers";
import {
  StatusColumnFormatter,
  KeyColumnFormatter,
  SourceColumnFormatter
} from "../../Orders/order-table/column-formatters";
import * as uiHelpers from "../UiHelpers";
import { DataTable } from "../../../components";

const UserOrder = ({id}) =>{
  const [queryParams, setQueryParams] = useState({ filter: {
    key: "",
  },
  sortOrder: "desc", // asc||desc
  sortField: "id",
  pageNumber: 1,
  pageSize: 10 });
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [total_count, setTotalCount] = useState([]);
  const handleTableChange = (type, { page, sizePerPage }) => {
    const pageNumber = page || 1;
    setQueryParams({ ...queryParams, pageNumber: pageNumber, pageSize: sizePerPage });
  };
  const getData = async () => {
    setLoading(true);

    const { orders, total_count } = await post('/order/admin/list', { ...queryParams, filter:{user_id: id} });
    setTotalCount(total_count);
    setData(orders);
    setLoading(false);
  };

  const columns = [
    {
      dataField: "id",
      text: "ID",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      style: {
        width: "40px",
      },
    },
    {
      dataField: "key",
      text: "Sipariş Numarası",
      formatter: KeyColumnFormatter,
    },
    {
      dataField: "buyer.full_name",
      text: "Alıcı",
    },
    {
      dataField: "total_count",
      text: "Ürün Çeşidi",
    },
    {
      dataField: "total_quantity",
      text: "Ürün Sayısı",
    },
    {
      dataField: "total_cost",
      text: "Toplam Fiyat",
    },
    {
      dataField: "is_paid",
      text: "Ödeme",
      formatter: SourceColumnFormatter,
    },
    {
      dataField: "is_completed",
      text: "Durum",
      formatter: StatusColumnFormatter,
    },
  ];


  useEffect(() => {
    getData();
  },[]);
  const paginationOptions = {
    custom: true,
    totalSize: total_count,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: queryParams.pageSize,
    page: queryParams.pageNumber,
  };
  return (
    <div className="card card-custom">
      {loading && <ModalProgressBar />}
      <div className="card-header py-3">
        <div className="card-title align-items-start flex-column">
          <h3 className="card-label font-weight-bolder text-dark">
                        Siparişler
          </h3>
          <span className="text-muted font-weight-bold font-size-sm mt-1">
            Kullanıcının siparişlerini görüntüleyin
          </span>
        </div>
      </div>
      <div className="card-body">
        <DataTable
          data={data}
          defaultSorted={uiHelpers.defaultSorted}
          columns={columns}
          paginationOptions={paginationOptions}
          handleTableChange={handleTableChange}
          listLoading={loading} />
      </div>
    </div>
  );
};

export default UserOrder;
