import React, { useEffect, useState } from 'react';
import { Modal } from "react-bootstrap";
import { Typeahead } from 'react-bootstrap-typeahead';
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Input } from '../../../components/common/controls';
import { get, post } from '../../../helpers';


const SubCategorySchema = Yup.object().shape({
  name: Yup.string()
    .min(3, "En az 3 karakter")
    .max(50, "En fazla 50 karakter")
    .required("Alt Kategori adı gerekli"),
  parent_id: Yup.string()
    .required("Ana Kategori adı gerekli"),
});

const SubCategoryForm = ({ saveSubCategory, subCategory, onHide }) => {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const getCategories = async () => {
    const { categories } = await post('/category/list', { });
    setCategories(categories);
  };

  useEffect(()=>{
    const getCategory = async (id) => {
      const { category } = await get('category/'+id);
      setSelectedCategory([category]);
    };
    if(subCategory.id !== undefined){
      getCategory(subCategory.parent_id);
    }
  },[subCategory]);
  useEffect(() => {getCategories();},[]);
  const _handleSubmit = (values) => saveSubCategory(values);
  return (
    <Formik
      initialValues={subCategory}
      enableReinitialize={true}
      validationSchema={SubCategorySchema}
      onSubmit={_handleSubmit}
    >
      {({ values, handleSubmit, setFieldValue }) =>
      {
        const _handleCategoryChange = (item) => {
          if(item.length > 0){
            setSelectedCategory(item);
            setFieldValue('parent_id', item[0].id);
          }
        };
        return(
          <React.Fragment>
            <Modal.Body className="overlay overlay-block cursor-default">
              <Form className="form form-label-right">
                <div className="form-group row">
                  <div className="col-lg-12">
                    <label>Ana Kategori</label>
                    <Typeahead
                      id={'categorySearch'}
                      isInvalid={values.parent_id === ''}
                      isValid={values.parent_id !== ''}
                      labelKey={'name'}
                      onChange={_handleCategoryChange}
                      options={categories}
                      selected={selectedCategory}
                      placeholder={'Ana Kategori'}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-12">
                    <Field
                      name="name"
                      component={Input}
                      placeholder="Alt Kategori Adı"
                      label="Alt Kategori Adı"
                    />
                  </div>
                </div>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                onClick={onHide}
                className="btn btn-light btn-elevate"
              >
                  İptal
              </button>
              <button
                type="submit"
                onClick={handleSubmit}
                className="btn btn-primary btn-elevate"
              >
                  Kaydet
              </button>
            </Modal.Footer>
          </React.Fragment>
        );}
      }
    </Formik>
  );
};
export default SubCategoryForm;
