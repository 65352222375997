import React from "react";
import { CollectionUIProvider } from './CollectionUIContext';
import { useHistory, Switch, Route } from 'react-router-dom';
import CollectionCard from './CollectionCard';
import { CollectionDeleteDialog } from './dialog';
import { ContentRoute } from '../../components';
import { CollectionAdd, CollectionInformation } from './index';

const CollectionPage = () => {
  const { push } = useHistory();
  const _onHide = () => push("/product_management/collections");
  const _handleEdit = ({ match }) => <CollectionAdd id={match && match.params.id}/>;
  const _handleDeleteModal = ({ match }) => <CollectionDeleteDialog show={match != null} id={match && match.params.id} onHide={_onHide} />;
  const _collectionInfo = ({ match }) => <CollectionInformation id={match && match.params.id}/>;
  return (
    <CollectionUIProvider>
      <Route path="/product_management/collections/:id/delete" render={_handleDeleteModal}/>
      <Switch>
        <ContentRoute path="/product_management/collections/new" component={CollectionAdd} />
        <ContentRoute path="/product_management/collections/:id/edit" component={_handleEdit} />
        <ContentRoute path="/product_management/collections/:id/information" component={_collectionInfo} />
        <ContentRoute component={CollectionCard} />
      </Switch>
    </CollectionUIProvider>
  );
};
export default CollectionPage;
