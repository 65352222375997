import React from 'react';
import { Card, CardBody, FormAlert } from '../../components/common/controls';
import { useRoleUIContext } from './UIContext';
import { RoleTable, RoleFilter } from './role-table';

const RoleCard = () => {
  const { error } = useRoleUIContext();

  return (
    <>
      <FormAlert visible={error !== null} message={error} />
      <Card>
        <CardBody>
          <RoleFilter />
          <RoleTable />
        </CardBody>
      </Card>
    </>
  );
};
export default RoleCard;
