import React, { useEffect, useState } from 'react';
import { Formik, Form, Field } from "formik";
import Select from 'react-select';
import * as Yup from "yup";
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { Input } from "../../components/common/controls";
import { post, get } from '../../helpers';
import { useProductSkuUIContext } from './UIContext';

const ProductSkuEditSchema = Yup.object().shape({
  price: Yup.number()
    .min(1, "1TL en az")
    .max(1000000, "1000000TL en fazla")
    .required("Fiyat gerekli"),
  stock: Yup.number()
    .min(1, "1 Adet en az")
    .max(1000000, "1000000 Adet en fazla")
    .required("Stok gerekli"),
});

export function ProductSkuForm({
  product,
  btnRef,
  setError,
  saveProductSku,
}) {
  const { queryParams } = useProductSkuUIContext();
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [productType, setProductType] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [currentVariant, setCurrentVariant] = useState(undefined);
  const _onSubmit = (values) => {
    if(currentVariant){
      if(product.id !== undefined){
        values.variants = [];
      }
      values.variants.push(currentVariant);
    }
    saveProductSku(values);
  };
  const getProducts = async (queryParams) => {
    setIsLoading(true);
    const { products } = await post('/product/list', { ...queryParams });
    setProducts(products);
    setIsLoading(false);
  };
  const getProduct = async (id) => {
    const { product } = await get('/product/'+id);
    setSelectedProduct([product]);
  };
  useEffect(() => {getProducts(queryParams);},[]);
  useEffect(() => {
    if(!product.id){
      return;
    }
    const { type:{ id }, attributes, parent_id } = product;
    setCurrentVariant(attributes);
    getProduct(parent_id);
    getType(id);
  },[product]);
  const getType = async (id) => {
    const { product_type, status, message } = await get('/product_type/admin/'+id);
    if(!status){
      setError(message);
      return;
    }
    setProductType(product_type);
  };
  const handleVariants = () => {
    if (!productType){
      return;
    }
    const { variants } = productType;
    return <React.Fragment>
      <div className="form-group row">
        {variants.map(attribute => {
          const _handleAttributeChange = (item) => {
            const variant_ = { ...currentVariant };
            variant_[attribute.id] = item.id;
            setCurrentVariant(variant_);
          };
          let filtered = undefined;
          if (product.id !== undefined){
            if (currentVariant) {
              filtered = attribute.values.filter(a => currentVariant[attribute.id] === a.id);
            }
            if (filtered && filtered.length > 0){
              filtered = filtered[0];
            }
          }

          return (
            <div className="col-lg-4" key={attribute.id}>
              <Select
                placeholder={attribute.name}
                className="basic-single"
                classNamePrefix="select"
                name="variant"
                getOptionLabel={ x => x.name}
                getOptionValue={ x => x.id}
                value={product.id && filtered}
                onChange={_handleAttributeChange}
                options={attribute.values}
              />
            </div>
          );
        })}
      </div>
      <div className="form-group row">
        <div className="col-lg-6">
          <Field
            type={'number'}
            name={'price'}
            component={Input}
            placeholder={'Fiyat'}
          />
        </div>
        <div className="col-lg-6">
          <Field
            type={'number'}
            name={'stock'}
            component={Input}
            placeholder={'Stok'}
          />
        </div>
      </div>
    </React.Fragment>;
  };

  return (
    <React.Fragment>
      <Formik
        enableReinitialize={true}
        initialValues={product}
        validationSchema={ProductSkuEditSchema}
        onSubmit={_onSubmit}
      >
        {({ handleSubmit, setFieldValue, values }) => {
          const filterBy = () => true;
          const _handleSearch = async (query) => {
            const newQueryParams = { ...queryParams };
            const filter = {};
            if (query) {
              filter.name = query;
            }
            newQueryParams.filter = filter;
            await getProducts(newQueryParams);
          };
          const _handleChange = (item) => {
            if(item.length > 0){
              setSelectedProduct(item);
              setFieldValue('parent_id', item[0].id);
              const { type:{ id } } = item[0];
              getType(id);
            }
          };
          return (
            <React.Fragment>
              <Form className="form form-label-right">
                <div style={{ minHeight: 250 }} className="mt-5">
                  <React.Fragment>
                    <div className="form-group row">
                      <div className="col-lg-12">
                        <label>Ürün Seçiniz</label>
                        <AsyncTypeahead
                          filterBy={filterBy}
                          id="productSearch"
                          isLoading={isLoading}
                          isInvalid={values.parent_id === undefined}
                          isValid={values.parent_id !== undefined}
                          labelKey="name"
                          minLength={3}
                          onChange={_handleChange}
                          onSearch={_handleSearch}
                          options={products}
                          selected={selectedProduct}
                          placeholder="Stok Eklenecek Ürün..."
                        />
                      </div>
                    </div>
                    {productType && !productType.has_variants ? <div className="form-group row">
                      <div className="col-lg-6">
                        <Field
                          type="number"
                          name="price"
                          component={Input}
                          placeholder="Fiyat"
                          label="Fiyat"
                          customFeedbackLabel={'Fiyat giriniz'}
                        />
                      </div>
                      <div className="col-lg-6">
                        <Field
                          type="number"
                          name="stock"
                          component={Input}
                          placeholder="Stok"
                          label="Stok"
                          customFeedbackLabel={'Stok giriniz'}
                        />
                      </div>
                    </div> : handleVariants()}
                  </React.Fragment>
                </div>
                <button
                  type="submit"
                  style={{ display: "none" }}
                  ref={btnRef}
                  onSubmit={handleSubmit}
                />
              </Form>
            </React.Fragment>

          );
        }

        }
      </Formik>
    </React.Fragment>
  );
}
