import { headerSortingClasses, sortCaret } from "../../../helpers";
import {
  KeyColumnFormatter,
  SourceColumnFormatter,
  StatusColumnFormatter
} from "./column-formatters";

const columns = [
  {
    dataField: "id",
    text: "ID",
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
    style: {
      width: "40px",
    },
  },
  {
    dataField: "key",
    text: "Sipariş Bilgileri",
    formatter: KeyColumnFormatter,
  },
  {
    dataField: "total_count",
    text: "Kalem",
  },
  {
    dataField: "total_quantity",
    text: "Adet",
  },
  {
    dataField: "total_cost",
    text: "Fiyat",

  },
  {
    dataField: "is_completed",
    text: "Durum",
    formatter: StatusColumnFormatter,
  },
  {
    dataField: "source",
    text: "",
    formatter: SourceColumnFormatter,
  },
];

export default columns;