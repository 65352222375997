import React, { useState } from "react";
import { Nav, Tab } from "react-bootstrap";
import { ModalProgressBar } from "../../../components/common/controls";
import { useUserUIContext } from "../UIContext";
import { UserLog } from "../component";

const UserGeneralInfo = () =>{
  const [key, setKey] = useState("statistics");
  const { userForEdit:user } = useUserUIContext();
  const [loading, setloading] = useState(false);


  return (
    <div className="card card-custom">
      {loading && <ModalProgressBar />}
      <div className="card-header border-0 pt-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label font-weight-bolder text-dark">
            Kullanıcı Önizleme
          </span>
          <span className="text-muted mt-3 font-weight-bold font-size-sm">
            Kullanıcıya ait özet bilgileri görüntüleyin
          </span>
        </h3>
        <div className="card-toolbar">
          <Tab.Container defaultActiveKey={key}>
            <Nav
              as="ul"
              onSelect={(_key) => setKey(_key)}
              className="nav nav-pills nav-pills-sm nav-dark-75"
            >
              <Nav.Item className="nav-item" as="li">
                <Nav.Link
                  eventKey="statistics"
                  className={`nav-link py-2 px-4 ${
                    key === "statistics" ? "active" : ""
                  }`}
                >
                                İstatistikler
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="nav-item" as="li">
                <Nav.Link
                  eventKey="logs"
                  className={`nav-link py-2 px-4 ${
                    key === "logs" ? "active" : ""
                  }`}
                >
                                Loglar
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Tab.Container>
        </div>
      </div>
        
      <div className="card-body">
        <div className="row">
          <div className="col-lg-12">
            <div className={`tab-content mt-5`} id="myTabTables12">
              <div className={`tab-pane fade ${key === "statistics" ? "show active" : ""}`}>
               İstatistikler
              </div>
              <div className={`tab-pane fade ${key === "logs" ? "show active" : ""}`}>
                {user && <UserLog logs={user.logs}/>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserGeneralInfo;
