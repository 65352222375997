import React, { useEffect, useState } from "react";
import { ModalProgressBar } from "../../../components/common/controls";
import { headerSortingClasses, NoRecordsFoundMessage, post, sortCaret } from "../../../helpers";
import BootstrapTable from "react-bootstrap-table-next";
import {
  LogoColumnFormatter,
  NameColumnFormatter,
  StockStatusColumnFormatter
} from "../../ProductSkus/product-sku-table/column-formatters";

const UserFavorite = ({ id }) =>{
  const [loading, setloading] = useState(false);
  const [data, setData] = useState([]);

  const getData = async () => {
    setloading(true);
    const credentials = {
      filter: { user_id: id},
      pageNumber: 1,
      pageSize: 10,
      sortField: "id",
      sortOrder: "asc",
    };
    const { favorites } = await post('/favorite/admin/list', credentials);
    setData(favorites);
    setloading(false);
  };

  const columns = [
    {
      dataField: "id",
      text: "ID",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      style: {
        width: "60px",
      },
    },
    {
      dataField: "image",
      text: "",
      sort: false,
      sortCaret: sortCaret,
      formatter: LogoColumnFormatter,
      headerSortingClasses,
      style: {
        width: "100px",
      },
    },
    {
      dataField: "name",
      text: "Ürün Adı",
      sort: true,
      sortCaret: sortCaret,
      formatter: NameColumnFormatter,
      headerSortingClasses,
    },
    {
      dataField: "type.name",
      text: "Tür",
      sort: false,
      sortCaret: sortCaret,
    },
    {
      dataField: "brand.name",
      text: "Marka",
      sort: false,
      sortCaret: sortCaret,
    },
    {
      dataField: "stock.in_stock",
      text: "Stok",
      sort: false,
      sortCaret: sortCaret,
      formatter: StockStatusColumnFormatter,
      headerSortingClasses,
      classes: "text-center pr-0",
      headerClasses: "text-center pr-0",
      style: {
        width: "80px",
      },
    },
  ];


  useEffect(() => {
    getData();
  },[]);

  return (
    <div className="card card-custom">
      {loading && <ModalProgressBar />}
      <div className="card-header py-3">
        <div className="card-title align-items-start flex-column">
          <h3 className="card-label font-weight-bolder text-dark">
                        Favoriler
          </h3>
          <span className="text-muted font-weight-bold font-size-sm mt-1">
            Kullanıcının favorilerini görüntüleyin
          </span>
        </div>
      </div>
      <div className="card-body">
        <BootstrapTable
          wrapperClasses="table-responsive"
          classes="table table-head-custom table-head-bg table-vertical-center overflow-hidden"
          bootstrap4
          bordered={false}
          remote
          keyField="id"
          data={data ?? []}
          columns={columns}
          noDataIndication={ () => <NoRecordsFoundMessage /> } />
      </div>
    </div>
  );
};

export default UserFavorite;
