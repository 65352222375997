import React from "react";

const CompanyOverView = () =>{
  return (
    <div className="card card-custom">
      <div className="card-header py-3">
        <div className="card-title align-items-start flex-column">
          <h3 className="card-label font-weight-bolder text-dark">
            Önizleme
          </h3>
          <span className="text-muted font-weight-bold font-size-sm mt-1">
            Firma özet bilgilerini görüntüleyin
          </span>
        </div>
      </div>
      <div className="card-body" style={{ padding: '1rem 2.25rem' }}>

      </div>
    </div>
  );
};

export default CompanyOverView;
