import React, { useEffect, useState } from "react";
import { sortCaret, headerSortingClasses, get, post,} from '../../../../helpers';
import * as uiHelpers from "../../UiHelpers";
import { toast } from "react-toastify";
import { DataTable } from "../../../../components";

const CompanyPriceTable = ({ company_id }) => {
  const [queryParams, setQueryParams] = useState({ filter: {
    name: "",
  },
  sortOrder: "asc", // asc||desc
  sortField: "id",
  pageNumber: 1,
  pageSize: 10 });
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [total_count, setTotalCount] = useState([]);
  const handleTableChange = (type, { page, sizePerPage }) => {
    const pageNumber = page || 1;
    setQueryParams({ ...queryParams, pageNumber: pageNumber, pageSize: sizePerPage });
  };
  useEffect(() => {
    const getPrices = async () => {
      setLoading(true);
      const { company_fees, total_count, status } = await post('/company/admin/fee/'+company_id, { ...queryParams });
      setLoading(false);
      if(!status){
        return;
      }
      setTotalCount(total_count);
      setData(company_fees);

    };
    getPrices();
  }, [queryParams]);

  const updatePrices = async () => {
    const { status, message } = await get('/company/admin/fee/update/'+company_id);
    if(!status){
      toast.info(message);
      return;
    }
    toast.success('Güncelleme isteği alındı!');
  };

  const columns = [
    {
      dataField: "category_name",
      text: "Kategori",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "wholesale",
      text: "Toptan",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "wholesale_discounted",
      text: "İndirimli",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "retail",
      text: "Perakende",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
  ];
  const paginationOptions = {
    custom: true,
    totalSize: total_count,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: queryParams.pageSize,
    page: queryParams.pageNumber,
  };
  return (
    <div className="card card-custom">
      <div className="card-header py-3">
        <div className="card-title align-items-start flex-column">
          <h3 className="card-label font-weight-bolder text-dark">
              Fiyatlar
          </h3>
          <span className="text-muted font-weight-bold font-size-sm mt-1">
            Fiyatları görüntüleyin
          </span>
        </div>
      </div>
      <div className="card-body" style={{ padding: '1rem 2.25rem' }}>
        <button type="button" className="btn btn-info mb-5" onClick={updatePrices}>
          Güncelle
        </button>
        <DataTable
          data={data}
          defaultSorted={uiHelpers.defaultSorted}
          columns={columns}
          paginationOptions={paginationOptions}
          handleTableChange={handleTableChange}
          listLoading={loading} />
      </div>
    </div>
  );
};
export default CompanyPriceTable;
