export const overrideStrings = {
  "allItemsAreSelected": "Hepsi seçildi.",
  "clearSearch": "Aramayı Temizle",
  "noOptions": "Boş",
  "search": "Ara",
  "selectAll": "Hepsini Seç",
  "selectAllFiltered": "Hepsi Seçildi",
  "selectSomeItems": "Seç..."
};

export const stockTypes = [
  {
    id:1,
    name: 'Adet'
  },
  {
    id:2,
    name: 'Metre Kare'
  }
];

export const orderTypes = [
  {
    value: 0,
    label: 'Bekliyor'
  },
  {
    value: 1,
    label: 'Ödeme Bekliyor'
  },
  {
    value: 2,
    label: 'Sipariş Alındı'
  },
  {
    value: 3,
    label: 'Hazırlanıyor'
  },
  {
    value: 4,
    label: 'Hazırlanmış'
  },
  {
    value: 5,
    label: 'Sevk Bekliyor'
  },
  {
    value: 6,
    label: 'Kargoya Verildi'
  },
  {
    value: 7,
    label: 'Dağıtım Aracında'
  },
  {
    value: 8,
    label: 'Sevk Edildi'
  },
  {
    value: 9,
    label: 'Teslim Edilmiş'
  },
  {
    value: 10,
    label: 'Tamamlandı'
  },
  {
    value: 11,
    label: 'İptal Edildi'
  }
];

export const MESSAGES = {
  uploadStart: "Yükleme başladı.",
  uploadEnd: "Yükleme tamamlandı.",
  loading: "Kaydediliyor..",
  success: "Başarılı.",
}


