import React from "react";
import { ProductAttributeValueUIProvider } from './UIContext';
import { Route, useHistory } from 'react-router-dom';
import ProductAttributeValueCard from './ProductAttributeValueCard';
import { ProductAttributeValueAdd, ProductAttributeValueDeleteDialog } from './dialog';

const ProductAttributeValuePage = () => {
  const { push } = useHistory();
  const _onHide = () => push("/product_management/attribute_values");
  const _handleAddModal = ({ match }) => <ProductAttributeValueAdd show={match != null} onHide={_onHide}/>;
  const _handleEditModal = ({ match }) => <ProductAttributeValueAdd show={match != null} id={match && match.params.id} onHide={_onHide}/>;
  const _handleDeleteModal = ({ match }) => <ProductAttributeValueDeleteDialog show={match != null} id={match && match.params.id} onHide={_onHide} />;
  return (
    <ProductAttributeValueUIProvider>
      <Route path="/product_management/attribute_values/new" render={_handleAddModal} />
      <Route path="/product_management/attribute_values/:id/edit" render={_handleEditModal} />
      <Route path="/product_management/attribute_values/:id/delete" render={_handleDeleteModal}/>
      <ProductAttributeValueCard />
    </ProductAttributeValueUIProvider>
  );
};
export default ProductAttributeValuePage;
