import React, { useRef, useEffect, useState } from "react";
import { toast } from "react-toastify";
import PropTypes from 'prop-types';
import { SplashScreen } from '../../components';
import { useStores } from '../../store';
import { get } from '../../helpers';

const AuthInit = ({ children }) => {
  const { setAuthState } = useStores();
  const didRequest = useRef(false);
  const [showSplashScreen, setShowSplashScreen] = useState(true);
  
  useEffect(() => {
    const requestUser = async () => {
      try {
        if (!didRequest.current) {
          const { user, tokens, status, message } = await get('/auth/admin/me');
          if(!status){
            toast.error(message);
          }
          setAuthState(user,tokens);
        }
      } catch (error) {
        if (!didRequest.current) {
          setAuthState();
        }
      } finally {
        setShowSplashScreen(false);
      }

      return () => didRequest.current = true;
    };
    requestUser();

    setShowSplashScreen(false);
  }, []);

  return showSplashScreen ? <SplashScreen /> : <>{children}</>;
};
AuthInit.propTypes = {
  children: PropTypes.node
};
export default AuthInit;
