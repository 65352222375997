import React from "react";
import { Switch, useHistory } from 'react-router-dom';
import { UserUIProvider } from './UIContext';
import { ContentRoute } from '../../components';
import { UserCard, UserAdd, UserInformation } from './index';
import { UserDeleteDialog } from "./dialog";


const UserPage = () => {
  const { push } = useHistory();
  const _onHide = () => push("/user_management/users");
  const UserEdit = ({ match }) => <UserAdd id={match && match.params.id}/>;
  const UserDelete = ({ match }) => <UserDeleteDialog show={match != null} id={match && match.params.id} onHide={_onHide} />;
  const UserProfile = ({ match }) => <UserInformation user_id={match && match.params.id} />;
  return (
    <UserUIProvider>
      <Switch>
        <ContentRoute path="/user_management/users/new" component={UserAdd} />
        <ContentRoute path="/user_management/users/:id/edit" component={UserEdit} />
        <ContentRoute path="/user_management/users/:id/delete" component={UserDelete} />
        <ContentRoute path="/user_management/users/:id" component={UserProfile} />
        <ContentRoute component={UserCard} />
      </Switch>
    </UserUIProvider>
  );
};
export default UserPage;
