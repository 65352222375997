import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { useProductAttributeValueUIContext } from '../UIContext';
import { delete_, post } from '../../../helpers';
import DialogModalFooter from "../../../components/DialogModalFooter";


const ProductAttributeValueDeleteDialog = ({ id, show, onHide }) => {
  const productAttributeValueUIContext = useProductAttributeValueUIContext();
  const productAttributeValueUIProps = useMemo(() => {
    return {
      setError: productAttributeValueUIContext.setError,
      setState: productAttributeValueUIContext.setState
    };
  }, [productAttributeValueUIContext]);

  const { queryParams } = productAttributeValueUIContext;

  useEffect(() => {
    if (!id) {
      onHide();
    }
  }, [id]);


  const deleteProductAttributeValue = async () => {
    const { status, message } = await delete_(`product_attribute/admin/value/delete/${id}`);
    if(!status){
      productAttributeValueUIProps.setError(message);
      onHide();
      return;
    }
    const { product_attribute_values:productAttributeValues,status:_status, message:_message, total_count } = await post('/product_attribute/admin/value/list', { ...queryParams });
    if(!_status){
      productAttributeValueUIProps.setError(_message);
      return;
    }
    await productAttributeValueUIProps.setState({ productAttributeValues, total_count });
    onHide();
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
    >
      <Modal.Header closeButton>
        <Modal.Title>
            Durum Güncelle
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span>Seçtiğiniz özellik değerinin durumu değiştirilecek. Devam etmek istiyor musunuz?</span>
      </Modal.Body>
      <DialogModalFooter type={'delete'} handleSubmit={deleteProductAttributeValue} onHide={onHide}/>
    </Modal>
  );
};
export default ProductAttributeValueDeleteDialog;
