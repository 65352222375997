import React from "react";

const ServerSendStatusColumnFormatter = (cellContent, row, rowIndex, { reSendOrder }) => {

  const _reSendOrder = () => reSendOrder(row.id);
  if (row.outref || row.is_sent || row.source === 5){
    return null;
  }
  return (
    <React.Fragment>
      <a title="Yeniden Gönder" onClick={_reSendOrder} className="btn btn-icon btn-clean btn-xs btn-hover-primary">
        <i className="flaticon2-checkmark" />
      </a>
    </React.Fragment>
  );
};
export default ServerSendStatusColumnFormatter;
