import { onSnapshot } from 'mobx-state-tree';
import { RootStoreModel } from './RootStore';
import * as utils from "../helpers/LocalStorageHelpers";

const ROOT_STATE_STORAGE_KEY = 'root';
export async function setupRootStore() {
  let rootStore;
  try {
    const storeData = await utils.getStorage(ROOT_STATE_STORAGE_KEY);
    const data = JSON.parse(storeData);
    rootStore = RootStoreModel.create(data);
  } catch (e) {
    rootStore = RootStoreModel.create();
  }
  onSnapshot(rootStore, snapshot => {
    utils.setStorage(ROOT_STATE_STORAGE_KEY, JSON.stringify(snapshot));
  }
  );
  return rootStore;
}
