import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { useCampaignUIContext } from '../CampaignUIContext';
import { delete_, post } from '../../../helpers';
import DialogModalFooter from "../../../components/DialogModalFooter";

const CampaignDeleteDialog = ({ id, show, onHide }) => {
  const campaignUIContext = useCampaignUIContext();
  const campaignUIProps = useMemo(() => {
    return {
      setError: campaignUIContext.setError,
      setState: campaignUIContext.setState
    };
  }, [campaignUIContext]);

  const { queryParams } = campaignUIContext;

  useEffect(() => {
    if (!id) {
      onHide();
    }
  }, [id]);


  const deleteCampaign = async () => {
    const { status, message } = await delete_(`campaign/admin/delete/${id}`);
    if(!status){
      campaignUIProps.setError(message);
      onHide();
      return;
    }
    const { campaigns, status:_status, message:_message, total_count } = await post('/campaign/list', { ...queryParams });
    if(!_status){
      campaignUIProps.setError(_message);
      return;
    }
    await campaignUIProps.setState({ campaigns, total_count });
    onHide();
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
    >
      <Modal.Header closeButton>
        <Modal.Title>
            Durum Güncelle
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span>Seçtiğiniz kampanyanın durumu değiştirilecek. Devam etmek istiyor musunuz?</span>
      </Modal.Body>
      <DialogModalFooter type={'delete'} handleSubmit={deleteCampaign} onHide={onHide}/>
    </Modal>
  );
};
export default CampaignDeleteDialog;
