import React, { useEffect, useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { ProductAttributeForm } from './index';
import { useProductAttributeUIContext } from '../UIContext';
import { get, post, put } from '../../../helpers';

const ProductAttributeAdd = ({ id, show, onHide }) => {
  const [title, setTitle] = useState('');
  const [productAttributeForEdit, setProductAttributeForEdit] = useState(undefined);
  const productAttributeUIContext = useProductAttributeUIContext();
  const { queryParams, initProductAttribute } = productAttributeUIContext;

  const productAttributeUIProps = useMemo(() => {
    return {
      setError: productAttributeUIContext.setError,
      setState: productAttributeUIContext.setState,
    };
  }, [productAttributeUIContext]);

  const getProductAttribute = async () => {
    if(!id){
      return;
    }
    const { product_attribute } = await get('/product_attribute/admin/'+id);
    setProductAttributeForEdit(product_attribute);
  };

  useEffect(() => {
    getProductAttribute();
  }, [id]);

  useEffect(() => {
    let _title = id ? "" : "Yeni Özellik";
    if (productAttributeForEdit && id) {
      _title = `${productAttributeForEdit.name} Düzenle`;
    }
    setTitle(_title);
  }, [productAttributeForEdit]);

  const saveProductAttribute = async (productAttribute, closeWindow) => {
    if (!id) {
      const { status, message } = await post('product_attribute/admin/add', productAttribute);
      if(!status){
        productAttributeUIProps.setError(message);
        if (!closeWindow) onHide();
        return;
      }
    } else {
      const { status, message } = await put('/product_attribute/admin/'+id, productAttribute);
      if(!status){
        productAttributeUIProps.setError(message);
        if (!closeWindow) onHide();
        return;
      }
    }
    const { product_attributes:productAttributes, status, message, total_count } = await post('/product_attribute/admin/list', { ...queryParams });
    if(!status){
      productAttributeUIProps.setError(message);
      return;
    }
    productAttributeUIProps.setError(null);
    await productAttributeUIProps.setState({ productAttributes, total_count });
    if (!closeWindow) onHide();
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <ProductAttributeForm
        saveProductAttribute={saveProductAttribute}
        productAttribute={productAttributeForEdit || initProductAttribute}
        onHide={onHide}
      />
    </Modal>
  );
};
export default ProductAttributeAdd;
