import React, { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { RootStoreProvider, setupRootStore } from './store';
import Routes from './Routes';
import { SplashScreen } from './components';
import { AuthInit } from './pages';
import { ToastContainer } from "react-toastify";

const App = () => {
  const [rootStore, setRootStore] = useState(undefined);
  const handleStore = async () => {
    const store = await setupRootStore();
    await setRootStore(store);
  };
  useEffect(() => {
    handleStore();
  }, []);
  if (!rootStore) {
    return null;
  }
  return (
    <RootStoreProvider value={rootStore}>
      <React.Suspense fallback={<SplashScreen />}>
        <BrowserRouter>
          <AuthInit>
            <Routes />
            <ToastContainer />
          </AuthInit>
        </BrowserRouter>
      </React.Suspense>
    </RootStoreProvider>
  );
};

export default App;
