import React from 'react';
import { Card, CardBody, FormAlert } from '../../components/common/controls';
import { useCampaignUIContext } from './CampaignUIContext';
import { CampaignTable, CampaignFilter } from './campaign-table';

const CampaignCard = () => {
  const { error } = useCampaignUIContext();

  return (
    <React.Fragment>
      <FormAlert visible={error !== null} message={error} />
      <Card>
        <CardBody>
          <CampaignFilter />
          <CampaignTable />
        </CardBody>
      </Card>
    </React.Fragment>
  );
};
export default CampaignCard;
