import React, { useState, useEffect, useRef } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar, FormAlert,
} from '../../components/common/controls';
import { useHistory } from 'react-router-dom';
import { ProductForm } from './ProductForm';
import { post, get, put } from '../../helpers';
import { useProductUIContext } from './UIContext';


const ProductAdd = ({ id }) => {
  const history = useHistory();
  const { initProduct } = useProductUIContext();
  const [error, setError] = useState(null);
  const [productForEdit, setProductForEdit] = useState(undefined);

  const btnRef = useRef();
  const saveProductClick = () => {
    if (btnRef && btnRef.current) {
      btnRef.current.click();
    }
  };

  const backToProductsList = () => {
    history.push(`/product_management/products`);
  };
  const saveProduct = async (product) => {
    if (!id) {
      const { status, message } = await post('product/add', product);
      if (!status) {
        setError(message);
        return;
      }
    }
    else{
      const { status, message } = await put('product/'+id, product);
      if (!status) {
        setError(message);
        return;
      }
    }
    backToProductsList();
  };
  const getProduct = async () => {
    if(!id){
      return;
    }
    const { product } = await get('product/'+ id);
    setProductForEdit(product);

  };
  useEffect(() => {
  }, [productForEdit]);
  useEffect(() => {
    getProduct();
  },[id]);

  return (
    <React.Fragment>
      <FormAlert visible={error !== null} message={error} />
      <Card>
        <CardHeader title={'Genel Bilgi'}>
          <CardHeaderToolbar>
            <button
              type="button"
              onClick={backToProductsList}
              className="btn btn-light"
            >
              <i className="fa fa-arrow-left" />
              Geri
            </button>
            <button
              type="submit"
              className="btn btn-primary ml-2"
              onClick={saveProductClick}
            >
              Kaydet
            </button>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <ProductForm setError={setError} product={productForEdit || initProduct} btnRef={btnRef} saveProduct={saveProduct} />
        </CardBody>
      </Card>
    </React.Fragment>
  );
};
export default ProductAdd;
