import React from 'react';
import { Card, CardBody, FormAlert } from '../../components/common/controls';
import { useProductAttributeUIContext } from './UIContext';
import ProductAttributeTable from './product-attribute-table/ProductAttributeTable';
import { ProductAttributeFilter } from "./product-attribute-table";

const ProductAttributeCard = () => {
  const { error } = useProductAttributeUIContext();

  return (
    <>
      <FormAlert visible={error !== null} message={error} />
      <Card>
        <CardBody>
          <ProductAttributeFilter />
          <ProductAttributeTable />
        </CardBody>
      </Card>
    </>
  );
};
export default ProductAttributeCard;
