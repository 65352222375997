import React, { createContext, useContext, useState, useCallback } from "react";
import { isEqual, isFunction } from "lodash";
import { initialFilter } from './UiHelpers';

const UIContext = createContext();

export function useUserUIContext() {
  return useContext(UIContext);
}

export function UserUIProvider({ children }) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [state, setState] = useState({ users:[], total_count:0 });
  const [error, setError] = useState(null);
  const [userForEdit, setUserForEdit] = useState(undefined);
  const [listLoading, setListLoading] = useState(false);
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);
  const initUser = {
    id: undefined,
    first_name: "",
    last_name: "",
    email: "",
    gsm: "",
    roles: []
  };
  const value = {
    queryParams,
    setQueryParamsBase,
    setQueryParams,
    initUser,
    userForEdit,
    setUserForEdit,
    error,
    setError,
    state,
    setState,
    listLoading,
    setListLoading,
  };

  return (
    <UIContext.Provider value={value}>
      {children}
    </UIContext.Provider>
  );
}
