import React from 'react';
import { Card, CardBody, FormAlert } from '../../components/common/controls';
import { useCategoryUIContext } from './UIContext';
import { CategoryTable, CategoryFilter } from './category-table';

const CategoryCard = () => {
  const { error } = useCategoryUIContext();

  return (
    <React.Fragment>
      <FormAlert visible={error !== null} message={error} />
      <Card>
        <CardBody>
          <CategoryFilter />
          <CategoryTable />
        </CardBody>
      </Card>
    </React.Fragment>
  );
};
export default CategoryCard;
