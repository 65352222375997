import React from "react";
import { ProductUIProvider } from './UIContext';
import { useHistory, Switch, Route } from 'react-router-dom';
import ProductCard from './ProductCard';
import { ProductDeleteDialog } from './dialog';
import { ContentRoute } from '../../components';
import { ProductAdd, ProductInformation } from './index';

const ProductPage = () => {
  const { push } = useHistory();
  const _onHide = () => push("/product_management/products");
  const _handleEdit = ({ match }) => <ProductAdd id={match && match.params.id}/>;
  const _handleDeleteModal = ({ match }) => <ProductDeleteDialog show={match != null} id={match && match.params.id} onHide={_onHide} />;
  const _productInfo = ({ match }) => <ProductInformation id={match && match.params.id}/>;
  return (
    <ProductUIProvider>
      <Route path="/product_management/products/:id/delete" render={_handleDeleteModal}/>
      <Switch>
        <ContentRoute path="/product_management/products/new" component={ProductAdd} />
        <ContentRoute path="/product_management/products/:id/edit" component={_handleEdit} />
        <ContentRoute path="/product_management/products/:id/information" component={_productInfo} />
        <ContentRoute component={ProductCard} />
      </Switch>
    </ProductUIProvider>
  );
};
export default ProductPage;
