import React, { useEffect, useMemo, useState } from 'react';
import { Formik } from "formik";
import { isEqual } from "lodash";
import { useUserUIContext } from '../UIContext';
import Select from 'react-select';
import { post } from '../../../helpers';
import { useHistory } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";

const prepareFilter = (queryParams, values) => {
  const { status, parent_id, searchText, source } = values;
  const newQueryParams = { ...queryParams };
  const filter = {};
  if (status) filter.status = status;
  if (parent_id) filter.parent_id = [parent_id];
  if (source) filter.source = source;
  if (searchText) {
    filter.full_name = searchText;
  }
  newQueryParams.filter = filter;
  return newQueryParams;
};

const UserFilter = () => {
  const { push } = useHistory();
  const [roles, setRoles] = useState([]);
  const userUiContext = useUserUIContext();
  const userUIProps = useMemo(() => {
    return {
      queryParams: userUiContext.queryParams,
      setQueryParams: userUiContext.setQueryParams,
    };
  }, [userUiContext]);

  const applyFilter = (values) => {
    const newQueryParams = prepareFilter(userUIProps.queryParams, values);
    if (!isEqual(newQueryParams, userUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      userUIProps.setQueryParams(newQueryParams);
    }
  };
  const initialValues = {
    status: "1",
    searchText: "",
    parent_id: "",
    source: "",
  };
  const statusOptions = [
    { value: '', label: 'Tümü' },
    { value: '1', label: 'Aktif' },
    { value: '0', label: 'Pasif' }
  ];
  const sourceOptions = [
    { value: '', label: 'Tümü' },
    { value: '1', label: 'Web' },
    { value: '2', label: 'Mobile' },
    { value: '3', label: 'Cms' },
    { value: '4', label: 'Cli' }
  ];
  const getRoles = async () => {
    const { roles } = await post('/role/admin/list', {});
    const roles_ = roles.map(item => {
      return { value: item.id, label: item.name };
    });
    roles_.unshift({ value: '', label: 'Tümü' });
    setRoles(roles_);
  };

  const _onSubmit = (values) => applyFilter(values);
  const navigateToAddUserView = () => {
    push("/user_management/users/new");
  };

  useEffect(() => {
    getRoles();
  }, []);
  return (
    <Formik initialValues={initialValues} onSubmit={_onSubmit}>
      {({
        values,
        handleSubmit,
        handleBlur,
        setFieldValue,
      }) =>
      {
        const _handleSelectChange = (item)=>{
          setFieldValue("status", item.value);
        };
        const _handleSourceSelectChange = (item)=>{
          setFieldValue("source", item.value);
        };
        const _handleRoleSelectChange = (item)=>{
          setFieldValue("parent_id", item.value);
        };
        return (
          <form onSubmit={handleSubmit} className="form form-label-right">
            <div className="form-group row">
              <div className="col-lg-2">
                <input
                  type="text"
                  className="form-control"
                  name="searchText"
                  placeholder="Kullanıcı"
                  onBlur={handleBlur}
                  value={values.searchText}
                  onChange={(e) => {
                    setFieldValue("searchText", e.target.value);
                    handleSubmit();
                  }}
                />
              </div>

              <div className="col-lg-10">
                <Dropdown onClick={(e) => e.stopPropagation(e)} drop="down" className="float-right ml-2">
                  <Dropdown.Toggle>
                    Filtreleme Seçenekleri
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
                    <div className="navi navi-spacer-x-0 p-5">
                      <div className="form-group row">
                        <div className="col-lg-12 mt-2">
                          <Select onChange={_handleSelectChange}
                            options={statusOptions}
                            placeholder={'Durum'}
                            value={statusOptions.filter(item => item.value === values.status)}/>
                          <small className="form-text text-muted">
                            Duruma <b>Göre</b>
                          </small>
                        </div>
                        <div className="col-lg-12 mt-2">
                          <Select
                            onChange={_handleRoleSelectChange}
                            options={roles}
                            placeholder={'Rol'}
                            value={roles.filter(item => item.value === values.parent_id)}/>
                          <small className="form-text text-muted">
                            Role <b>Göre</b>
                          </small>
                        </div>
                        <div className="col-lg-12 mt-2">
                          <Select
                            onChange={_handleSourceSelectChange}
                            options={sourceOptions}
                            placeholder={'Kaynak'}
                            value={sourceOptions.filter(item => item.value === values.source)}/>
                          <small className="form-text text-muted">
                            Kaynağa <b>Göre</b>
                          </small>
                        </div>
                        <div className="col-lg-12 mt-2">
                          <button type="button" className="btn btn-outline-primary btn-block" onClick={handleSubmit}>
                            Uygula
                          </button>
                        </div>
                      </div>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
                <button type="button" className="btn btn-info ml-2 float-right" onClick={navigateToAddUserView}>
                  Yeni Kullanıcı
                </button>
              </div>
            </div>
          </form>
        );
      }
      }
    </Formik>
  );
};
export default UserFilter;
