import React from 'react';

const RatingIcons = ({
  fullStarColor = '#fac917',
  maxStars = 5,
  rating = 1,
  starSize = 12,
}) => {
  const handleStars = () => {
    const stars = [];
    for (let i = 0; i < maxStars; i++) {
      stars.push(
        <i className={rating < i + 1 ? 'fa fa-star-half' : 'fa fa-star'}
          key={i.toString()}
          style={{ fontSize: starSize, color: fullStarColor }}
        />,
      );
    }
    return stars;
  };
  return (
    <div>
      {handleStars()}
      {rating &&
                <span className={'font-weight-light font-size-sm'} style={{ marginLeft:"5px" }}>{rating}</span>
      }
    </div>
  );
};
export default RatingIcons;
