import React, { useEffect, useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { AddressForm } from './index';
import { useAddressUIContext } from '../UIContext';
import { get, post, put } from '../../../helpers';
import { stockTypes } from "../../../constant/helper";


const AddressAdd = ({ id, show, onHide }) => {
  const [title, setTitle] = useState('');
  const [addressForEdit, setAddressForEdit] = useState(undefined);
  const addressUIContext = useAddressUIContext();
  const { queryParams, initAddress } = addressUIContext;

  const addressUIProps = useMemo(() => {
    return {
      setError: addressUIContext.setError,
      setState: addressUIContext.setState,
    };
  }, [addressUIContext]);

  const getAddress = async () => {
    if(id){
      const { address } = await get('address/'+id);
      setAddressForEdit(address);
    }
  };

  useEffect(() => {
    getAddress();
  }, [id]);

  useEffect(() => {
    let _title = id ? "" : "Yeni Adres Ekle";
    if (addressForEdit && id) {
      _title = `${addressForEdit.name} Düzenle`;
    }
    setTitle(_title);
  }, [addressForEdit]);


  const saveAddress = async (address) => {

    if (!id) {
      const { status, message } = await post('address/add', address);
      if(!status){
        addressUIProps.setError(message);
      }
    } else {
      const { status, message } = await put('address/'+id, address);
      if(!status){
        addressUIProps.setError(message);
      }
    }
    const { addresses, status:status_, message:message_, total_count } = await post('/address/list', { ...queryParams });
    if(!status_){
      addressUIProps.setError(message_);
      return;
    }
    addressUIProps.setError(null);
    addressUIProps.setState({ addresses, total_count });
    onHide();
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <AddressForm
        saveAddress={saveAddress}
        address={addressForEdit || initAddress}
        onHide={onHide}
      />
    </Modal>
  );
};
export default AddressAdd;
