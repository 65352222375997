import React from "react";
import { OrderItemStatusTitles, OrderItemStatusClasses } from '../../UiHelpers';
const ItemStatusColumnFormatter = (cellContent, row) => {
  const { status } = row;
  console.log(row);
  const getLabelCssClasses = (key) => {
    return `label label-light-${
      OrderItemStatusClasses[key]
    } label-inline ml-2`;
  };
  return <div>
    <span className={getLabelCssClasses(status)}>{OrderItemStatusTitles[status]}</span>
  </div>
   
  ;
};
export default ItemStatusColumnFormatter;
