import React, { useEffect, useMemo, useState } from 'react';
import { Formik } from "formik";
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { useHistory } from "react-router-dom";
import { isEqual } from "lodash";
import { useProductSkuUIContext } from '../UIContext';
import { post } from '../../../helpers';
import Dropdown from "react-bootstrap/Dropdown";

const prepareFilter = (queryParams, values) => {
  const { status, on_sale, searchText, category_id, sub_category_id, brand_id, type_id } = values;
  const newQueryParams = { ...queryParams };
  const filter = {};
  if (status) filter.status = status;
  if (on_sale) filter.on_sale = on_sale;
  if (category_id > 0) filter.category_id = category_id;
  if (brand_id > 0) filter.brand_id = brand_id;
  if (type_id) filter.type_id = type_id;
  if (sub_category_id) filter.sub_category_id = sub_category_id;
  if (searchText) {
    newQueryParams.keyword = searchText;
  }
  newQueryParams.filter = filter;
  return newQueryParams;
};

const ProductSkuFilter = () => {
  const { push } = useHistory();
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const [productTypes, setProductTypes] = useState([]);
  const [subCategorySearch, setSubCategorySearch] = useState('');
  const productUiContext = useProductSkuUIContext();
  const productUIProps = useMemo(() => {
    return {
      queryParams: productUiContext.queryParams,
      setQueryParams: productUiContext.setQueryParams,
    };
  }, [productUiContext]);

  const applyFilter = (values) => {
    const newQueryParams = prepareFilter(productUIProps.queryParams, values);
    if (!isEqual(newQueryParams, productUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      productUIProps.setQueryParams(newQueryParams);
    }
  };
  const getCategories = async () => {
    const { categories } = await post('/category/list', {});
    let categories_ = categories.map(item => {
      return { value: item.id, label: item.name };
    });
    categories_ = [{ value: 0, label: 'Tümü' }, ...categories_];
    setCategories(categories_);
  };
  const getSubCategories = async (input) => {
    const queryParams = productUIProps.queryParams;
    queryParams.filter['name'] = input ?? subCategorySearch;
    const { sub_categories } = await post('/category/sub/list', queryParams);
    const sub_categories_ = sub_categories.map(item => {
      return { value: item.id, label: item.name };
    });
    setSubCategories(sub_categories_);
    return sub_categories_;
  };

  const getBrands = async () => {
    const { brands } = await post('/brand/list', {});
    let brands_ = brands.map(item => {
      return { value: item.id, label: item.name };
    });
    brands_ = [{ value: 0, label: 'Tümü' }, ...brands_];
    setBrands(brands_);
  };

  const getProductTypes = async () => {
    const { product_types } = await post('/product_type/admin/list', {});
    const productTypes_ = product_types.map(item => {
      return { value: item.id, label: item.name };
    });
    setProductTypes(productTypes_);
  };

  useEffect(() => {
    getCategories();
    getSubCategories();
    getBrands();
    getProductTypes();
  }, []);

  const initialValues = {
    status: "1",
    searchText: "",
  };
  const statusOptions = [
    { value: '', label: 'Tümü' },
    { value: '1', label: 'Aktif' },
    { value: '0', label: 'Pasif' }
  ];
  const saleOptions = [
    { value: '', label: 'Tümü' },
    { value: '1', label: 'Satışa Açık' },
    { value: '0', label: 'Satışa Kapalı' }
  ];
  const _handleSubmit = (values) => applyFilter(values);
  const openProductSkuAddModal = () => {
    push("/product_management/stocks/new");
  };

  const loadOptions = async (inputValue, callback) => {
    const _data = await getSubCategories(inputValue);
    callback(_data);
  };

  return (
    <Formik initialValues={initialValues} onSubmit={_handleSubmit}>
      {({ values, handleSubmit, handleBlur, handleChange, setFieldValue }) =>
      {
        const _handleSelectChange = (item)=>{
          setFieldValue("status", item.value);
        };
        const _handleSaleSelectChange = (item)=>{
          setFieldValue("on_sale", item.value);
        };
        const _handleBrandSelectChange = (item)=>{
          setFieldValue("brand_id", item.value);
        };
        const _handleCategorySelectChange = (item)=>{
          setFieldValue("category_id", item.value);
        };
        const _handleSubCategorySelectChange = (item)=>{
          setFieldValue("sub_category_id", item.value);
        };
        const _handleProductTypeSelectChange = (item)=>{
          setFieldValue("type_id", item.value);
        };
        return(
          <form onSubmit={handleSubmit} className="form form-label-right">
            <div className="form-group row">
              <div className="col-lg-2">
                <input
                  type="text"
                  className="form-control"
                  name="searchText"
                  placeholder="Ürünler"
                  onBlur={handleBlur}
                  value={values.searchText}
                  onChange={(e) => {
                    setFieldValue("searchText", e.target.value);
                    handleSubmit();
                  }}
                />
              </div>
              <div className="col-lg-10">
                <Dropdown onClick={(e) => e.stopPropagation(e)} drop="down" className="float-right ml-2">
                  <Dropdown.Toggle>
                    Filtreleme Seçenekleri
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
                    <div className="navi navi-spacer-x-0 p-5">
                      <div className="form-group row">
                        <div className="col-lg-12 mt-2">
                          <Select
                            onChange={_handleBrandSelectChange}
                            options={brands}
                            placeholder={'Marka'}
                          />
                          <small className="form-text text-muted">
                            Markaya <b>Göre</b>
                          </small>
                        </div>
                        <div className="col-lg-12 mt-2">
                          <Select
                            onChange={_handleCategorySelectChange}
                            options={categories}
                            placeholder={'Kategori'}
                          />
                          <small className="form-text text-muted">
                            Kategoriye <b>Göre</b>
                          </small>
                        </div>
                        <div className="col-lg-12 mt-2">
                          <AsyncSelect
                            cacheOptions
                            loadOptions={loadOptions}
                            defaultOptions
                            onInputChange={setSubCategorySearch}
                            onChange={_handleSubCategorySelectChange}
                            options={subCategories}
                            placeholder={'Alt Kategori'}
                          />
                          <small className="form-text text-muted">
                            Alt Kategoriye <b>Göre</b>
                          </small>
                        </div>
                        <div className="col-lg-12 mt-2">
                          <Select
                            onChange={_handleSelectChange}
                            options={statusOptions}
                            placeholder={'Durum'}
                            value={statusOptions.filter(item => item.value === values.status)}
                          />
                          <small className="form-text text-muted">
                            Duruma <b>Göre</b>
                          </small>
                        </div>
                        <div className="col-lg-12 mt-2">
                          <Select
                            onChange={_handleSaleSelectChange}
                            options={saleOptions}
                            placeholder={'Satış Durumu'}
                            value={saleOptions.filter(item => item.value === values.on_sale)}
                          />
                          <small className="form-text text-muted">
                            Satış Durumuna <b>Göre</b>
                          </small>
                        </div>
                        <div className="col-lg-12 mt-2">
                          <Select
                            onChange={_handleProductTypeSelectChange}
                            options={productTypes}
                            placeholder={'Tür'}
                          />
                          <small className="form-text text-muted">
                            Türe <b>Göre</b>
                          </small>
                        </div>
                        <div className="col-lg-12 mt-2">
                          <button type="button" className="btn btn-outline-primary btn-block" onClick={handleSubmit}>
                            Uygula
                          </button>
                        </div>
                      </div>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
                <button type="button" className="btn btn-info ml-2 float-right" onClick={openProductSkuAddModal}>
                  Yeni Stok Kartı
                </button>
              </div>
            </div>
          </form>
        );}
      }
    </Formik>
  );
};
export default ProductSkuFilter;
