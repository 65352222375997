import React, { useEffect, useState } from "react";
import { ModalProgressBar } from "../../../components/common/controls";
import { headerSortingClasses, get, sortCaret, delete_ } from "../../../helpers";
import {
  LogoColumnFormatter,
  NameColumnFormatter,
} from "../../ProductSkus/product-sku-table/column-formatters";
import { toast } from "react-toastify";
import * as uiHelpers from "../UiHelpers";
import { DataTable } from "../../../components";

const UserCart = ({ id }) =>{
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const handleTableChange = (type, { page, sizePerPage }) => {
    return;
  };
  const getData = async () => {
    setLoading(false);
    const { cart } = await get('/cart/admin/list/' + id);
    setData(cart.products);
    setLoading(false);
  };
  const emptyUserCart = async () => {
    setLoading(true);
    const { status, message } = await delete_('/cart/admin/delete/'+id);
    setLoading(false);
    if(!status){
      toast.info(message);
      return;
    }
    toast.success('Kullanıcı sepeti boşaltıldı!');
    getData();
  };
  const columns = [
    {
      dataField: "id",
      text: "ID",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      style: {
        width: "40px",
      },
    },
    {
      dataField: "image",
      text: "",
      formatter: LogoColumnFormatter,
      style: {
        width: "80px",
      },
    },
    {
      dataField: "name",
      text: "Ürün Adı",
      sort: true,
      sortCaret: sortCaret,
      formatter: NameColumnFormatter,
      headerSortingClasses,
    },
    {
      dataField: "type.name",
      text: "Tür",
      
    },
    {
      dataField: "brand.name",
      text: "Marka",
      
    },
  ];
  const paginationOptions = {
    custom: true,
    totalSize: 10,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: 10,
    page: 1,
  };

  useEffect(() => {
    getData();
  },[]);

  return (
    <div className="card card-custom">
      {loading && <ModalProgressBar />}
      <div className="card-header py-3">
        <div className="card-title align-items-start flex-column">
          <h3 className="card-label font-weight-bolder text-dark">
            Sepet
          </h3>
          <span className="text-muted font-weight-bold font-size-sm mt-1">
            Kullanıcı sepetini görüntüleyin
          </span>
        </div>
      </div>
      <div className="card-body">
        {data && data.length > 0 && <button type="button" className="btn btn-info mb-5" onClick={emptyUserCart}>
          Sepeti Boşalt
        </button>}
        <DataTable
          data={data || []}
          defaultSorted={uiHelpers.defaultSorted}
          columns={columns}
          paginationOptions={paginationOptions}
          handleTableChange={handleTableChange}
          listLoading={loading} />
      </div>
    </div>
  );
};

export default UserCart;
