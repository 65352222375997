import React, { useEffect, useMemo } from "react";
import { useHistory } from 'react-router-dom';
import {
  getHandlerTableChange,
  sortCaret,
  headerSortingClasses, post,
} from '../../../helpers';
import * as uiHelpers from "../UiHelpers";
import { useRoleUIContext } from '../UIContext';
import { DataTable, DefaultActionsColumnFormatter, DefaultStatusColumnFormatter } from "../../../components";

const RoleTable = () => {
  const { push } = useHistory();
  const UIContext = useRoleUIContext();
  const openEditDialog = (id) => {
    push(`/user_management/roles/${id}/edit`);
  };
  const openDeleteDialog = (id) => {
    push(`/user_management/roles/${id}/delete`);
  };

  const UIProps = useMemo(() => {
    return {
      setQueryParams: UIContext.setQueryParams,
      setState: UIContext.setState,
      setError: UIContext.setError,
      setListLoading: UIContext.setListLoading,
    };
  }, [UIContext]);

  const { state: { total_count, roles }, listLoading, queryParams } = UIContext;

  useEffect(() => {
    const getRoles = async () => {
      UIProps.setListLoading(true);
      const { roles, status, message, total_count } = await post('/role/admin/list', { ...queryParams });
      UIProps.setListLoading(false);
      if(!status){
        UIProps.setError(message);
        return;
      }
      UIProps.setError(null);
      UIProps.setState({ roles, total_count });
    };
    getRoles();
    return () => {
      UIProps.setError(null);
    };
  }, [queryParams]);

  const columns = [
    {
      dataField: "id",
      text: "ID",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "name",
      text: "Rol Adı",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "description",
      text: "Rol Açıklaması",
    },
    {
      dataField: "is_active",
      text: "Durum",
      formatter: DefaultStatusColumnFormatter,
    },
    {
      dataField: "action",
      text: "İşlem",
      formatter: DefaultActionsColumnFormatter,
      formatExtraData: {
        openEditDialog,
        openDeleteDialog,
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
    },
  ];
  
  const paginationOptions = {
    custom: true,
    totalSize: total_count,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: queryParams.pageSize,
    page: queryParams.pageNumber,
  };

  return (
    <DataTable
      data={roles || []}
      defaultSorted={uiHelpers.defaultSorted}
      columns={columns}
      paginationOptions={paginationOptions}
      handleTableChange={getHandlerTableChange(
        UIProps.setQueryParams
      )}
      listLoading={listLoading} />
  );
};
export default RoleTable;
