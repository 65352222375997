import React, { useEffect, useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { RoleForm } from './index';
import { useRoleUIContext } from '../UIContext';
import { get, post, put } from '../../../helpers';

const RoleAdd = ({ id, show, onHide }) => {
  const [title, setTitle] = useState('');
  const [roleForEdit, setRoleForEdit] = useState(undefined);
  const roleUIContext = useRoleUIContext();
  const { queryParams, initRole } = roleUIContext;

  const roleUIProps = useMemo(() => {
    return {
      setError: roleUIContext.setError,
      setState: roleUIContext.setState,
    };
  }, [roleUIContext]);

  const getRole = async () => {
    if(!id){
      return;
    }
    const { role } = await get('role/'+id);
    setRoleForEdit(role);
  };

  useEffect(() => {
    getRole();
  }, [id]);

  useEffect(() => {
    let _title = id ? "" : "Yeni Rol";
    if (roleForEdit && id) {
      _title = `${roleForEdit.name} Düzenle`;
    }
    setTitle(_title);
  }, [roleForEdit]);

  const saveRole = async (role) => {
    if (!id) {
      const { status, message } = await post('role/add', role);
      if(!status){
        roleUIProps.setError(message);
        onHide();
        return;
      }
    } else {
      const { status, message } = await put('role/'+id, role);
      if(!status){
        roleUIProps.setError(message);
        onHide();
        return;
      }
    }
    const { roles,status, message, total_count } = await post('/role/admin/list', { ...queryParams });
    if(!status){
      roleUIProps.setError(message);
      return;
    }
    roleUIProps.setError(null);
    await roleUIProps.setState({ roles, total_count });
    onHide();
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <RoleForm
        saveRole={saveRole}
        role={roleForEdit || initRole}
        onHide={onHide}
      />
    </Modal>
  );
};
export default RoleAdd;
