import React from 'react';
import { Card, CardBody, CardHeader, CardHeaderToolbar, FormAlert } from '../../components/common/controls';
import { useSubCategoryUIContext } from './UIContext';
import SubCategoryTable from './sub-category-table/SubCategoryTable';
import { SubCategoryFilter } from './sub-category-table';

const SubCategoryCard = () => {
  const { error } = useSubCategoryUIContext();
  return (
    <React.Fragment>
      <FormAlert visible={error !== null} message={error} />
      <Card>
        <CardBody>
          <SubCategoryFilter />
          <SubCategoryTable />
        </CardBody>
      </Card>
    </React.Fragment>
  );
};
export default SubCategoryCard;