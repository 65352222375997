import React from "react";

const DefaultActionsColumnFormatter = (cellContent, row, rowIndex, { openEditDialog, openDeleteDialog }) => {
  const _openEditDialog = () => openEditDialog(row.id);
  const _openDeleteDialog = () => openDeleteDialog(row.id);
  return (
    <React.Fragment>
      <a title="Edit" className="btn btn-icon btn-clean btn-xs btn-hover-primary mx-2" onClick={_openEditDialog}>
        <i className="flaticon2-edit" />
      </a>
      <a title="Delete" className="btn btn-icon btn-clean btn-xs btn-hover-danger mx-2" onClick={_openDeleteDialog}>
        <i className="flaticon2-trash" />
      </a>
    </React.Fragment>
  );
};
export default DefaultActionsColumnFormatter;
