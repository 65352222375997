import React from "react";
import { CategoryUIProvider } from './UIContext';
import { Route, useHistory } from 'react-router-dom';
import CategoryCard from './CategoryCard';
import { CategoryAdd, CategoryDeleteDialog, CategoryLogoDialog } from './dialog';

const CategoryPage = () => {
  const { push } = useHistory();
  const _onHide = () => push("/content/categories");
  const _handleAddModal = ({ match }) => <CategoryAdd show={match != null} onHide={_onHide}/>;
  const _handleEditModal = ({ match }) => <CategoryAdd show={match != null} id={match && match.params.id} onHide={_onHide}/>;
  const _handleDeleteModal = ({ match }) => <CategoryDeleteDialog show={match != null} id={match && match.params.id} onHide={_onHide} />;
  const _handleLogoModal = ({ match }) => <CategoryLogoDialog show={match != null} id={match && match.params.id} onHide={_onHide} />;

  return (
    <CategoryUIProvider>
      <Route path="/content/categories/new" render={_handleAddModal} />
      <Route path="/content/categories/:id/edit" render={_handleEditModal} />
      <Route path="/content/categories/:id/delete" render={_handleDeleteModal}/>
      <Route path="/content/categories/:id/logo" render={_handleLogoModal}/>
      <CategoryCard />
    </CategoryUIProvider>
  );
};
export default CategoryPage;
