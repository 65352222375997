import React from "react";

const NameColumnFormatter = (cellContent, row) =>{
  const { name, create_date } = row;
  return (
    <React.Fragment>
      <div className="text-dark-75 font-weight-bold mb-1">{name}</div>
      <div className="text-dark-50 font-weight-lighter mb-1">Oluşturma Tarihi: {create_date}</div>
    </React.Fragment>
  );
};
export default NameColumnFormatter;
