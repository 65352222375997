import React, { useEffect, useState } from 'react';
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Input } from '../../components/common/controls';
import { post } from '../../helpers';
import Select from 'react-select';
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { useUserUIContext } from "./UIContext";

const UserSchema =Yup.object().shape({
  first_name: Yup.string()
    .min(3, "En az 3 karakter")
    .max(50, "En fazla 50 karakter")
    .required("Kullanıcı adı gerekli"),
  last_name: Yup.string()
    .min(3, "En az 3 karakter")
    .max(50, "En fazla 50 karakter")
    .required("Kullanıcı soyadı gerekli"),
  email: Yup.string()
    .email('Geçersiz email')
    .required('Email adresi gerekli'),
  gsm: Yup.number().positive(),
  password: Yup.string()
    .min(3, "En az 3 karakter")
    .max(50, "En fazla 50 karakter")
});

const UserForm = ({ btnRef, saveUser, user, tab, setError }) => {
  const [companies, setCompanies] = useState([]);
  const [addresses, setAddresses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [roles, setRoles] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState([]);
  const { queryParams } = useUserUIContext();
  const getRoles = async () => {
    const { roles, status, message } = await post('/role/admin/list', {});
    if(!status){
      setError(message);
      return;
    }
    const roles_ = roles.map(item => {
      return { value: item.id, label: item.name };
    });
    setRoles(roles_);
  };

  const _onSubmit = (values) => {
    saveUser(values);
  };
  const getAddresses = async (params) => {
    setLoading(true);
    const { addresses } = await post('/address/admin/list', { ...params });
    const _addresses = addresses.map(item => {
      return { value: item.id, label: item.name };
    });
    setAddresses(_addresses);
    setLoading(false);
  };
  const getCompanies = async (params) => {
    setLoading(true);
    const { companies } = await post('/company/admin/list', { ...params });
    setCompanies(companies);
    setLoading(false);
  };
  const checkAdmin = (values) => {
    return values.roles.indexOf(3) > -1;
  };
  const checkSeller = (values) => {
    return values.roles.indexOf(4) > -1;
  };

  useEffect(()=>{
    getRoles();
    getCompanies(queryParams);
    return () => {
      setError(null);
    };
  },[]);
  useEffect(() => {}, [selectedCompany]);
  return (
    <Formik
      enableReinitialize={true}
      initialValues={user}
      validationSchema={UserSchema}
      onSubmit={_onSubmit}
    >
      {({ handleSubmit, values , setFieldValue }) =>
      {
        const filterBy = () => true;
        const _handleRoleSelectChange = (item) => {
          setFieldValue('roles', item.map(el => {
            return el.value;
          }));
        };
        const _handleAddressChange = (item) => {
          setSelectedAddress(item);
          setFieldValue('addresses', item.map(el => {
            return el.value;
          }));
        };
        const _handleCompanyChange = (item)=> {
          if(item.length > 0){
            setSelectedCompany(item);
            setFieldValue('company_id', item[0].id);
          }
        };
        const _handleSearch = async (query) => {
          const newQueryParams = { ...queryParams };
          const filter = {};
          if (query) {
            filter.name = query;
          }
          newQueryParams.filter = filter;
          await getCompanies(newQueryParams);
        };
        const _handleAddressSearch = async (query) => {
          const newQueryParams = { ...queryParams };
          const filter = {};
          filter.company_id = values.company_id;
          if (query) {
            filter.name = query;
          }
          newQueryParams.filter = filter;
          await getAddresses(newQueryParams);
        };

        return(
          <Form className="form form-label-right">
            <div style={{ minHeight: 250 }} className="mt-5">
              {tab === "general" &&
              <React.Fragment>
                <div className="form-group row">
                  <div className="col-lg-12">
                    <Field
                      name="first_name"
                      component={Input}
                      placeholder="Ad"
                      label="Ad"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-12">
                    <Field
                      name="last_name"
                      component={Input}
                      placeholder="Soyad"
                      label="Soyad"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-12">
                    <Field
                      name="email"
                      component={Input}
                      placeholder="E Posta"
                      label="E Posta"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-12">
                    <Field
                      name="gsm"
                      component={Input}
                      placeholder="Telefon Numarası"
                      label="Telefon Numarası"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-12">
                    <Field
                      name="password"
                      component={Input}
                      placeholder="Parola"
                      label="Parola"
                    />
                  </div>
                </div>
              </React.Fragment>
              }
              {tab === "roles" &&
              <React.Fragment>
                <Select
                  onChange={_handleRoleSelectChange}
                  options={roles}
                  placeholder={'Rol'}
                  isMulti
                  name="roles"
                  className="basic-multi-select"
                  classNamePrefix="select"
                  defaultValue={user.roles.length > 0 ?
                    roles.filter(role => user.roles.some(item => item === role.value)) :
                    values.roles.length > 0 ?
                      roles.filter(role => values.roles.some(item => item === role.value)) :
                      []
                  }
                />
              </React.Fragment>
              }
              {tab === "company" &&
              <React.Fragment>
                {
                  (checkAdmin(values) || checkSeller(values)) &&
                  <AsyncTypeahead
                    filterBy={filterBy}
                    id={'companySearch'}
                    isLoading={loading}
                    labelKey="name"
                    minLength={3}
                    onChange={_handleCompanyChange}
                    onSearch={_handleSearch}
                    options={companies}
                    selected={selectedCompany}
                    placeholder={'Firma*'}
                  />
                }
                <div style={{ height: 10 }}/>
                {
                  values.company_id && checkSeller(values) &&
                  <AsyncTypeahead
                    filterBy={filterBy}
                    id={'addressSearch'}
                    isLoading={loading}
                    labelKey="label"
                    minLength={3}
                    onChange={_handleAddressChange}
                    onSearch={_handleAddressSearch}
                    options={addresses}
                    selected={selectedAddress}
                    placeholder={'Adres'}
                    multiple
                  />
                }

              </React.Fragment>
              }
            </div>
            <button
              type="submit"
              style={{ display: "none" }}
              ref={btnRef}
              onSubmit={handleSubmit}
            />
          </Form>
        );}
      }
    </Formik>
  );
};
export default UserForm;
