import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Badge } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { put, toAbsoluteUrl } from "../../../helpers";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import { MESSAGES } from "../../../constant/helper";


const ProfileCard = ({ user, getUser }) => {
  const { acceptedFiles, getInputProps } = useDropzone();
  const getUserPic = () => {
    if (!user?.image) {
      return "none";
    }
    return user.image;
  };
  const fileToBase64 = async (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (e) => reject(e);
    });
  const _handleUpload = async () => {
    toast.info(MESSAGES.uploadStart);
    const imageStr = await fileToBase64(acceptedFiles[0]);
    const credentials = {
      image: imageStr.split(',')[1]
    };
    const { status } = await put('/user/admin/edit/image/'+user.id, credentials);
    if(!status){
      return;
    }
    toast.success(MESSAGES.uploadEnd);
    getUser();
  };
  useEffect(() => {
    if (acceptedFiles.length > 0) {
      _handleUpload();
    }
  },[acceptedFiles]);

  return (
    <React.Fragment>
      {user &&
        <div className="flex-row-auto offcanvas-mobile w-200px w-xxl-300px" id="kt_profile_aside">
          <div className="card card-custom card-stretch">
            <div className="card-body" style={{ padding: '1rem' }}>
              <div className="d-flex align-items-center flex-column">
                <div className="image-input image-input-outline" id="kt_profile_avatar" style={{
                  backgroundImage: `url(${toAbsoluteUrl(
                    "/media/users/blank.png"
                  )}`,
                }}
                >
                  <div
                    className="image-input-wrapper"
                    style={{ backgroundImage: `url(${getUserPic()})` }}
                  />
                  <label
                    className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                    data-action="change"
                    data-toggle="tooltip"
                    title=""
                    data-original-title="Change avatar"
                  >
                    <i className="fa fa-pen icon-sm text-muted" />
                    <input
                      {...getInputProps()}
                      type="file"
                      accept=".png, .jpg, .jpeg"
                    />
                    <input type="hidden" name="profile_avatar_remove" />
                  </label>
                </div>

                <div className="mt-10 text-center">
                  <div className="font-weight-bold font-size-h5 text-dark-75">
                    {user.full_name}
                  </div>
                  <div className="text-muted">{user.create_date}</div>
                  {user.is_new && <Badge className={'ml-2'} pill variant="danger">Yeni</Badge>}
                </div>
              </div>
              <div className="py-9">
                <div className="d-flex align-items-center justify-content-between mb-2">
                  <span className="font-weight-bold mr-2">Email:</span>
                  <span className="text-muted text-hover-primary">
                    {user.email}
                  </span>
                </div>
                <div className="d-flex align-items-center justify-content-between mb-2">
                  <span className="font-weight-bold mr-2">Telefon:</span>
                  <span className="text-muted">{user.gsm}</span>
                </div>
              </div>
              <div className="navi navi-bold navi-hover navi-active navi-link-rounded">
                <div className="navi-item mb-2">
                  <NavLink
                    to={'/user_management/users/'+user.id+'/overview'}
                    className="navi-link py-4"
                    activeClassName="active"
                  >
                    <span className="navi-icon mr-2">
                      <span className="svg-icon">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Design/Layers.svg"
                          )}
                        />
                      </span>
                    </span>
                    <span className="navi-text font-size-lg">Profile Önizleme</span>
                  </NavLink>
                </div>
                <div className="navi-item mb-2">
                  <NavLink
                    to={'/user_management/users/'+user.id+'/information'}
                    className="navi-link py-4"
                    activeClassName="active"
                  >
                    <span className="navi-icon mr-2">
                      <span className="svg-icon">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/General/User.svg"
                          )}
                        />
                      </span>
                    </span>
                    <span className="navi-text font-size-lg">Kişisel Bilgiler</span>
                  </NavLink>
                </div>
                <div className="navi-item mb-2">
                  <NavLink
                    to={'/user_management/users/'+user.id+'/change-password'}
                    className="navi-link py-4"
                    activeClassName="active"
                  >
                    <span className="navi-icon mr-2">
                      <span className="svg-icon">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Communication/Shield-user.svg"
                          )}
                        />
                      </span>
                    </span>
                    <span className="navi-text font-size-lg">Şifre Ayarları</span>
                  </NavLink>
                </div>
                <div className="navi-item mb-2">
                  <NavLink
                    to={'/user_management/users/' + user.id + '/account-information'}
                    className="navi-link py-4"
                    activeClassName="active"
                  >
                    <span className="navi-icon mr-2">
                      <span className="svg-icon">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Code/Compiling.svg"
                          )}
                        />
                      </span>
                    </span>
                    <span className="navi-text font-size-lg">Hesap Bilgileri</span>
                  </NavLink>
                </div>
                <div className="navi-item mb-2">
                  <NavLink
                    to={'/user_management/users/' + user.id + '/role'}
                    className="navi-link py-4"
                    activeClassName="active"
                  >
                    <span className="navi-icon mr-2">
                      <span className="svg-icon">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Code/Spy.svg"
                          )}
                        />
                      </span>
                    </span>
                    <span className="navi-text font-size-lg">Rol Bilgileri</span>
                  </NavLink>
                </div>
                {user && user.company && <React.Fragment>
                  <div className="navi-item mb-2">
                    <NavLink
                      to={'/user_management/users/' + user.id + '/company'}
                      className="navi-link py-4"
                      activeClassName="active"
                    >
                      <span className="navi-icon mr-2">
                        <span className="svg-icon">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Communication/Mail-opened.svg"
                            )}
                          />
                        </span>
                      </span>
                      <span className="navi-text font-size-lg">
                      Firma Bilgileri
                      </span>
                    </NavLink>
                  </div>
                  <div className="navi-item mb-2">
                    <NavLink
                      to={'/user_management/users/' + user.id + '/address'}
                      className="navi-link py-4"
                      activeClassName="active"
                    >
                      <span className="navi-icon mr-2">
                        <span className="svg-icon">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Text/Article.svg"
                            )}
                          />
                        </span>
                      </span>
                      <span className="navi-text font-size-lg">
                        Adres Bilgileri
                      </span>
                    </NavLink>
                  </div>
                  <div className="navi-item mb-2">
                    <NavLink
                      to={'/user_management/users/' + user.id + '/order'}
                      className="navi-link py-4"
                      activeClassName="active"
                    >
                      <span className="navi-icon mr-2">
                        <span className="svg-icon">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Files/File.svg"
                            )}
                          />
                        </span>
                      </span>
                      <span className="navi-text font-size-lg">
                        Sipariş Bilgileri
                      </span>
                    </NavLink>
                  </div>
                  <div className="navi-item mb-2">
                    <NavLink
                      to={'/user_management/users/' + user.id + '/favorite'}
                      className="navi-link py-4"
                      activeClassName="active"
                    >
                      <span className="navi-icon mr-2">
                        <span className="svg-icon">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/General/Star.svg"
                            )}
                          />
                        </span>
                      </span>
                      <span className="navi-text font-size-lg">Favoriler</span>
                    </NavLink>
                  </div>
                  <div className="navi-item mb-2">
                    <NavLink
                      to={'/user_management/users/' + user.id + '/last-visit'}
                      className="navi-link py-4"
                      activeClassName="active"
                    >
                      <span className="navi-icon mr-2">
                        <span className="svg-icon">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/General/Visible.svg"
                            )}
                          />
                        </span>
                      </span>
                      <span className="navi-text font-size-lg">Son Ziyaret Edilenler</span>
                    </NavLink>
                  </div>
                  <div className="navi-item mb-2">
                    <NavLink
                      to={'/user_management/users/' + user.id + '/search-history'}
                      className="navi-link py-4"
                      activeClassName="active"
                    >
                      <span className="navi-icon mr-2">
                        <span className="svg-icon">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/General/Search.svg"
                            )}
                          />
                        </span>
                      </span>
                      <span className="navi-text font-size-lg">Arama Geçmişi</span>
                    </NavLink>
                  </div>
                  <div className="navi-item mb-2">
                    <NavLink
                      to={'/user_management/users/' + user.id + '/cart'}
                      className="navi-link py-4"
                      activeClassName="active"
                    >
                      <span className="navi-icon mr-2">
                        <span className="svg-icon">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Layout/Layout-top-panel-6.svg"
                            )}
                          />
                        </span>
                      </span>
                      <span className="navi-text font-size-lg">Sepet</span>
                    </NavLink>
                  </div>
                  <div className="navi-item mb-2">
                    <NavLink
                      to={'/user_management/users/' + user.id + '/notifications'}
                      className="navi-link py-4"
                      activeClassName="active"
                    >
                      <span className="navi-icon mr-2">
                        <span className="svg-icon">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/General/Notifications1.svg"
                            )}
                          />
                        </span>
                      </span>
                      <span className="navi-text font-size-lg">Bildirimler</span>
                    </NavLink>
                  </div>
                  <div className="navi-item mb-2">
                    <NavLink
                      to={'/user_management/users/' + user.id + '/devices'}
                      className="navi-link py-4"
                      activeClassName="active"
                    >
                      <span className="navi-icon mr-2">
                        <span className="svg-icon">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Devices/Tablet.svg"
                            )}
                          />
                        </span>
                      </span>
                      <span className="navi-text font-size-lg">Cihazlar</span>
                    </NavLink>
                  </div>

                </React.Fragment>
                }
              </div>
            </div>
          </div>
        </div>
      }
    </React.Fragment>
  );
};
export default ProfileCard;
