import React, {useEffect, useMemo, useRef, useState} from "react";
import {
  getHandlerTableChange,
  sortCaret,
  headerSortingClasses, post, _handleUpload,
} from '../../../helpers';
import * as uiHelpers from "../UiHelpers";
import { useSubCategoryUIContext } from '../UIContext';
import { useHistory } from "react-router-dom";
import { NameColumnFormatter } from './column-formatters';
import { DataTable, DefaultActionsColumnFormatter, DefaultStatusColumnFormatter } from "../../../components";
import LogoColumnFormatter from "../../Categories/category-table/column-formatters/LogoColumnFormatter";

const SubCategoryTable = () => {
  const { push } = useHistory();
  const subCategoryUIContext = useSubCategoryUIContext();
  const openEditDialog = (id) => {
    push(`/content/sub_categories/${id}/edit`);
  };
  const openDeleteDialog = (id) => {
    push(`/content/sub_categories/${id}/delete`);
  };
  const subCategoryUIProps = useMemo(() => {
    return {
      setQueryParams: subCategoryUIContext.setQueryParams,
      setState: subCategoryUIContext.setState,
      setError: subCategoryUIContext.setError,
      setListLoading: subCategoryUIContext.setListLoading,
    };
  }, [subCategoryUIContext]);


  const { state: { total_count, subCategories }, listLoading, queryParams } = subCategoryUIContext;
  useEffect(() => {
    const getSubCategories = async () => {
      subCategoryUIProps.setListLoading(true);
      const { sub_categories:subCategories, status, message, total_count } = await post('/category/admin/sub/list', { ...queryParams });
      subCategoryUIProps.setListLoading(false);
      if(!status){
        subCategoryUIProps.setError(message);
        return;
      }
      subCategoryUIProps.setError(null);
      subCategoryUIProps.setState({ subCategories, total_count });
    };
    getSubCategories();
    return () => {
      subCategoryUIProps.setError(null);
    };
  }, [queryParams]);
  const inputRef = useRef({});
  const [selectedFile, setSelectedFile] = useState(undefined);
  const [selectedRow, setSelectedRow] = useState(undefined);
  const columns = [
    {
      dataField: "id",
      text: "ID",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      style: {
        width: "40px",
      },
    },
    {
      dataField: "image",
      text: "",
      formatter: LogoColumnFormatter,
      formatExtraData: {
        inputRef,
        setSelectedRow,
        setSelectedFile
      },
      classes: "text-center",
      headerClasses: "text-center",
      style: {
        width: "60px",
      },
    },
    {
      dataField: "name",
      text: "Kategori",
      sort: true,
      sortCaret: sortCaret,
      formatter: NameColumnFormatter,
      headerSortingClasses,
    },
    {
      dataField: "parent_name",
      text: "Ana Kategori",
    },
    {
      dataField: "is_active",
      text: "Durum",
      formatter: DefaultStatusColumnFormatter,
      classes: "text-center pr-0",
      headerClasses: "text-center pr-0",
      style: {
        width: "60px",
      },
    },
    {
      dataField: "action",
      text: "İşlem",
      formatter: DefaultActionsColumnFormatter,
      formatExtraData: {
        openEditDialog,
        openDeleteDialog,
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        width: "100px",
      },
    },
  ];

  useEffect(() => {
    if(selectedFile){
      _handleUpload(selectedFile, selectedRow, subCategoryUIProps, '/category/admin/sub/edit/'+selectedRow.id);
    }
  },[selectedFile]);

  const paginationOptions = {
    custom: true,
    totalSize: total_count,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: queryParams.pageSize,
    page: queryParams.pageNumber,
  };
  return (
    <DataTable
      data={subCategories || []}
      defaultSorted={uiHelpers.defaultSorted}
      columns={columns}
      paginationOptions={paginationOptions}
      handleTableChange={getHandlerTableChange(
        subCategoryUIProps.setQueryParams
      )}
      listLoading={listLoading} />
  );
};
export default SubCategoryTable;
