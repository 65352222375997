import React from 'react';
import { useHistory } from 'react-router-dom';
import { Card, CardBody, FormAlert } from '../../components/common/controls';
import { useCollectionUIContext } from './CollectionUIContext';
import { CollectionTable, CollectionFilter } from './collection-table';

const CollectionCard = () => {
  const { error } = useCollectionUIContext();

  return (
    <React.Fragment>
      <FormAlert visible={error !== null} message={error} />
      <Card>
        <CardBody>
          <CollectionFilter />
          <CollectionTable />
        </CardBody>
      </Card>
    </React.Fragment>
  );
};
export default CollectionCard;
