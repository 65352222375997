import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { useCompanyUIContext } from '../UIContext';
import { delete_, post } from '../../../helpers';

const CompanyDeleteDialog = ({ id, show, onHide }) => {
  const companyUIContext = useCompanyUIContext();
  const companyUIProps = useMemo(() => {
    return {
      setError: companyUIContext.setError,
      setState: companyUIContext.setState
    };
  }, [companyUIContext]);

  const { queryParams } = companyUIContext;

  useEffect(() => {
    if (!id) {
      onHide();
    }
  }, [id]);


  const deleteCompany = async () => {
    const { status, message } = await delete_(`/company/delete/${id}`);
    if(!status){
      companyUIProps.setError(message);
      onHide();
      return;
    }
    const { companies,status:_status, message:_message, total_count } = await post('/company/list', { ...queryParams });
    if(!_status){
      companyUIProps.setError(_message);
      return;
    }
    await companyUIProps.setState({ companies, total_count });
    onHide();
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
    >
      <Modal.Header closeButton>
        <Modal.Title>
            Durum Güncelle
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span>Seçtiğiniz verinin durumu değiştirilecek. Devam etmek istiyor musunuz?</span>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={onHide}
            className="btn btn-light btn-elevate"
          >
              İptal
          </button>
          <button
            type="button"
            onClick={deleteCompany}
            className="btn btn-danger btn-elevate"
          >
              Güncelle
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
export default CompanyDeleteDialog;
