import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { ModalProgressBar } from "../../../components/common/controls";
import Select from "react-select";
import { toast } from "react-toastify";
import { get, post, put } from "../../../helpers";

const CompanyPayment = ({ company }) =>{
  const [loading, setLoading] = useState(false);
  const [selectedPaymentMethods, setSelectedPaymentMethods] = useState(undefined);
  const [paymentMethods, setPaymentMethods] = useState(undefined);
  const [me, setMe] = useState(undefined);

  const getMe = async () => {
    const { user } = await get('/auth/admin/me');
    setMe(user);
  };
  const getPaymentMethods = async () => {
    const { payment_methods } = await post('/payment/admin/list/payment_methods', {});
    const _payment_methods = payment_methods.map(item => {
      return { value: item.id, label: item.name };
    });
    setPaymentMethods(_payment_methods);
  };
  const savePaymentMethods = async () => {
    setLoading(true);
    const { status, message } = await put('/company/admin/'+company.id, {
      name:company.name,
      methods: selectedPaymentMethods.map(item => item.value)
    });
    setLoading(false);
    if(!status){
      toast.info(message);
      return;
    }
    toast.success('Ödeme yöntemleri güncellendi!');
    
  };
  const handleSelectedPaymentMethods = () => {
    const _payment_methods = company.payment_methods?.map(item => {
      return { value: item.id, label: item.name };
    });
    console.log(_payment_methods);
    setSelectedPaymentMethods(_payment_methods);
  };

  const _handleMethodSelectChange = (item) => {
    setSelectedPaymentMethods(item);
  };

  useEffect(()=> {
    getMe();
    getPaymentMethods();
    handleSelectedPaymentMethods();
  },[]);

  return (
    <div className="card card-custom">
      {loading && <ModalProgressBar />}
      <div className="card-header py-3">
        <div className="card-title align-items-start flex-column">
          <h3 className="card-label font-weight-bolder text-dark">
            Ödeme Yöntemleri
          </h3>
          <span className="text-muted font-weight-bold font-size-sm mt-1">
            Firma ödeme yöntemlerini güncelleyin
          </span>
        </div>
        <div className="card-toolbar">
          <button type="submit" className="btn btn-success mr-2" onClick={savePaymentMethods} disabled={!selectedPaymentMethods || selectedPaymentMethods.length === 0}>
            Güncelle
          </button>
          <Link to={'/seller_management/companies/'+company.id+'/overview'} className="btn btn-secondary">İptal</Link>
        </div>
      </div>
      {
        paymentMethods && paymentMethods.length > 0 && me &&
        <div className="form">
          <div className="card-body">
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label text-alert">
                Rol
              </label>
              <div className="col-lg-9 col-xl-6">
                <Select
                  onChange={_handleMethodSelectChange}
                  options={paymentMethods}
                  placeholder={'Ödeme Yöntemi'}
                  isMulti
                  name="payment_methods"
                  className="basic-multi-select"
                  classNamePrefix="select"
                  defaultValue={selectedPaymentMethods && selectedPaymentMethods.length > 0 ?
                    paymentMethods.filter(method => selectedPaymentMethods.some(item => item.value === method.value)) : []
                  }
                />
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  );
};

export default CompanyPayment;
