import React from 'react';
import { Card, CardBody, FormAlert } from '../../components/common/controls';
import { useProductUIContext } from './UIContext';
import { ProductTable, ProductFilter } from './product-table';

const ProductCard = () => {
  const { error } = useProductUIContext();

  return (
    <React.Fragment>
      <FormAlert visible={error !== null} message={error} />
      <Card>
        <CardBody>
          <ProductFilter />
          <ProductTable />
        </CardBody>
      </Card>
    </React.Fragment>
  );
};
export default ProductCard;
