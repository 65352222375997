import React, { useEffect, useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import {
  getHandlerTableChange,
  NoRecordsFoundMessage,
  sortCaret,
  headerSortingClasses, post,
} from '../../../helpers';
import * as uiHelpers from "../UiHelpers";
import { Pagination } from "../../../components/common/controls";
import { useUserUIContext } from '../UIContext';
import {
  ImageColumnFormatter,
  NameColumnFormatter,
  SourceColumnFormatter, UserActionsColumnFormatter,
} from "./column-formatters";
import { useHistory } from "react-router-dom";
import { DefaultStatusColumnFormatter } from "../../../components";

const UserTable = () => {
  const history = useHistory();
  const userUIContext = useUserUIContext();
  const userUIProps = useMemo(() => {
    return {
      setQueryParams: userUIContext.setQueryParams,
      setState: userUIContext.setState,
      setError: userUIContext.setError,
      setListLoading: userUIContext.setListLoading,
    };
  }, [userUIContext]);

  const { state: { total_count, users }, listLoading, queryParams } = userUIContext;

  const openDeleteDialog = (id) => {
    history.push(`/user_management/users/${id}/delete`);
  };

  useEffect(() => {
    const getUsers = async () => {
      userUIProps.setListLoading(true);
      const { users,status, message, total_count } = await post('/user/admin/list', { ...queryParams });
      userUIProps.setListLoading(false);
      if(!status){
        userUIProps.setError(message);
        return;
      }
      userUIProps.setError(null);
      userUIProps.setState({ users, total_count });
    };
    getUsers();
    return () => {
      userUIProps.setError(null);
    };
  }, [queryParams]);

  const columns = [
    {
      dataField: "id",
      text: "ID",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      style: {
        width: "50px",
      },
    },
    {
      dataField: "image",
      text: "",
      sort: false,
      formatter: ImageColumnFormatter,
      classes: "text-center",
      headerClasses: "text-center",
      style: {
        width: "60px",
      },
    },
    {
      dataField: "full_name",
      text: "Adı Soyadı",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: NameColumnFormatter,

    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "gsm",
      text: "Gsm",
      sort: false,
    },
    {
      dataField: "is_active",
      text: "Durum",
      sort: false,
      formatter: DefaultStatusColumnFormatter,
      classes: "text-center",
      headerClasses: "text-center",
      style: {
        width: "70px",
      },
    },
    {
      dataField: "source",
      text: "Kaynak",
      sort: false,
      formatter: SourceColumnFormatter,
      classes: "text-center",
      headerClasses: "text-center",
      style: {
        width: "70px",
      },
    },
    {
      dataField: "action",
      text: "İşlem",
      formatter: UserActionsColumnFormatter,
      formatExtraData: {
        openDeleteDialog,
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
    },
  ];
  const customTotal = (from, to, size) => {
    return (
      <span className="react-bootstrap-table-pagination-total">
        { from } - { to } Toplam { size }
      </span>
    );
  };
  const paginationOptions = {
    custom: true,
    totalSize: total_count,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: queryParams.pageSize,
    page: queryParams.pageNumber,
    paginationTotalRenderer: customTotal,
  };
  return (
    <PaginationProvider pagination={paginationFactory(paginationOptions)}>
      {({ paginationProps, paginationTableProps }) => {
        return (
          <Pagination
            isLoading={listLoading}
            paginationProps={paginationProps}
          >
            <BootstrapTable
              wrapperClasses="table-responsive"
              classes="table table-head-custom table-head-bg table-vertical-center overflow-hidden"
              bootstrap4
              loading={ listLoading }
              bordered={false}
              remote
              keyField="id"
              data={users === null ? [] : users}
              columns={columns}
              defaultSorted={uiHelpers.defaultSorted}
              onTableChange={getHandlerTableChange(
                userUIProps.setQueryParams
              )}
              {...paginationTableProps}
              noDataIndication={ () => !listLoading && <NoRecordsFoundMessage /> } />
          </Pagination>
        );
      }}
    </PaginationProvider>
  );
};
export default UserTable;
