import React, { useEffect, useState } from 'react';
import { Formik, Form, Field } from "formik";
import Select from 'react-select';
import * as Yup from "yup";
import { Input } from "../../components/common/controls";
import { post } from '../../helpers';

const ProductEditSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, "En az 5 karakter")
    .max(50, "En fazla 50 karakter")
    .required("Ürün adı gerekli"),
});

export function ProductForm({
  product,
  btnRef,
  setError,
  saveProduct,
}) {
  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const [sub_categories, setSubCategories] = useState([]);
  const [types, setTypes] = useState([]);
  const getTypes = async () => {
    const { product_types, status, message } = await post('/product_type/admin/list', {});
    if(!status){
      setError(message);
      return;
    }
    setTypes(product_types);
  };
  const getBrands = async () => {
    const { brands, status, message } = await post('/brand/list', {});
    if(!status){
      setError(message);
      return;
    }
    setBrands(brands);
    getTypes();
  };
  const getCategories = async () => {
    const { categories, status, message } = await post('/category/list', {});
    if(!status){
      setError(message);
      return;
    }
    setCategories(categories);
    getBrands();
  };
  const getSubCategories = async (id) => {
    const { sub_categories, status, message } = await post('/category/sub/list', { queryParams:{ filter:{ parent_id:id } } });
    if(!status){
      setError(message);
      return;
    }
    setSubCategories(sub_categories);
  };

  useEffect(() => {
    getCategories();
  },[]);


  const _onSubmit = (values) => {
    const _keys = Object.keys(values.attributes);
    const _values = Object.values(values.attributes);
    let formattedAttribute = {};
    _keys.map((key,index) => formattedAttribute[key]=_values[index].id);
    values['attributes'] = formattedAttribute;
    saveProduct(values);
  };

  return (
    <React.Fragment>
      <Formik
        enableReinitialize={true}
        initialValues={product}
        validationSchema={ProductEditSchema}
        onSubmit={_onSubmit}
      >
        {({ handleSubmit, setFieldValue, values, setValues }) => {
          const _handleCategoryChange = (item) => {
            setFieldValue('category', item);
            getSubCategories(item.id, "");
          };
          const _handleSubCategoryChange = (item) => {
            setFieldValue('sub_category', item);
          };
          const _handleBrandChange = (item) => {
            setFieldValue('brand', item);
            console.log(values);
          };
          const _handleProductTypeChange = (item) => {
            if (item.attributes.length > 0) {
              let addedAttributes = {};
              item.attributes.map(attributes => addedAttributes[attributes.id] = undefined);
              setFieldValue('attributes', addedAttributes);
            }

            setFieldValue('type', item);
          };
          const _handleCustomChange = (item, value) => {
            setValues({ ...values, attributes: { ...values.attributes, [value]:item } });
          };
          return (
            <React.Fragment>
              <Form className="form form-label-right">
                <div style={{ minHeight: 250 }} className="mt-5">
                  <React.Fragment>
                    <div className="form-group row">
                      <div className="col-lg-3">
                        <Select
                          placeholder={'Ürün Türü'}
                          className="basic-single"
                          classNamePrefix="select"
                          name="product_type"
                          options={types}
                          getOptionLabel={ x => x.name}
                          getOptionValue={ x => x.id}
                          value={values.type}
                          onChange={_handleProductTypeChange}
                        />
                      </div>
                      <div className="col-lg-3">
                        <Select
                          placeholder={'Kategori'}
                          className="basic-single"
                          classNamePrefix="select"
                          name="category"
                          options={categories}
                          getOptionLabel={ x => x.name}
                          getOptionValue={ x => x.id}
                          onChange={_handleCategoryChange}
                          value={values.category}
                        />
                      </div>
                      <div className="col-lg-3">
                        <Select
                          placeholder={'Alt Kategori'}
                          className="basic-single"
                          classNamePrefix="select"
                          name="sub_category"
                          options={sub_categories}
                          getOptionLabel={ x => x.name}
                          getOptionValue={ x => x.id}
                          onChange={_handleSubCategoryChange}
                          value={values.sub_category}
                          // onInputChange={(name) => getSubCategories(values.category.id, name)}
                        />
                      </div>
                      <div className="col-lg-3">
                        <Select
                          placeholder={'Marka'}
                          className="basic-single"
                          classNamePrefix="select"
                          name={'brand'}
                          options={brands}
                          getOptionLabel={ x => x.name}
                          getOptionValue={ x => x.id}
                          onChange={_handleBrandChange}
                          value={values.brand}
                        />
                      </div>
                    </div>
                    {/*{*/}
                    {/*  values.type?.attributes.length > 0 &&*/}
                    {/*  <div className="form-group row">*/}
                    {/*    {*/}
                    {/*      values.type.attributes.map(attributes =>*/}
                    {/*        <div className="col-lg-3" key={attributes.id}>*/}
                    {/*          <Select*/}
                    {/*            placeholder={attributes.name}*/}
                    {/*            className="basic-single"*/}
                    {/*            classNamePrefix="select"*/}
                    {/*            name={attributes.name}*/}
                    {/*            options={attributes.values}*/}
                    {/*            getOptionLabel={ x => x.name}*/}
                    {/*            getOptionValue={ x => x.id}*/}
                    {/*            onChange={(item) => _handleCustomChange(item,attributes.id)}*/}
                    {/*            value={values.attributes[attributes.id]}*/}
                    {/*          />*/}
                    {/*        </div>*/}
                    {/*      )*/}
                    {/*    }*/}
                    {/*  </div>*/}
                    {/*}*/}

                    <div className="form-group row">
                      <div className="col-lg-12">
                        <Field
                          name="name"
                          component={Input}
                          placeholder="Ürün Adı"
                          label="Ad"
                        />
                      </div>
                    </div>
                  </React.Fragment>
                </div>
                <button
                  type="submit"
                  style={{ display: "none" }}
                  ref={btnRef}
                  onSubmit={handleSubmit}
                />
              </Form>
            </React.Fragment>

          );
        }

        }
      </Formik>
    </React.Fragment>
  );
}
