import React, { useEffect, useState } from "react";
import { get } from "../../../helpers";
import { DataTable } from "../../../components";
import * as uiHelpers from "../UiHelpers";

const UserSearchHistory = ({ id }) =>{

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const getData = async () => {
    setLoading(true);
    const { search_history } = await get('/search/admin/history/list/' + id);
    setData(search_history);
    setLoading(false);
  };

  const columns = [
    {
      dataField: "keyword",
      text: "Kelime",
      style: {
        width: "40px",
      },
    }
  ];


  useEffect(() => {
    getData();
  },[]);

  const paginationOptions = {
    custom: true,
    totalSize: 10,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: 10,
    page: 1,
  };
  return (
    <div className="card card-custom">
      <div className="card-header py-3">
        <div className="card-title align-items-start flex-column">
          <h3 className="card-label font-weight-bolder text-dark">
            Arama Geçmişi
          </h3>
          <span className="text-muted font-weight-bold font-size-sm mt-1">
            Kullanıcının arama geçmişini görüntüleyin
          </span>
        </div>
      </div>
      <div className="card-body">
        <DataTable
          data={data || []}
          defaultSorted={uiHelpers.defaultSorted}
          columns={columns}
          paginationOptions={paginationOptions}
          listLoading={loading} />
      </div>
    </div>
  );
};

export default UserSearchHistory;
