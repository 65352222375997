import React, {useEffect, useMemo} from "react";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import objectPath from "object-path";
import { useHtmlClassService } from "../../../core/MetronicLayout";
import { toAbsoluteUrl } from "../../../../helpers";
import { DropdownTopbarItemToggler } from "../../dropdowns";
import { useStores } from '../../../../store';

export function UserProfileDropdown() {
  const { userInfo: { user }, setAuthState } = useStores();
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      light:
          objectPath.get(uiService.config, "extras.user.dropdown.style") ===
          "light",
    };
  }, [uiService]);

  const handleLogOut = () => {
    setAuthState();
  };

  return (
    <Dropdown drop="down" alignRight>
      <Dropdown.Toggle as={DropdownTopbarItemToggler} id="dropdown-toggle-user-profile">
        <div
          className={
            "btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
          }
        >
          <span className="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1" />
          <span className="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3">
            {user.full_name}
          </span>
          <span className="symbol symbol-35 symbol-light-success">
            <img alt="Pic" className="hidden" src={user.image} />
          </span>
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
        <>
          {/** ClassName should be 'dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl' */}
          {layoutProps.light &&
                <>
                  <div className="d-flex align-items-center p-8 rounded-top">
                    <div className="symbol symbol-md bg-light-primary mr-3 flex-shrink-0">
                      <img src={user.image} alt="" />
                    </div>
                    <div className="text-dark m-0 flex-grow-1 mr-3 font-size-h5">
                      {user.full_name}
                    </div>
                    <span className="label label-light-success label-lg font-weight-bold label-inline">
                  3 messages
                    </span>
                  </div>
                  <div className="separator separator-solid"></div>
                </>
          }

          {!layoutProps.light &&
                <div
                  className="d-flex align-items-center justify-content-between flex-wrap p-8 bgi-size-cover bgi-no-repeat rounded-top"
                  style={{
                    backgroundImage: `url(${toAbsoluteUrl(
                      "/media/misc/bg-1.jpg"
                    )})`,
                  }}
                >
                  <div className="symbol bg-white-o-15 mr-3">
                    <img alt="Pic" className="hidden" src={user.image} />
                  </div>
                  <div className="text-white m-0 flex-grow-1 mr-3 font-size-h5">
                    {user.full_name}
                  </div>
                </div>
          }
        </>

        <div className="navi navi-spacer-x-0 pt-5">
          <Link to="/user-profile" className="navi-item px-8 cursor-pointer">
            <div className="navi-link">
              <div className="navi-icon mr-2">
                <i className="flaticon2-calendar-3 text-success" />
              </div>
              <div className="navi-text">
                <div className="font-weight-bold cursor-pointer">
                    Profil Bilgilerim
                </div>
                <div className="text-muted">
                    Profil bilgilerinizi görüntüleyin veya güncelleyin
                </div>
              </div>
            </div>
          </Link>
          <div className="navi-separator mt-3"></div>

          <div className="navi-footer px-8 py-5">
            {/*<Link*/}
            {/*  to="/logout"*/}
            {/*  className="btn btn-light-primary font-weight-bold"*/}
            {/*>*/}
              <a href="" onClick={handleLogOut} className="btn btn-light-primary font-weight-bold">Çıkış</a>

          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
}
