import React, { useEffect, useState } from 'react';
import { Modal } from "react-bootstrap";
import Select from 'react-select';
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import * as _ from "lodash";
import { Checkbox, Input } from '../../../components/common/controls';
import { post } from '../../../helpers';
import DialogModalFooter from "../../../components/DialogModalFooter";
import { MultiSelect } from "react-multi-select-component";
import { overrideStrings, stockTypes } from "../../../constant/helper";

const ProductTypeSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, "En az 3 karakter")
    .max(50, "En fazla 50 karakter")
    .required("Marka adı gerekli")
});

const ProductTypeForm = ({ saveProductType, productType, onHide }) => {
  const [hasVariant, setHasVariant] = useState(false);
  const [productAttributes, setProductAttributes] = useState([]);
  const [selectedAttributes, setSelectedAttributes] = useState([]);
  const [selectedVariants, setSelectedVariants] = useState([]);

  const getAttributes = async () => {
    const { product_attributes } = await post('/product_attribute/admin/list', { });
    const attributes = product_attributes.map(item => {
      return { value: item.id, label: item.name };
    });
    setProductAttributes(attributes);
  };
  useEffect(()=>{
    setHasVariant(productType.has_variants);
  },[productType]);
  useEffect(()=>{
    getAttributes();
  },[]);
  useEffect(()=>{
    if (productType.id){
      setSelectedAttributes(productAttributes.filter(attributes => productType.attributes.some(item => item === attributes.value)));
      setSelectedVariants(productAttributes.filter(attributes => productType.variants.some(item => item === attributes.value)));
    }
  },[productAttributes, productType]);
  const _handleSubmit = (values) => {
    const _values = { ...values, stock_type: values.stock_type.id };
    saveProductType(_values);
  };

  console.log('prod', productType);
  return (
    <React.Fragment>
      <Formik enableReinitialize={true} initialValues={productType} validationSchema={ProductTypeSchema} onSubmit={_handleSubmit}>
        {({ handleSubmit, setFieldValue, values }) => {
          const _handleAttributeSelectChange = (item) => {
            setSelectedAttributes(item);
            setSelectedVariants([]);
            setFieldValue('attributes', item.map(el => {
              return el.value;
            }));
            setFieldValue('variants', []);
          };
          const _handleVariantSelectChange = (item) => {
            setSelectedVariants(item);
            setFieldValue('variants', item.map(el => {
              return el.value;
            }));
          };
          const _handleVariantActive = () => {
            const _hasVariant = !hasVariant;
            setHasVariant(_hasVariant);
            setFieldValue('has_variants', _hasVariant);
            if (!_hasVariant) {
              setSelectedVariants([]);
              setFieldValue('variants', []);
            }
          };
          const _handleStockTypeChange = (item) => {
            setFieldValue('stock_type', item);
          };
          return (
            <React.Fragment>
              <Modal.Body className="overlay overlay-block cursor-default">
                <Form className="form form-label-right">
                  <div className="form-group row">
                    <div className="col-lg-12">
                      <Field
                        name="name"
                        component={Input}
                        placeholder="Ürün Türü"
                        label="Ürün Türü*"
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-lg-12">
                      <label>Stok Türü*</label>
                      <Select
                        placeholder={'Stok Türü'}
                        className="basic-single"
                        classNamePrefix="select"
                        name="product_type"
                        options={stockTypes}
                        getOptionLabel={ x => x.name}
                        getOptionValue={ x => x.id}
                        value={values.stock_type}
                        onChange={_handleStockTypeChange}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-lg-12">
                      <label>Özellikler*</label>
                      <MultiSelect
                        options={productAttributes}
                        value={selectedAttributes}
                        onChange={_handleAttributeSelectChange}
                        overrideStrings={overrideStrings}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-lg-12">
                      <label>Varyant Aktif</label>
                      <Checkbox isSelected={hasVariant} onChange={_handleVariantActive} />
                    </div>
                  </div>
                  {hasVariant && <div className="form-group row">
                    <div className="col-lg-12">
                      <label>Varyantlar</label>
                      <MultiSelect
                        options={_.differenceWith(productAttributes, selectedAttributes, _.isEqual)}
                        value={selectedVariants}
                        onChange={_handleVariantSelectChange}
                        overrideStrings={overrideStrings}
                      />
                    </div>
                  </div>}
                </Form>
              </Modal.Body>
              <DialogModalFooter handleSubmit={handleSubmit} onHide={onHide}/>
            </React.Fragment>
          );
        }
        }
      </Formik>
    </React.Fragment>
  );
};
export default ProductTypeForm;
