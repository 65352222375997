import React, { useMemo } from 'react';
import { Formik } from "formik";
import Select from 'react-select';
import { isEqual } from "lodash";
import { useProductAttributeUIContext } from '../UIContext';
import Dropdown from "react-bootstrap/Dropdown";
import { useHistory } from "react-router-dom";

const prepareFilter = (queryParams, values) => {
  const { status, searchText } = values;
  const newQueryParams = { ...queryParams };
  const filter = {};
  if (status) filter.status = status;
  if (searchText) {
    filter.name = searchText;
  }
  newQueryParams.filter = filter;
  return newQueryParams;
};

const ProductAttributeFilter = () => {
  const { push } = useHistory();
  const productAttributeUiContext = useProductAttributeUIContext();
  const productAttributeUIProps = useMemo(() => {
    return {
      queryParams: productAttributeUiContext.queryParams,
      setQueryParams: productAttributeUiContext.setQueryParams,
    };
  }, [productAttributeUiContext]);

  const applyFilter = (values) => {
    const newQueryParams = prepareFilter(productAttributeUIProps.queryParams, values);
    if (!isEqual(newQueryParams, productAttributeUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      productAttributeUIProps.setQueryParams(newQueryParams);
    }
  };

  const initialValues = {
    status: "1",
    searchText: "",
  };
  const statusOptions = [
    { value: '', label: 'Tümü' },
    { value: '1', label: 'Aktif' },
    { value: '0', label: 'Pasif' }
  ];
  const _handleSubmit = (values) => applyFilter(values);
  const openProductAttributeAddModal = () => {
    push("/product_management/attributes/new");
  };
  return (
    <React.Fragment>
      <Formik initialValues={initialValues} onSubmit={_handleSubmit}>
        {({ values, handleSubmit, handleBlur, handleChange, setFieldValue }) =>
        {
          const _handleSelectChange = (item)=>{
            setFieldValue("status", item.value);
          };
          return(
            <form onSubmit={handleSubmit} className="form form-label-right">
              <div className="form-group row">
                <div className="col-lg-2">
                  <input
                    type="text"
                    className="form-control"
                    name="searchText"
                    placeholder="Varyant"
                    onBlur={handleBlur}
                    value={values.searchText}
                    onChange={(e) => {
                      setFieldValue("searchText", e.target.value);
                      handleSubmit();
                    }}
                  />
                </div>
                <div className="col-lg-10">
                  <Dropdown onClick={(e) => e.stopPropagation(e)} drop="down" className="float-right ml-2">
                    <Dropdown.Toggle>
                      Filtreleme Seçenekleri
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
                      <div className="navi navi-spacer-x-0 p-5">
                        <div className="form-group row">
                          <div className="col-lg-12 mt-2">
                            <Select onChange={_handleSelectChange}
                              options={statusOptions}
                              placeholder={'Durum'}
                              value={statusOptions.filter(item => item.value === values.status)}/>
                            <small className="form-text text-muted">
                              Duruma <b>Göre</b>
                            </small>
                          </div>
                          <div className="col-lg-12 mt-2">
                            <button type="button" className="btn btn-outline-primary btn-block" onClick={handleSubmit}>
                              Uygula
                            </button>
                          </div>
                        </div>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                  <button type="button" className="btn btn-info ml-2 float-right" onClick={openProductAttributeAddModal}>
                    Yeni Özellik
                  </button>
                </div>
              </div>
            </form>
          );}
        }
      </Formik>
    </React.Fragment>
  );
};
export default ProductAttributeFilter;
