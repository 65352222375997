import React, { useState, useRef, useEffect } from 'react';
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar, FormAlert,
} from '../../components/common/controls';
import { useHistory } from 'react-router-dom';
import { ProductSkuForm } from './ProductSkuForm';
import { get, post, put } from '../../helpers';
import { useProductSkuUIContext } from './UIContext';


const ProductSkuAdd = ({ id }) => {
  const history = useHistory();
  const { initProductSku } = useProductSkuUIContext();
  const [error, setError] = useState(null);
  const [productSkuForEdit, setProductSkuForEdit] = useState(undefined);

  const btnRef = useRef();
  const saveProductSkuClick = () => {
    if (btnRef && btnRef.current) {
      btnRef.current.click();
    }
  };

  const backToProductSkusList = () => {
    history.push(`/product_management/stocks`);
  };
  const saveProductSku = async (product) => {
    if (!id) {
      const { status, message } = await post('/product/admin/sku/add', product);
      if (!status) {
        setError(message);
        return;
      }
    }
    else{
      const { status, message } = await put('/product/admin/sku/'+id, product);
      if (!status) {
        setError(message);
        return;
      }
    }
    backToProductSkusList();
  };
  const getSku = async () => {
    if(!id){
      return;
    }
    const { product_sku } = await get('/product/admin/sku/'+ id);
    setProductSkuForEdit(product_sku);

  };
  useEffect(() => {
  }, [productSkuForEdit]);
  useEffect(() => {
    console.log('id', id);
    getSku();
  },[id]);
  return (
    <React.Fragment>
      <FormAlert visible={error !== null} message={error} />
      <Card>
        <CardHeader title={'Genel Bilgi'}>
          <CardHeaderToolbar>
            <button
              type="button"
              onClick={backToProductSkusList}
              className="btn btn-light"
            >
              <i className="fa fa-arrow-left" />
              Geri
            </button>
            <button
              type="submit"
              className="btn btn-primary ml-2"
              onClick={saveProductSkuClick}
            >
              Kaydet
            </button>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <ProductSkuForm setError={setError} product={productSkuForEdit || initProductSku} btnRef={btnRef} saveProductSku={saveProductSku} />
        </CardBody>
      </Card>
    </React.Fragment>
  );
};
export default ProductSkuAdd;
