import { toast } from "react-toastify";
import { MESSAGES } from "../constant/helper";
import { post, put } from "./HttpHelpers";

export function removeCSSClass(ele, cls) {
  const reg = new RegExp("(\\s|^)" + cls + "(\\s|$)");
  ele.className = ele.className.replace(reg, " ");
}

export function addCSSClass(ele, cls) {
  ele.classList.add(cls);
}

export const toAbsoluteUrl = pathname => process.env.PUBLIC_URL + pathname;


export const fileToBase64 = async (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = (e) => reject(e);
  });
};

export const _handleUpload = async (file, state, uiProps, url) => {
  toast.info(MESSAGES.uploadStart);
  const imageStr = await fileToBase64(file);
  state['image'] = imageStr.split(',')[1];
  const { status, message } = await put(url, state);
  if(!status){
    uiProps.setError(message);
    return;
  }
  toast.success(MESSAGES.uploadEnd);
  window.location.reload();
};
