import React, { useEffect, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useParams } from "react-router-dom";
import { get, post } from "../../../helpers";
import { initialFilter } from "../../Users/UiHelpers";


const ChatContent = () => {
  const { id: userId } = useParams();
  const [user, setUser] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [queryParams] = useState({ ...initialFilter, filter:{ user_id:userId } });
  const [messages, setMessages] = useState([]);
  const getMessages = async () => {
    const { messages, total_count } = await post('/message/admin/list', { ...queryParams });
    setMessages(messages);
    setLoading(false);
  };
  const getUser = async () => {
    const { user } = await get('/user/'+userId);
    setUser(user);
    setLoading(false);
  };
  useEffect(() => {
    getUser();
    getMessages();
  }, [queryParams]);
  return(
    <div className="flex-row-fluid ml-lg-8" id="kt_chat_content">
      <div className="card card-custom">
        {user && <div className="card-header align-items-center px-4 py-3">
          <div className="text-center flex-grow-1">
            <div className="text-dark-75 font-weight-bold font-size-h5">{user.full_name}</div>
            <div>
              <span className="label label-sm label-dot label-success"/>
              <span className="font-weight-bold text-muted font-size-sm">Active</span>
            </div>
          </div>
        </div>}
        <div className="card-body">
          <PerfectScrollbar options={{ suppressScrollX:false }}>
            <div style={{ height: '433px', overflow: 'scroll' }} data-mobile-height="350">
              <div className="messages">
                {messages.map((item, index) => {
                  const { user_id, content, user } = item;
                  return(
                    <div key={index} className={item.user_id !== null && item.user_id === parseInt(userId) ? 'd-flex flex-column mb-5 align-items-start' : 'd-flex flex-column mb-5 align-items-end'}>
                      <div className="d-flex align-items-center">
                        {user && <div className="symbol symbol-circle symbol-40 mr-3">
                          <img src={user.image}/>
                        </div>}
                        <div>
                          <a href="#" className="text-dark-75 text-hover-primary font-weight-bold font-size-h6">{user ? user.full_name : 'Siz'}</a>
                          <span className="text-muted font-size-sm">2 Hours</span>
                        </div>
                      </div>
                      <div
                        className="mt-2 rounded p-5 bg-light-success text-dark-50 font-weight-bold font-size-lg text-left max-w-400px">
                        {content}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </PerfectScrollbar>
          <div className="card-footer align-items-center">
            <textarea className="form-control border-0 p-0" rows="2" placeholder="Type a message" />
            <div className="d-flex align-items-center justify-content-between mt-5">
              <div className="mr-3">
                <a href="#" className="btn btn-clean btn-icon btn-md mr-1">
                  <i className="flaticon2-photograph icon-lg" />
                </a>
                <a href="#" className="btn btn-clean btn-icon btn-md">
                  <i className="flaticon2-photo-camera icon-lg" />
                </a>
              </div>
              <div>
                <button type="button"
                  className="btn btn-primary btn-md text-uppercase font-weight-bold chat-send py-2 px-6">Send
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ChatContent;