import React from "react";
import { Link } from "react-router-dom";

const NameColumnFormatter = (cellContent, row) =>{
  return (
    <React.Fragment>
      <Link to={`/product_management/products/${row.id}/information`} className="text-dark-75 font-weight-bold text-hover-info mb-1">{row.name}</Link>
      <div className={'font-weight-lighter'}>{row.type.name}</div>
    </React.Fragment>
  );
};
export default NameColumnFormatter;
