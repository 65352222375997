import React from 'react';
import { Image } from 'react-bootstrap';

const LogoColumnFormatter = (cellContent, row, rowIndex, { inputRef, setSelectedRow, setSelectedFile }) => {

  const changeHandler = async (event) => {
    await setSelectedFile(event.target.files[0]);
  };

  const _handleInputClick = async () => {
    await setSelectedRow(row);
    inputRef.current[row.id].click();
  };
  const handleInput = (element) => {
    inputRef.current[row.id] = element;
  };

  return (
    <React.Fragment>
      <input style={{ display: 'none' }} ref={handleInput} type="file" name="file" onChange={changeHandler} />
      <a onClick={_handleInputClick}>
        <Image thumbnail roundedCircle style={{ objectFit:'contain', width: '41px', height: '41px' }} src={row.image} width="50"/>
      </a>
    </React.Fragment>);
};

export default LogoColumnFormatter;
