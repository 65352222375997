import React from "react";
import { AddressUIProvider } from './UIContext';
import { Route, useHistory } from 'react-router-dom';
import AddressCard from './AddressCard';
import { AddressAdd, AddressDeleteDialog } from './dialog';

const AddressPage = () => {
  const { push } = useHistory();
  const _onHide = () => push("/seller_management/addresses");
  const _handleAddModal = ({ match }) => <AddressAdd show={match != null} onHide={_onHide}/>;
  const _handleEditModal = ({ match }) => <AddressAdd show={match != null} id={match && match.params.id} onHide={_onHide}/>;
  const _handleDeleteModal = ({ match }) => <AddressDeleteDialog show={match != null} id={match && match.params.id} onHide={_onHide} />;

  return (
    <AddressUIProvider>
      <Route path="/seller_management/addresses/new" render={_handleAddModal} />
      <Route path="/seller_management/addresses/:id/edit" render={_handleEditModal} />
      <Route path="/seller_management/addresses/:id/delete" render={_handleDeleteModal}/>
      <AddressCard />
    </AddressUIProvider>
  );
};
export default AddressPage;
