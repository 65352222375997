import React from "react";

const AddressColumnFormatter = (cellContent, row) =>{
  const { name,address1, email } = row;
  return (
    <React.Fragment>
      {row.company && <p className="text-dark-75 font-weight-bolder text-hover-primary mb-1">{row.company.name}</p>}
      <div className={'text-muted'}>
        <span>
          <span>{name} : </span><span>{address1}</span>
          <div>{email}</div>
        </span>
      </div>
    </React.Fragment>
  );
};
export default AddressColumnFormatter;
