import React, { useEffect, useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { BrandForm } from './index';
import { useBrandUIContext } from '../UIContext';
import { get, post, put } from '../../../helpers';


const BrandAdd = ({ id, show, onHide }) => {
  const [title, setTitle] = useState('');
  const [brandForEdit, setBrandForEdit] = useState(undefined);
  const brandUIContext = useBrandUIContext();
  const { queryParams, initBrand } = brandUIContext;

  const brandUIProps = useMemo(() => {
    return {
      setError: brandUIContext.setError,
      setState: brandUIContext.setState,
    };
  }, [brandUIContext]);

  const getBrand = async () => {
    if(id){
      const { brand } = await get('brand/'+id);
      setBrandForEdit(brand);
    }
  };

  useEffect(() => {
    getBrand();
  }, [id]);

  useEffect(() => {
    let _title = id ? "" : "Yeni Marka";
    if (brandForEdit && id) {
      _title = `${brandForEdit.name} Düzenle`;
    }
    setTitle(_title);
  }, [brandForEdit]);


  const saveBrand = async (brand, closeWindow) => {
    if (!id) {
      const { status, message } = await post('brand/add', brand);
      if(!status){
        brandUIProps.setError(message);
        if (!closeWindow) onHide();
        return;
      }
    } else {
      const { status, message } = await put('brand/'+id, brand);
      if(!status){
        brandUIProps.setError(message);
        if (!closeWindow) onHide();
        return;
      }
    }
    const { brands, status, message, total_count } = await post('/brand/list', { ...queryParams });
    if(!status){
      brandUIProps.setError(message);
      return;
    }
    brandUIProps.setError(null);
    await brandUIProps.setState({ brands, total_count });
    if (!closeWindow) onHide();
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <BrandForm
        saveBrand={saveBrand}
        brand={brandForEdit || initBrand}
        onHide={onHide}
      />
    </Modal>
  );
};
export default BrandAdd;
