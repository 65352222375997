import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { useProductUIContext } from '../UIContext';
import { delete_, post } from '../../../helpers';
import DialogModalFooter from "../../../components/DialogModalFooter";

const ProductDeleteDialog = ({ id, show, onHide }) => {
  const productUIContext = useProductUIContext();
  const productUIProps = useMemo(() => {
    return {
      setError: productUIContext.setError,
      setState: productUIContext.setState
    };
  }, [productUIContext]);

  const { queryParams } = productUIContext;

  useEffect(() => {
    if (!id) {
      onHide();
    }
  }, [id]);


  const deleteProduct = async () => {
    const { status, message } = await delete_(`product/delete/${id}`);
    if(!status){
      productUIProps.setError(message);
      onHide();
      return;
    }
    const { products, status:_status, message:_message, total_count } = await post('/product/list', { ...queryParams });
    if(!_status){
      productUIProps.setError(_message);
      return;
    }
    await productUIProps.setState({ products, total_count });
    onHide();
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
    >
      <Modal.Header closeButton>
        <Modal.Title>
            Durum Güncelle
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span>Seçtiğiniz ürünün durumu değiştirilecek. Devam etmek istiyor musunuz?</span>
      </Modal.Body>
      <DialogModalFooter type={'delete'} handleSubmit={deleteProduct} onHide={onHide}/>
    </Modal>
  );
};
export default ProductDeleteDialog;
