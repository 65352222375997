import React, { useEffect } from "react";
import { Alert } from 'react-bootstrap';

export function FormAlert({
  visible = false,
  type = "danger",
  message = "Oh snap! Change a few things up and try submitting again.",
  onClose,
}) {
  useEffect(() => {
  }, [visible]);
  if (visible) {
    return (
      <Alert variant={type} onClose={onClose} dismissible>
        <p>
          {message}
        </p>
      </Alert>
    );
  }

  return null;
}
