import React from "react";

const inputLabel = ({ label, touched, error, customFeedbackLabel }) => {
  if (touched && error) {
    return <div className="invalid-feedback mb-4">{error}</div>;
  }

  if (touched && !error && label) {
    return <div className="valid-feedback mb-4">{label} düzgün görünüyor</div>;
  }

  return (
    <div className="feedback mt-2">
      {customFeedbackLabel && <>{customFeedbackLabel}</>}
      {/*{!customFeedbackLabel &&*/}
      {/*    <small>{label} giriniz!</small>*/}
      {/*}*/}
    </div>
  );
};

const selectLabel = ({ label, touched, error, customFeedbackLabel }) => {
  if (touched && error) {
    return <div className="invalid-feedback">{error}</div>;
  }

  return (
    <div className="feedback mt-2">
      {customFeedbackLabel && <small>{customFeedbackLabel}</small>}
      {!customFeedbackLabel && label &&
        <>
          <small>{label} seçiniz</small>
        </>
      }
    </div>
  );
};

export function FieldFeedbackLabel({
  label,
  touched,
  error,
  type,
  customFeedbackLabel
}) {
  switch (type) {
  case "text":
    return inputLabel({ label, touched, error, customFeedbackLabel });
  case "email":
    return inputLabel({ label, touched, error, customFeedbackLabel });
  case "password":
    return inputLabel({ label, touched, error, customFeedbackLabel });
  default:
    return selectLabel({ label, touched, error, customFeedbackLabel });
  }
}
