import React from 'react';
import { Card, CardBody, FormAlert } from '../../components/common/controls';
import { useBrandUIContext } from './UIContext';
import { BrandFilter, BrandTable } from './brand-table';

const BrandCard = () => {
  const { error } = useBrandUIContext();
  return (
    <>
      <FormAlert visible={error !== null} message={error} />
      <Card>
        <CardBody>
          <BrandFilter />
          <BrandTable />
        </CardBody>
      </Card>
    </>
  );
};
export default BrandCard;
