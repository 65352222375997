import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { useCategoryUIContext } from '../UIContext';
import { delete_, post } from '../../../helpers';
import DialogModalFooter from "../../../components/DialogModalFooter";

const CategoryDeleteDialog = ({ id, show, onHide }) => {
  const categoryUIContext = useCategoryUIContext();
  const categoryUIProps = useMemo(() => {
    return {
      setError: categoryUIContext.setError,
      setState: categoryUIContext.setState
    };
  }, [categoryUIContext]);

  const { queryParams } = categoryUIContext;

  useEffect(() => {
    if (!id) {
      onHide();
    }
  }, [id]);


  const deleteCategory = async () => {
    const { status, message } = await delete_(`category/delete/${id}`);
    if(!status){
      categoryUIProps.setError(message);
      onHide();
      return;
    }
    const { categories,status:_status, message:_message, total_count } = await post('/category/list', { ...queryParams });
    if(!_status){
      categoryUIProps.setError(_message);
      return;
    }
    await categoryUIProps.setState({ categories, total_count });
    onHide();
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Durum Güncelle
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span>Seçtiğiniz kategorinin durumu değiştirilecek. Devam etmek istiyor musunuz?</span>
      </Modal.Body>
      <DialogModalFooter type={'delete'} handleSubmit={deleteCategory} onHide={onHide}/>

    </Modal>
  );
};
export default CategoryDeleteDialog;
