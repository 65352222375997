import React, { useEffect, useRef, useState } from "react";
import { useHistory } from 'react-router-dom';
import {
  getHandlerTableChange,
  sortCaret,
  headerSortingClasses, post, _handleUpload,
} from '../../../helpers';
import * as uiHelpers from "../UiHelpers";
import { useCategoryUIContext } from '../UIContext';
import { DataTable, DefaultActionsColumnFormatter, DefaultStatusColumnFormatter } from "../../../components";
import LogoColumnFormatter from "./column-formatters/LogoColumnFormatter";

const CategoryTable = () => {
  const history = useHistory();
  const UIContext = useCategoryUIContext();
  const openEditDialog = (id) => {
    history.push(`/content/categories/${id}/edit`);
  };
  const openDeleteDialog = (id) => {
    history.push(`/content/categories/${id}/delete`);
  };
  const { state: { categories }, listLoading,total_count, queryParams } = UIContext;
  useEffect(() => {
    const getCategories = async () => {
      const { categories, status, message, total_count } = await post('/category/admin/list', { ...queryParams });
      if(!status){
        UIContext.setError(message);
        return;
      }
      UIContext.setState({ categories, total_count });
    };
    getCategories();

  }, []);
  const inputRef = useRef({});
  const [selectedFile, setSelectedFile] = useState(undefined);
  const [selectedRow, setSelectedRow] = useState(undefined);
  const columns = [
    {
      dataField: "id",
      text: "ID",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      style: {
        width: "40px",
      },
    },
    {
      dataField: "image",
      text: "",
      formatter: LogoColumnFormatter,
      formatExtraData: {
        inputRef,
        setSelectedRow,
        setSelectedFile
      },
      classes: "text-center",
      headerClasses: "text-center",
      style: {
        width: "60px",
      },
    },
    {
      dataField: "name",
      text: "Kategori",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "is_active",
      text: "Durum",
      formatter: DefaultStatusColumnFormatter,
      classes: "text-center",
      headerClasses: "text-center",
      style: {
        width: "60px",
      },
    },
    {
      dataField: "action",
      text: "İşlem",
      formatter: DefaultActionsColumnFormatter,
      formatExtraData: {
        openEditDialog,
        openDeleteDialog,
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        width: "100px",
      },
    },
  ];
  const paginationOptions = {
    custom: true,
    totalSize: total_count,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: queryParams.pageSize,
    page: queryParams.pageNumber,

  };
  useEffect(() => {
    if(selectedFile){
      _handleUpload(selectedFile, selectedRow, UIContext, '/category/admin/'+selectedRow.id);
    }
  },[selectedFile]);
  return (
    <DataTable
      data={categories || []}
      defaultSorted={uiHelpers.defaultSorted}
      columns={columns}
      paginationOptions={paginationOptions}
      handleTableChange={getHandlerTableChange(
        UIContext.setQueryParams
      )}
      listLoading={listLoading} />
  );
};
export default CategoryTable;
