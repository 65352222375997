import React  from "react";
import BootstrapTable from "react-bootstrap-table-next";

import {
  NoRecordsFoundMessage,
  sortCaret,
  headerSortingClasses,
} from '../../../helpers';
import * as uiHelpers from "../UiHelpers";

const ProductStockTable = ({ data }) => {
  const columns = [
    {
      dataField: "name",
      text: "Ad",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "quantity",
      text: "Stock",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "type",
      text: "Tür",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
  ];
  return (
    <BootstrapTable
      remote
      keyField="id"
      wrapperClasses="table-responsive"
      classes="table table-head-custom table-head-bg table-vertical-center overflow-hidden"
      bootstrap4
      bordered={false}
      data={data ?? []}
      columns={columns}
      defaultSorted={uiHelpers.defaultSorted}
      noDataIndication={ () => <NoRecordsFoundMessage /> } />
  );
};
export default ProductStockTable;
