import React, { useEffect, useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { SubCategoryForm } from "./index";
import { useSubCategoryUIContext } from '../UIContext';
import { get, post, put } from '../../../helpers';


const SubCategoryAdd = ({ id, show, onHide }) => {
  const [title, setTitle] = useState('');
  const [subCategoryForEdit, setSubCategoryForEdit] = useState(undefined);
  const subCategoryUIContext = useSubCategoryUIContext();
  const { queryParams, initSubCategory } = subCategoryUIContext;
  const subCategoryUIProps = useMemo(() => {
    return {
      setError: subCategoryUIContext.setError,
      setState: subCategoryUIContext.setState,
    };
  }, [subCategoryUIContext]);


  useEffect(() => {
    const getSubCategory = async () => {
      const { category } = await get('/category/admin/sub/'+id);
      console.log('category', category);
      setSubCategoryForEdit(category);
    };
    if(id){
      getSubCategory();
    }
  }, [id]);

  useEffect(() => {
    let _title = id ? "" : "Yeni Alt Kategori";
    if (subCategoryForEdit && id) {
      _title = `${subCategoryForEdit.name} Düzenle`;
    }

    setTitle(_title);
  }, [subCategoryForEdit]);

  const saveSubCategory = async (sub_category) => {
    if (!id) {
      const { status, message } = await post('/category/admin/sub/add', sub_category);
      if(!status){
        subCategoryUIProps.setError(message);
        onHide();
        return;
      }
    } else {
      const { status, message } = await put('/category/admin/sub/edit/'+id, sub_category);
      if(!status){
        subCategoryUIProps.setError(message);
        onHide();
        return;
      }
    }
    const { sub_categories:subCategories,status, message, total_count } = await post('/category/admin/sub/list', { ...queryParams });
    if(!status){
      subCategoryUIProps.setError(message);
      return;
    }
    await subCategoryUIProps.setState({ subCategories, total_count });
    onHide();
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <SubCategoryForm
        saveSubCategory={saveSubCategory}
        subCategory={subCategoryForEdit || initSubCategory}
        onHide={onHide}
      />
    </Modal>
  );
};
export default SubCategoryAdd;
