import React, { useState, useEffect } from "react";
import {Link, useHistory} from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ModalProgressBar } from "../../../components/common/controls";
import { useUserUIContext } from "../UIContext";
import {put} from "../../../helpers";
import {toast} from "react-toastify";

const ChangePassword = () =>{
  const { push } = useHistory();
  const { userForEdit:user } = useUserUIContext();

  const [loading, setloading] = useState(false);
  const [isError, setisError] = useState(false);
  useEffect(() => {}, [user]);
  // Methods
  const saveUser = async (values, setStatus, setSubmitting) => {
    setloading(true);
    setisError(false);
    const { status, message } = await put('/user/admin/password/'+user.id, values);
    console.log(status, message)
    setloading(false);
    setSubmitting(false);
    if (status) {
      toast.success('Şifre başarıyla değiştirildi.')
    }
  };
  // UI Helpers
  const initialValues = {
    password: "",
    cPassword: "",
  };
  const Schema = Yup.object().shape({
    password: Yup.string().required("Parola yazınız"),
    cPassword: Yup.string()
      .required("Parolayı tekrar yazınız")
      .when("password", {
        is: (val) => val && val.length > 0,
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          "Parolalar eşleşmedi"
        ),
      }),
  });
  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };
  const formik = useFormik({
    initialValues,
    validationSchema: Schema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      saveUser(values, setStatus, setSubmitting);
    },
    onReset: (values, { resetForm }) => {
      resetForm();
    },
  });

  return (
    <form className="card card-custom" onSubmit={formik.handleSubmit}>
      {loading && <ModalProgressBar />}
      <div className="card-header py-3">
        <div className="card-title align-items-start flex-column">
          <h3 className="card-label font-weight-bolder text-dark">
            Şifre Güncelle
          </h3>
          <span className="text-muted font-weight-bold font-size-sm mt-1">
            Kullanıcı şifresini güncelleyin
          </span>
        </div>
        <div className="card-toolbar">
          <button
            type="submit"
            className="btn btn-success mr-2"
            disabled={
              formik.isSubmitting || formik.touched && !formik.isValid
            }
          >
            Güncelle
            {formik.isSubmitting}
          </button>
          <Link to={'/user_management/users/'+user.id+'/overview'} className="btn btn-secondary">İptal</Link>
        </div>
      </div>
      <div className="form">
        <div className="card-body">
          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label text-alert">
              Yeni Şifre
            </label>
            <div className="col-lg-9 col-xl-6">
              <input
                type="password"
                placeholder="Yeni Şifre"
                className={`form-control form-control-lg form-control-solid ${getInputClasses(
                  "password"
                )}`}
                name="password"
                {...formik.getFieldProps("password")}
              />
              {formik.touched.password && formik.errors.password ?
                <div className="invalid-feedback">{formik.errors.password}</div>
                : null}
            </div>
          </div>
          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label text-alert">
              Şifre Kontrol
            </label>
            <div className="col-lg-9 col-xl-6">
              <input
                type="password"
                placeholder="Şifre Kontrol"
                className={`form-control form-control-lg form-control-solid ${getInputClasses(
                  "cPassword"
                )}`}
                name="cPassword"
                {...formik.getFieldProps("cPassword")}
              />
              {formik.touched.cPassword && formik.errors.cPassword ?
                <div className="invalid-feedback">
                  {formik.errors.cPassword}
                </div>
                : null}
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default ChangePassword;
