import React, { useEffect, useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { ProductAttributeValueForm } from "./index";
import { useProductAttributeValueUIContext } from '../UIContext';
import { get, post, put } from '../../../helpers';


const ProductAttributeValueAdd = ({ id, show, onHide }) => {
  const [title, setTitle] = useState('');
  const [productAttributeValueForEdit, setProductAttributeValueForEdit] = useState(undefined);
  const productAttributeValueUIContext = useProductAttributeValueUIContext();
  const { queryParams, initProductAttributeValue } = productAttributeValueUIContext;
  const productAttributeValueUIProps = useMemo(() => {
    return {
      setError: productAttributeValueUIContext.setError,
      setState: productAttributeValueUIContext.setState,
    };
  }, [productAttributeValueUIContext]);


  useEffect(() => {
    const getProductAttributeValue = async () => {
      const { product_attribute_value } = await get('/product_attribute/admin/value/'+id);
      console.log('product_attribute_value', product_attribute_value);
      setProductAttributeValueForEdit(product_attribute_value);
    };
    if(id){
      getProductAttributeValue();
    }
  }, [id]);

  useEffect(() => {
    let _title = id ? "" : "Yeni Özellik Değeri";
    if (productAttributeValueForEdit && id) {
      _title = `${productAttributeValueForEdit.name} Düzenle`;
    }

    setTitle(_title);
  }, [productAttributeValueForEdit]);

  const saveProductAttributeValue = async (value, closeWindow) => {
    if (!id) {
      const { status, message } = await post('/product_attribute/admin/value/add', value);
      if(!status){
        productAttributeValueUIProps.setError(message);
        if (!closeWindow) onHide();
        return;
      }
    } else {
      const { status, message } = await put('/product_attribute/admin/value/edit/'+id, value);
      if(!status){
        productAttributeValueUIProps.setError(message);
        if (!closeWindow) onHide();
        return;
      }
    }
    const { product_attribute_values:productAttributeValues,status, message, total_count } = await post('/product_attribute/admin/value/list', { ...queryParams });
    if(!status){
      productAttributeValueUIProps.setError(message);
      return;
    }
    await productAttributeValueUIProps.setState({ productAttributeValues, total_count });
    if (!closeWindow) onHide();
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <ProductAttributeValueForm
        saveProductAttributeValue={saveProductAttributeValue}
        productAttributeValue={productAttributeValueForEdit || initProductAttributeValue}
        onHide={onHide}
      />
    </Modal>
  );
};
export default ProductAttributeValueAdd;
