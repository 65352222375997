import React, { useEffect, useMemo } from "react";
import {
  getHandlerTableChange,
  sortCaret,
  headerSortingClasses, post,
} from '../../../helpers';
import * as uiHelpers from "../UiHelpers";
import { useProductTypeUIContext } from '../UIContext';
import { StockTypeColumnFormatter } from './column-formatters';
import { useHistory } from "react-router-dom";
import { DataTable, DefaultActionsColumnFormatter, DefaultStatusColumnFormatter } from "../../../components";

const ProductTypeTable = () => {
  const { push } = useHistory();
  const UIContext = useProductTypeUIContext();
  const openEditDialog = (id) => {
    push(`/product_management/product_types/${id}/edit`);
  };
  const openDeleteDialog = (id) => {
    push(`/product_management/product_types/${id}/delete`);
  };
  const UIProps = useMemo(() => {
    return {
      setQueryParams: UIContext.setQueryParams,
      setState: UIContext.setState,
      setError: UIContext.setError,
      setListLoading: UIContext.setListLoading,
    };
  }, [UIContext]);

  const { state: { total_count, product_types }, listLoading, queryParams } = UIContext;
  useEffect(() => {
    const getProductTypes = async () => {
      UIProps.setListLoading(true);
      const { product_types, status, message, total_count } = await post('/product_type/admin/list', { ...queryParams });
      UIProps.setListLoading(false);
      if(!status){
        UIProps.setError(message);
        return;
      }
      UIProps.setError(null);
      UIProps.setState({ product_types, total_count });
    };
    getProductTypes();
    return () => {
      UIProps.setError(null);
    };
  }, [queryParams]);
  // Table columns
  const columns = [
    {
      dataField: "id",
      text: "ID",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      style: {
        width: "40px",
      },
    },
    {
      dataField: "name",
      text: "Ürün Türü",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "attribute_labels",
      text: "Özellikler",
    },
    {
      dataField: "stock_type",
      text: "Stok Türü",
      formatter: StockTypeColumnFormatter,
    },
    {
      dataField: "variant_labels",
      text: "Varyantlar",
    },
    {
      dataField: "is_active",
      text: "Durum",
      formatter: DefaultStatusColumnFormatter,
      classes: "text-center",
      headerClasses: "text-center",
      style: {
        width: "60px",
      },
    },
    {
      dataField: "action",
      text: "İşlem",
      formatter: DefaultActionsColumnFormatter,
      formatExtraData: {
        openEditDialog,
        openDeleteDialog,
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        width: "100px",
      },
    },
  ];
  const paginationOptions = {
    custom: true,
    totalSize: total_count,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: queryParams.pageSize,
    page: queryParams.pageNumber,
  };

  return (
    <DataTable
      data={product_types || []}
      defaultSorted={uiHelpers.defaultSorted}
      columns={columns}
      paginationOptions={paginationOptions}
      handleTableChange={getHandlerTableChange(
        UIContext.setQueryParams
      )}
      listLoading={listLoading} />
  );
};
export default ProductTypeTable;
