import React from "react";

const ServerStatusColumnFormatter = (cellContent, row, rowIndex, { updateOrder }) => {

  const _updateOrder = () => updateOrder(row.id);
  return (
    <React.Fragment>
      <a title="Yenile" onClick={_updateOrder} className="btn btn-icon btn-clean btn-xs btn-hover-warning">
        <i className="flaticon2-refresh" />
      </a>
    </React.Fragment>
  );
};
export default ServerStatusColumnFormatter;
