import React from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Link } from "react-router-dom";

const ChatBar = ({ conversations, _handleChange }) => {
  return(
    <div className="flex-row-auto offcanvas-mobile w-350px w-xl-400px offcanvas-mobile-on" id="kt_chat_aside">
      <div className="card card-custom">
        <div className="card-body">
          <div className="input-group input-group-solid">
            <input onChange={_handleChange} type="text" className="form-control py-4 h-auto" placeholder="Email" />
          </div>
          <PerfectScrollbar options={{ suppressScrollX:false }}>
            <div className="mt-7" style={{ height: '580px', overflow: 'hidden' }}>
              {conversations.length > 0 && conversations.map((item, index) => {
                const { unread_msg_count, user } = item;
                return(
                  <div key={index} className="d-flex align-items-center justify-content-between mb-5">
                    <div className="d-flex align-items-center">
                      <div className="symbol symbol-circle symbol-50 mr-3">
                        <img alt="Pic" src={user.image} />
                      </div>
                      <div className="d-flex flex-column">
                        <Link to={'/user_management/messages/'+user.id} className="text-dark-75 text-hover-primary font-weight-bold font-size-lg">{user.full_name}</Link>
                        <span className="text-muted font-weight-bold font-size-sm">{user.company.name}</span>
                      </div>
                    </div>
                    <div className="d-flex flex-column align-items-end">
                      {unread_msg_count && <span className="label label-sm label-danger">{unread_msg_count}</span>}
                    </div>
                  </div>
                );
              })}
            </div>
          </PerfectScrollbar>
        </div>
      </div>
    </div>
  );
};
export default ChatBar;