import React, { useEffect } from "react";
import { get } from "../../helpers";
import {
  CompanyProfile,
  CompanyOverView,
  CompanyPayment,
} from "./information";
import { Redirect, Route, Switch } from "react-router-dom";
import { useCompanyUIContext } from "./UIContext";
import { CompanyOrderTable } from "./information/company-order-table";
import { CompanyAddressTable } from "./information/company-address-table";
import { CompanyUserTable } from "./information/company-user-table";
import { CompanyPriceTable } from "./information/company-price-table";


const CompanyInformation = ({ company_id }) => {
  const { company , setCompany } = useCompanyUIContext();
  const CompanyAddresses_ = () => <CompanyAddressTable company_id={company?.id} />;
  const CompanyOrders_ = () => <CompanyOrderTable company_id={company?.id} />;
  const CompanyUsers_ = () => <CompanyUserTable company_id={company?.id} />;
  const CompanyPrice_ = () => <CompanyPriceTable company_id={company?.id} />;
  const CompanyPayment_ = () => <CompanyPayment company={company && company} />;

  const getCompany = async () => {
    if(company_id){
      const { company } = await get('/company/admin/' + company_id);
      await setCompany(company);
    }
  };

  useEffect(() => {
    getCompany();
  }, [company_id]);

  return (
    <React.Fragment>
      <div className="d-flex flex-row">
        {company && <CompanyProfile company={company} getCompany={getCompany}/>}
        <div className="flex-row-fluid ml-lg-8">
          <Switch>
            <Redirect
              from="/seller_management/companies/:id"
              exact={true}
              to="/seller_management/companies/:id/overview"
            />
            <Route
              path="/seller_management/companies/:id/overview"
              component={CompanyOverView}
            />
            <Route
              path="/seller_management/companies/:id/orders"
              component={CompanyOrders_}
            />
            <Route
              path="/seller_management/companies/:id/address"
              component={CompanyAddresses_}
            />
            <Route
              path="/seller_management/companies/:id/users"
              component={CompanyUsers_}
            />
            <Route
              path="/seller_management/companies/:id/prices"
              component={CompanyPrice_}
            />
            <Route
              path="/seller_management/companies/:id/payment"
              component={CompanyPayment_}
            />
          </Switch>
        </div>
      </div>
    </React.Fragment>
  );
};
export default CompanyInformation;
