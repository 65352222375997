import React from "react";
import { SubCategoryUIProvider } from './UIContext';
import { Route, useHistory } from 'react-router-dom';
import SubCategoryCard from './SubCategoryCard';
import { SubCategoryAdd, SubCategoryDeleteDialog } from './dialog';

const SubCategoryPage = () => {
  const { push } = useHistory();
  const _onHide = () => push("/content/sub_categories");
  const _handleAddModal = ({ match }) => <SubCategoryAdd show={match != null} onHide={_onHide}/>;
  const _handleEditModal = ({ match }) => <SubCategoryAdd show={match != null} id={match && match.params.id} onHide={_onHide}/>;
  const _handleDeleteModal = ({ match }) => <SubCategoryDeleteDialog show={match != null} id={match && match.params.id} onHide={_onHide} />;

  return (
    <SubCategoryUIProvider>
      <Route path="/content/sub_categories/new" render={_handleAddModal} />
      <Route path="/content/sub_categories/:id/edit" render={_handleEditModal} />
      <Route path="/content/sub_categories/:id/delete" render={_handleDeleteModal}/>
      <SubCategoryCard />
    </SubCategoryUIProvider>
  );
};
export default SubCategoryPage;
