import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { useProductAttributeUIContext } from '../UIContext';
import { delete_, post } from '../../../helpers';
import DialogModalFooter from "../../../components/DialogModalFooter";

const ProductAttributeDeleteDialog = ({ id, show, onHide }) => {
  const productAttributeUIContext = useProductAttributeUIContext();
  const productAttributeUIProps = useMemo(() => {
    return {
      setError: productAttributeUIContext.setError,
      setState: productAttributeUIContext.setState
    };
  }, [productAttributeUIContext]);

  const { queryParams } = productAttributeUIContext;

  useEffect(() => {
    if (!id) {
      onHide();
    }
  }, [id]);


  const deleteProductAttribute = async () => {
    const { status, message } = await delete_(`/product_attribute/admin/delete/${id}`);
    if(!status){
      productAttributeUIProps.setError(message);
      onHide();
      return;
    }
    const { product_attributes:productAttributes, status:_status, message:_message, total_count } = await post('/product_attribute/admin/list', { ...queryParams });
    if(!_status){
      productAttributeUIProps.setError(_message);
      return;
    }
    await productAttributeUIProps.setState({ productAttributes, total_count });
    onHide();
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
    >
      <Modal.Header closeButton>
        <Modal.Title>
            Durum Güncelle
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span>Seçtiğiniz özelliğin durumu değiştirilecek. Devam etmek istiyor musunuz?</span>
      </Modal.Body>
      <DialogModalFooter type={'delete'} handleSubmit={deleteProductAttribute} onHide={onHide}/>
    </Modal>
  );
};
export default ProductAttributeDeleteDialog;
