import React from 'react';
import BootstrapTable from "react-bootstrap-table-next";
import { headerSortingClasses, NoRecordsFoundMessage, sortCaret } from "../../../helpers";
import { LogoColumnFormatter } from "../../ProductSkus/product-sku-table/column-formatters";
import { DefaultStatusColumnFormatter } from "../../../components";
import NameColumnFormatter from "./column-formatters/NameColumnFormatter";

const InformationStock = ({ data }) => {
  const columns = [
    {
      dataField: "id",
      text: "ID",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      style: {
        width: "60px",
      },
    },
    {
      dataField: "image",
      text: "",
      sort: false,
      sortCaret: sortCaret,
      formatter: LogoColumnFormatter,
      headerSortingClasses,
      style: {
        width: "100px",
      },
    },
    {
      dataField: "name",
      text: "Ürün Adı",
      sort: false,
      sortCaret: sortCaret,
      formatter: NameColumnFormatter,
      headerSortingClasses,
    },
    {
      dataField: "stock.type",
      text: "Tür",
      sort: false,
      sortCaret: sortCaret,
    },
    {
      dataField: "stock.quantity",
      text: "Stok",
      sort: false,
      sortCaret: sortCaret,
    },
    {
      dataField: "status",
      text: "Durum",
      sort: false,
      sortCaret: sortCaret,
      formatter: DefaultStatusColumnFormatter,
      headerSortingClasses,
      classes: "text-center pr-0",
      headerClasses: "text-center pr-0",
      style: {
        width: "80px",
      },
    },
  ];
  return(
    <BootstrapTable
      remote
      keyField="id"
      wrapperClasses="table-responsive"
      classes="table table-head-custom table-head-bg table-vertical-center overflow-hidden"
      loading={ false }
      bordered={false}
      data={data ?? []}
      columns={columns}
      noDataIndication={ <NoRecordsFoundMessage /> } />
  );
};
export default InformationStock;
