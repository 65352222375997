import React from "react";
import { ProductAttributeUIProvider } from './UIContext';
import { Route, useHistory } from 'react-router-dom';
import ProductAttributeCard from './ProductAttributeCard';
import { ProductAttributeAdd, ProductAttributeDeleteDialog } from './dialog';

const ProductAttributePage = () => {
  const { push } = useHistory();
  const _onHide = () => push("/product_management/attributes");
  const _handleAddModal = ({ match }) => <ProductAttributeAdd show={match != null} onHide={_onHide}/>;
  const _handleEditModal = ({ match }) => <ProductAttributeAdd show={match != null} id={match && match.params.id} onHide={_onHide}/>;
  const _handleDeleteModal = ({ match }) => <ProductAttributeDeleteDialog show={match != null} id={match && match.params.id} onHide={_onHide} />;

  return (
    <ProductAttributeUIProvider>
      <Route path="/product_management/attributes/new" render={_handleAddModal} />
      <Route path="/product_management/attributes/:id/edit" render={_handleEditModal} />
      <Route path="/product_management/attributes/:id/delete" render={_handleDeleteModal}/>
      <ProductAttributeCard />
    </ProductAttributeUIProvider>
  );
};
export default ProductAttributePage;
