import React, { useEffect, useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { CompanyForm } from './index';
import { useCompanyUIContext } from '../UIContext';
import { get, post, put } from '../../../helpers';


const CompanyAdd = ({ id, show, onHide }) => {
  const [title, setTitle] = useState('');
  const [companyForEdit, setCompanyForEdit] = useState(undefined);
  const companyUIContext = useCompanyUIContext();
  const { queryParams, initCompany } = companyUIContext;

  const companyUIProps = useMemo(() => {
    return {
      setError: companyUIContext.setError,
      setState: companyUIContext.setState,
    };
  }, [companyUIContext]);

  const getCompany = async () => {
    if(id){
      const { company } = await get('company/'+id);
      setCompanyForEdit(company);
    }
  };

  useEffect(() => {
    getCompany();
  }, [id]);

  useEffect(() => {
    let _title = id ? "" : "Yeni Marka";
    if (companyForEdit && id) {
      _title = `${companyForEdit.name} Düzenle`;
    }
    setTitle(_title);
  }, [companyForEdit]);


  const saveCompany = async (company, closeWindow) => {
    if (!id) {
      const { status, message } = await post('company/add', company);
      if(!status){
        companyUIProps.setError(message);
        if (!closeWindow) onHide();
        return;
      }
    } else {
      const { status, message } = await put('company/'+id, company);
      if(!status){
        companyUIProps.setError(message);
        if (!closeWindow) onHide();
        return;
      }
    }
    const { companies, status, message, total_count } = await post('/company/list', { ...queryParams });
    if(!status){
      companyUIProps.setError(message);
      return;
    }
    companyUIProps.setError(null);
    await companyUIProps.setState({ companies, total_count });
    if (!closeWindow) onHide();
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <CompanyForm
        saveCompany={saveCompany}
        company={companyForEdit || initCompany}
        onHide={onHide}
      />
    </Modal>
  );
};
export default CompanyAdd;
