import React from "react";
import { OrderUIProvider } from './UIContext';
import OrderCard from './OrderCard';
import { ContentRoute } from "../../components";
import OrderInformation from "./OrderInformation";
import { Switch } from "react-router-dom";

const OrderPage = () => {
  const OrderInfo = ({ match }) => <OrderInformation id={match && match.params.id}/>;
  return (
    <OrderUIProvider>
      <Switch>
        <ContentRoute path="/order_management/orders/:id/information" component={OrderInfo} />
        <OrderCard />
      </Switch>
    </OrderUIProvider>
  );
};
export default OrderPage;
