import React, { useEffect, useState } from 'react';
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import { Typeahead } from 'react-bootstrap-typeahead';
import * as Yup from "yup";
import { Input } from '../../../components/common/controls';
import { get, post } from '../../../helpers';
import DialogModalFooter from "../../../components/DialogModalFooter";


const BrandSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, "En az 3 karakter")
    .max(50, "En fazla 50 karakter")
    .required("Marka adı gerekli"),
});

const BrandForm = ({ saveBrand, brand, onHide }) => {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [closeWindow, setCloseWindow] = useState(false);
  const handleCloseWindow = () => {
    setCloseWindow(!closeWindow);
  };
  const getCategories = async () => {
    const { categories } = await post('/category/admin/list', { });
    setCategories(categories);
  };
  useEffect(()=>{
    const getCategory = async (id) => {
      const { category } = await get('/category/admin/'+id);
      setSelectedCategory([category]);
    };
    if(brand.parent_id !== undefined && brand.parent_id !== ''){
      getCategory(brand.parent_id);
    }
  },[brand]);

  useEffect(() => {getCategories();}, []);

  const _handleSubmit = (values, { resetForm }) => {
    saveBrand(values, closeWindow);
    setSelectedCategory([]);
    resetForm({});
  };

  return (
    <React.Fragment>
      <Formik enableReinitialize={true} initialValues={brand} validationSchema={BrandSchema} onSubmit={_handleSubmit}>
        {({ handleSubmit, setFieldValue, values }) =>
        {
          const _handleCategoryChange = (item)=> {
            if(item.length > 0){
              setSelectedCategory(item);
              setFieldValue('parent_id', item[0].id);
            }
          };
          return (
            <React.Fragment>
              <Modal.Body className="overlay overlay-block cursor-default">
                <Form className="form form-label-right">
                  <div className="form-group row">
                    <div className="col-lg-12">
                      <label>Ana Kategori</label>
                      <Typeahead
                        id={'brandSearch'}
                        labelKey={'name'}
                        onChange={_handleCategoryChange}
                        options={categories}
                        placeholder={'Ana Kategori'}
                        selected={selectedCategory}
                      />
                    </div>
                  </div>
                  <Field
                    name="name"
                    component={Input}
                    placeholder="Marka Adı"
                    label="Marka Adı"
                  />
                </Form>
              </Modal.Body>
              <DialogModalFooter handleSubmit={handleSubmit} onHide={onHide} handleCloseWindow={handleCloseWindow}/>
            </React.Fragment>
          );}
        }
      </Formik>
    </React.Fragment>
  );
};
export default BrandForm;
