import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { ModalProgressBar } from "../../../components/common/controls";
import { useUserUIContext } from "../UIContext";
import { get, post, put } from "../../../helpers";
import Select from "react-select";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { toast } from "react-toastify";

const Role = ({ role_ids, id, company }) =>{
  const { userForEdit:user, queryParams } = useUserUIContext();
  const [me, setMe] = useState(undefined);
  const [roles, setRoles] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState([]);
  const [addresses, setAddresses] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState([]);

  const getMe = async () => {
    const { user } = await get('/auth/admin/me');
    setMe(user);
  };

  const saveRoles = async () => {
    setLoading(true);
    const credentials = {
      roles: selectedRoles
    };
    if (checkAdmin() || checkSeller()) {
      credentials['company_id'] = selectedCompany[0].id;
    }
    if (selectedCompany.length > 0 && checkSeller()) {
      credentials['addresses'] = selectedAddress.map(item => item.value);
    }
    const { status } = await put('/user/admin/edit/role/' + id, credentials);
    if (status) {
      toast.success('Güncelleme başarılı.');
    }
    setLoading(false);
  };

  const handleAddressSearch = async (query) => {
    const newQueryParams = { ...queryParams };
    const filter = {};
    filter.company_id = selectedCompany[0].id;
    filter.type = 0;
    if (query) {
      filter.name = query;
    }
    newQueryParams.filter = filter;
    await getAddresses(newQueryParams);
  };

  const getAddresses = async (params) => {
    setLoading(true);
    const { addresses } = await post('/address/admin/list', { ...params });
    const _addresses = addresses.map(item => {
      return { value: item.id, label: item.name };
    });
    setAddresses(_addresses);
    setLoading(false);

  };

  const getRoles = async () => {
    setLoading(true);
    const { roles } = await post('/role/admin/list', {});
    const roles_ = roles.map(item => {
      return { value: item.id, label: item.name };
    });
    setRoles(roles_);
    setLoading(false);
  };

  const checkAdmin = () => {
    return selectedRoles.indexOf(3) > -1;
  };
  const checkSeller = () => {
    return selectedRoles.indexOf(4) > -1;
  };

  const _handleRoleSelectChange = (item) => {
    setSelectedRoles(item.map(el => {
      return el.value;
    }));
  };

  const getCompanies = async (params) => {
    setLoading(true);
    const { companies } = await post('/company/list', { ...params });
    setCompanies(companies);
    setLoading(false);
  };

  const handleSearch = async (query) => {
    const newQueryParams = { ...queryParams };
    const filter = {};
    if (query) {
      filter.name = query;
    }
    newQueryParams.filter = filter;
    await getCompanies(newQueryParams);
  };

  const filterBy = () => true;

  const getAddress = async () => {
    const credentials = {
      filter: { user_id:id, type: 0 },
      pageNumber: 1,
      pageSize: 10,
      sortField: "id",
      sortOrder: "asc",
    };
    const { addresses } = await post('/address/admin/list', credentials);
    const _addresses = addresses.map(item => {
      return { value: item.id, label: item.name };
    });
    setSelectedAddress(_addresses);
  };

  useEffect(()=> {
    setSelectedRoles(role_ids);
    setSelectedCompany([company]);
    getRoles();
    getAddress();
    getMe();
  },[]);

  return (
    <div className="card card-custom">
      {loading && <ModalProgressBar />}
      <div className="card-header py-3">
        <div className="card-title align-items-start flex-column">
          <h3 className="card-label font-weight-bolder text-dark">
            Rol Bilgileri
          </h3>
          <span className="text-muted font-weight-bold font-size-sm mt-1">
            Kullanıcı rolünü güncelleyin
          </span>
        </div>
        <div className="card-toolbar">
          <button
            type="submit"
            className="btn btn-success mr-2"
            onClick={saveRoles}
            disabled={selectedRoles.length === 0}
          >
            Güncelle
          </button>
          <Link to={'/user_management/users/'+user.id+'/overview'} className="btn btn-secondary">İptal</Link>
        </div>
      </div>
      {
        roles.length > 0 && me &&
        <div className="form">
          <div className="card-body">
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label text-alert">
                Rol
              </label>
              <div className="col-lg-9 col-xl-6">
                <Select
                  isDisabled={me.id === id}
                  onChange={_handleRoleSelectChange}
                  options={roles}
                  placeholder={'Rol'}
                  isMulti
                  name="roles"
                  className="basic-multi-select"
                  classNamePrefix="select"
                  defaultValue={role_ids.length > 0 ?
                    roles.filter(role => role_ids.some(item => item === role.value)) : []
                  }
                />
              </div>
            </div>

            {
              (checkAdmin() || checkSeller()) &&
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label text-alert">
                  Firma
                </label>
                <div className="col-lg-9 col-xl-6">
                  <AsyncTypeahead
                    filterBy={filterBy}
                    id={'companySearch'}
                    isLoading={loading}
                    labelKey="name"
                    minLength={3}
                    onChange={setSelectedCompany}
                    onSearch={handleSearch}
                    options={companies}
                    selected={selectedCompany}
                    placeholder={'Firma*'}
                  />
                </div>
              </div>
            }
            {
              selectedCompany.length > 0 && checkSeller() &&
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label text-alert">
                  Adres
                </label>
                <div className="col-lg-9 col-xl-6">
                  <AsyncTypeahead
                    filterBy={filterBy}
                    id={'addressSearch'}
                    isLoading={loading}
                    labelKey="label"
                    minLength={3}
                    onChange={setSelectedAddress}
                    onSearch={handleAddressSearch}
                    options={addresses}
                    selected={selectedAddress}
                    placeholder={'Adres'}
                    multiple
                  />
                </div>
              </div>
            }

          </div>
        </div>
      }
    </div>
  );
};

export default Role;
