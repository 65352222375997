import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader, ModalProgressBar,
} from '../../components/common/controls';
import { get, NoRecordsFoundMessage } from "../../helpers";
import { Col, Container, Row } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import {
  LogoColumnFormatter,
  NameColumnFormatter,
} from "../ProductSkus/product-sku-table/column-formatters";
import { ItemStatusColumnFormatter } from "./order-table/column-formatters";
import { AddressColumnFormatter } from "../Addresses/address-table/column-formatters";
import { ImageColumnFormatter } from "../Companies/company-table/column-formatters";
import { NameColumnFormatter as UserNameColumnFormatter } from "../Users/user-table/column-formatters";
import { OrderStatusClasses, OrderStatusTitles } from "./UiHelpers";

const OrderInformation = ({ id }) => {
  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState("general");
  const [data, setData] = useState({});

  const moveToGeneralTab = () => setTab("general");
  const moveToProductTab = () => setTab("product");
  const moveToAddressTab = () => setTab("address");
  const moveToCompanyTab = () => setTab("company");
  const moveToUserTab = () => setTab("user");

  const getOrder = async () => {
    if (id) {
      setLoading(true);
      const { order } = await get('order/' + id);
      setData(order);
      setLoading(false);

    }
  };

  useEffect(() => {
    getOrder();
  }, []);
  const orderItemColumns = [
    {
      dataField: "id",
      text: "ID",
      style: {
        width: "60px",
      },
    },
    {
      dataField: "image",
      text: "",
      formatter: LogoColumnFormatter,
      style: {
        width: "100px",
      },
    },
    {
      dataField: "name",
      text: "Ürün Adı",
      formatter: NameColumnFormatter,
    },
    {
      dataField: "type.name",
      text: "Tür",
      classes: "text-center pr-0",
      headerClasses: "text-center pr-0",
    },
    {
      dataField: "brand.name",
      text: "Marka",
      classes: "text-center pr-0",
      headerClasses: "text-center pr-0",
    },
    {
      dataField: "quantity",
      text: "Adet",
      classes: "text-center pr-0",
      headerClasses: "text-center pr-0",
      style: {
        width: "60px",
      },
    },
    {
      dataField: "cost",
      text: "Tutar",
      classes: "text-center pr-0",
      headerClasses: "text-center pr-0",
      style: {
        width: "60px",
      },
    },
    {
      dataField: "status.name",
      text: "Durum",
      classes: "text-center pr-0",
      headerClasses: "text-center pr-0",
      formatter: ItemStatusColumnFormatter,
      style: {
        width: "150px",
      },
    },

  ];
  const addressColumn = [
    {
      dataField: "id",
      text: "ID",
      sort: true,
      style: {
        width: "40px",
      },
    },
    {
      dataField: "title",
      text: "",
      sort: true,
    },
    {
      dataField: "name",
      text: "Adres",
      formatter: AddressColumnFormatter,
    },
    {
      dataField: "phone1",
      text: "Tel",
    },
    {
      dataField: "city.name",
      text: "İl",
    },
    {
      dataField: "county.name",
      text: "İlçe",
    }
  ];
  const companyColumn = [
    {
      dataField: "id",
      text: "ID",
      style: {
        width: "40px",
      },
    },
    {
      dataField: "image",
      text: "",
      classes: "text-center",
      formatter: ImageColumnFormatter,
      headerClasses: "text-center",
      style: {
        width: "60px",
      },
    },
    {
      dataField: "name",
      text: "Firma",
    }
  ];
  const userColumn= [
    {
      dataField: "id",
      text: "ID",
      style: {
        width: "50px",
      },
    },
    {
      dataField: "image",
      text: "",
      formatter: ImageColumnFormatter,
      classes: "text-center",
      headerClasses: "text-center",
      style: {
        width: "60px",
      },
    },
    {
      dataField: "full_name",
      text: "Adı Soyadı",
      formatter: UserNameColumnFormatter,

    },
    {
      dataField: "email",
      text: "Email",
    },
    {
      dataField: "gsm",
      text: "Gsm",
    },
  ];
  return (
    <React.Fragment>
      {loading && <ModalProgressBar />}
      <Card>
        <CardHeader title={'Sipariş Bilgileri'}/>
        <CardBody style={{ padding:'1rem 2rem' }}>
          {
            data &&
              <React.Fragment>
                <ul className="nav nav-pills " role="tablist">
                  <li className="nav-item" onClick={moveToGeneralTab}>
                    <a
                      className={`nav-link ${tab === "general" && "active bg-info"}`}
                      data-toggle="tab"
                      role="tab"
                      aria-selected={(tab === "general").toString()}
                    >
                                            Genel Bilgiler
                    </a>
                  </li>
                  <li className="nav-item" onClick={moveToProductTab}>
                    <a
                      className={`nav-link ${tab === "product" && "active bg-info"}`}
                      data-toggle="tab"
                      role="tab"
                      aria-selected={(tab === "product").toString()}
                    >
                                            Ürün Bilgileri
                    </a>
                  </li>
                  <li className="nav-item" onClick={moveToAddressTab}>
                    <a
                      className={`nav-link ${tab === "address" && "active bg-info"}`}
                      data-toggle="tab"
                      role="tab"
                      aria-selected={(tab === "address").toString()}
                    >
                                            Adres Bilgisi
                    </a>
                  </li>

                  <li className="nav-item" onClick={moveToCompanyTab}>
                    <a
                      className={`nav-link ${tab === 'company' && 'active bg-info'}`}
                      data-toggle="tab"
                      role="button"
                      aria-selected={(tab === 'company').toString()}
                    >
                                            Firma
                    </a>
                  </li>
                  <li className="nav-item" onClick={moveToUserTab}>
                    <a
                      className={`nav-link ${tab === 'user' && 'active bg-info'}`}
                      data-toggle="tab"
                      role="button"
                      aria-selected={(tab === 'user').toString()}
                    >
                                            Kullanıcı
                    </a>
                  </li>
                </ul>
                <div style={{ minHeight: 250 }} className="mt-5">
                  <React.Fragment>
                    {tab === "general" &&
                                        <Container>
                                          <Row>
                                            <Col sm={2}><p className={'text-black-50'}>Sipariş Kodu:</p></Col>
                                            <Col sm={6}>{data.key}</Col>
                                          </Row>
                                          <Row>
                                            <Col sm={2}><p className={'text-black-50'}>Tarih:</p></Col>
                                            <Col sm={6}>{data.date}</Col>
                                          </Row>
                                          <Row>
                                            <Col sm={2}><p className={'text-black-50'}>Ürün Çeşidi:</p></Col>
                                            <Col sm={6}>{data.total_count}</Col>
                                          </Row>
                                          <Row>
                                            <Col sm={2}><p className={'text-black-50'}>Ürün Sayısı:</p></Col>
                                            <Col sm={6}>{data.total_quantity}</Col>
                                          </Row>
                                          <Row>
                                            <Col sm={2}><p className={'text-black-50'}>Fiyat:</p></Col>
                                            <Col sm={6}>{data.total_cost}</Col>
                                          </Row>
                                          <Row>
                                            <Col sm={2}><p className={'text-black-50'}>Durum:</p></Col>
                                            <Col sm={6}>
                                              <span className={`label label-light-${
                                                OrderStatusClasses[data.status]
                                              } label-inline`}>{OrderStatusTitles[data.status]}</span>
                                            </Col>
                                          </Row>
                                        </Container>
                    }
                  </React.Fragment>
                  <React.Fragment>
                    {tab === "product" && data.products &&
                    <BootstrapTable
                      keyField={'item_id'}
                      wrapperClasses="table-responsive"
                      classes="table table-head-custom table-head-bg table-vertical-center overflow-hidden"
                      bordered={false}
                      data={ data.products || []}
                      columns={orderItemColumns}
                    />
                    }
                  </React.Fragment>
                  <React.Fragment>
                    {tab === "address" &&
                    <BootstrapTable
                      keyField={'id'}
                      wrapperClasses="table-responsive"
                      classes="table table-head-custom table-head-bg table-vertical-center overflow-hidden"
                      bordered={false}
                      data={ [data.address] || []}
                      columns={addressColumn}
                    />
                    }
                  </React.Fragment>
                  <React.Fragment>
                    {tab === "company" && data.buyer &&
                    <BootstrapTable
                      keyField={'id'}
                      wrapperClasses="table-responsive"
                      classes="table table-head-custom table-head-bg table-vertical-center overflow-hidden"
                      bordered={false}
                      data={ [data.buyer.company] || []}
                      columns={companyColumn}
                    />
                    }
                  </React.Fragment>
                  <React.Fragment>
                    {tab === "user" && data.buyer &&
                    <BootstrapTable
                      keyField={'id'}
                      wrapperClasses="table-responsive"
                      classes="table table-head-custom table-head-bg table-vertical-center overflow-hidden"
                      bordered={false}
                      data={ data.buyer.user ? [data.buyer.user] : []}
                      columns={userColumn}
                      noDataIndication={ () => <NoRecordsFoundMessage /> } />
                    }
                  </React.Fragment>

                  <React.Fragment>
                    {tab === "status" &&
                                        <Container>
                                          <Row>
                                            <Col sm={2}><p className={'text-black-50'}>Ödendi:</p></Col>
                                            <Col sm={6}>{data.is_paid ? 'Evet' : 'Hayır'}</Col>
                                          </Row>
                                          <Row>
                                            <Col sm={2}><p className={'text-black-50'}>Tamamlandı:</p></Col>
                                            <Col sm={6}>{data.is_completed ? 'Evet' : 'Hayır'}</Col>
                                          </Row>
                                          <Row>
                                            <Col sm={2}><p className={'text-black-50'}>İptal Edildi:</p></Col>
                                            <Col sm={6}>{data.is_cancelled ? 'Evet' : 'Hayır'}</Col>
                                          </Row>
                                        </Container>
                    }
                  </React.Fragment>
                </div>
              </React.Fragment>
          }
        </CardBody>
      </Card>
    </React.Fragment>
  );
};
export default OrderInformation;
