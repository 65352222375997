import React, { useMemo, useState } from "react";
import { Formik } from "formik";
import Select from 'react-select';
import { isEqual } from "lodash";
import { useOrderUIContext } from '../UIContext';
import Dropdown from "react-bootstrap/Dropdown";
import AsyncSelect from "react-select/async/dist/react-select.esm";
import { get, post } from "../../../helpers";
import { initialFilter } from "../UiHelpers";
import { orderTypes } from "../../../constant/helper";
import { toast } from "react-toastify";

const prepareFilter = (queryParams, values) => {
  const { status, searchText, company_id, sentStatus, user_id } = values;
  const newQueryParams = { ...queryParams };
  const filter = {};
  if (status) filter.status = status;
  if (company_id) filter.company_id = company_id;
  if (sentStatus) filter.is_sent = sentStatus;
  if (sentStatus) filter.user_id = user_id;
  if (searchText) {
    filter.key = searchText;
  }
  newQueryParams.filter = filter;
  return newQueryParams;
};

const OrderFilter = () => {
  const [companies, setCompanies] = useState([]);
  const [users, setUsers] = useState([]);
  const [companySearch, setCompanySearch] = useState('');
  const [userSearch, setUserSearch] = useState('');
  const orderUiContext = useOrderUIContext();
  const orderUIProps = useMemo(() => {
    return {
      queryParams: orderUiContext.queryParams,
      setQueryParams: orderUiContext.setQueryParams,
    };
  }, [orderUiContext]);

  const applyFilter = (values) => {
    const newQueryParams = prepareFilter(orderUIProps.queryParams, values);
    if (!isEqual(newQueryParams, orderUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      orderUIProps.setQueryParams(newQueryParams);
    }
  };
  const initialValues = {
    status: "1",
    searchText: "",
  };
  const statusOptions = [
    { value: '', label: 'Tümü' },
    { value: '1', label: 'Aktif' },
    { value: '0', label: 'Pasif' }
  ];
  const sentStatusOptions = [
    { value: '', label: 'Tümü' },
    { value: '1', label: 'Gönderilen' },
    { value: '0', label: 'Gönderilmeyen' }
  ];
  const _handleSubmit = (values) => applyFilter(values);

  const filterUser = async (inputValue) => {
    const credentials = initialFilter;
    credentials.filter.full_name = inputValue;
    const { users } = await post('/user/list/', credentials);
    const _users = users.map(item => {
      return { value: item.id, label: item.full_name };
    });
    setUsers(_users);
    return _users;
  };

  const loadOptionsUser = async (
    inputValue,
    callback
  ) => {
    const _data = await filterUser(inputValue);
    callback(_data);
  };

  const filterCompany = async (inputValue) => {
    const credentials = initialFilter;
    credentials.filter.name = inputValue;
    const { companies } = await post('/company/list', credentials);
    const _companies = companies.map(item => {
      return { value: item.id, label: item.name };
    });
    setCompanies(_companies);
    return _companies;
  };

  const loadOptionsCompany = async (
    inputValue,
    callback
  ) => {
    const _data = await filterCompany(inputValue);
    callback(_data);
  };
  const updateAllOrder = async () => {
    const { status, message } = await get('/order/admin/update/all');
    if(!status){
      toast.info(message);
      return;
    }
    toast.success('Güncelleme isteği alındı!');
  };
  return (
    <Formik initialValues={initialValues} onSubmit={_handleSubmit}>
      {({ values, handleSubmit, handleBlur, handleChange, setFieldValue }) =>
      {
        const _handleSelectChange = (item)=>{
          setFieldValue("status", item.value);
        };
        const _handleSentSelectChange = (item)=>{
          setFieldValue("sentStatus", item.value);
        };
        const _handleCompanySelectChange = (item)=>{
          setFieldValue("company_id", item.value);
        };
        const _handleOrderTypeSelectChange = (item)=>{
          setFieldValue("order_type_id", item.value);
        };
        const _handleUserSelectChange = (item)=>{
          setFieldValue("user_id", item.value);
        };
        return(
          <form onSubmit={handleSubmit} className="form form-label-right">
            <div className="form-group row">
              <div className="col-lg-2">
                <input
                  type="text"
                  className="form-control"
                  name="searchText"
                  placeholder="Siparişler"
                  onBlur={handleBlur}
                  value={values.searchText}
                  onChange={(e) => {
                    setFieldValue("searchText", e.target.value);
                    handleSubmit();
                  }}
                />
              </div>
              <div className="col-lg-10">
                <Dropdown onClick={(e) => e.stopPropagation(e)} drop="down" className="float-right ml-2">
                  <Dropdown.Toggle>
                    Filtreleme Seçenekleri
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
                    <div className="navi navi-spacer-x-0 p-5">
                      <div className="form-group row">
                        <div className="col-lg-12 mt-2">
                          <Select onChange={_handleSelectChange}
                            options={statusOptions}
                            placeholder={'Durum'}
                            value={statusOptions.filter(item => item.value === values.status)}/>
                          <small className="form-text text-muted">
                            Duruma <b>Göre</b>
                          </small>
                        </div>
                        <div className="col-lg-12 mt-2">
                          <Select onChange={_handleSentSelectChange}
                            options={sentStatusOptions}
                            placeholder={'Sunucu Durumu'}
                            value={sentStatusOptions.filter(item => item.value === values.sentStatus)}/>
                          <small className="form-text text-muted">
                            Sunucu Duruma <b>Göre</b>
                          </small>
                        </div>
                        <div className="col-lg-12 mt-2">
                          <AsyncSelect
                            cacheOptions
                            loadOptions={loadOptionsCompany}
                            defaultOptions
                            onInputChange={setCompanySearch}
                            onChange={_handleCompanySelectChange}
                            options={companies}
                            placeholder={'Firma'}
                          />
                          <small className="form-text text-muted">
                            Firmaya <b>Göre</b>
                          </small>
                        </div>
                        <div className="col-lg-12 mt-2">
                          <AsyncSelect
                            cacheOptions
                            loadOptions={loadOptionsUser}
                            defaultOptions
                            onInputChange={setUserSearch}
                            onChange={_handleUserSelectChange}
                            options={users}
                            placeholder={'Kullanıcı'}
                          />
                          <small className="form-text text-muted">
                            Kullanıcıya <b>Göre</b>
                          </small>
                        </div>
                        <div className="col-lg-12 mt-2">
                          <Select
                            onChange={_handleOrderTypeSelectChange}
                            options={orderTypes}
                            placeholder={'Sipariş Durumu'}
                            // value={orderTypes.filter(item => item.value === values.order_type_id)}
                          />
                          <small className="form-text text-muted">
                            Sipariş Durumuna <b>Göre</b>
                          </small>
                        </div>
                        <div className="col-lg-12 mt-2">
                          <button type="button" className="btn btn-outline-primary btn-block" onClick={handleSubmit}>
                            Uygula
                          </button>
                        </div>
                      </div>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
                <button type="button" className="btn btn-info float-right" onClick={updateAllOrder}>
                  Güncelle
                </button>
              </div>
            </div>
          </form>
        );}
      }
    </Formik>
  );
};
export default OrderFilter;
