export const OrderStatusCssClasses = ["danger", "success"];
export const OrderPaidTitles = ["Ödenmedi", "Ödendi"];
export const OrderStatusTitles = ["Sipariş Alındı", "Hazırlanıyor", "Tamamlandı", "Kısmi Sevk", "İptal"];
export const OrderStatusClasses = ["primary", "dark", "danger", "warning", "info"];
export const OrderItemStatusTitles = ["Sipariş Alındı", "Hazırlanıyor", "Hazırlandı", "İptal"];
export const OrderItemStatusClasses = ["primary", "dark", "danger", "warning"];
export const OrderSourceTitles = ["", "Web", "Mobile", "Cms", "Cli", "Logo"];
export const OrderSourceClasses = ["", "primary", "dark", "danger", "warning", "info"];
export const defaultSorted = [{ dataField: "id", order: "desc" }];
export const sizePerPageList = [
  { text: "3", value: 3 },
  { text: "5", value: 5 },
  { text: "10", value: 10 }
];
export const initialFilter = {
  filter: {
    company_name: "",
    status: "1",
  },
  sortOrder: "desc", // asc||desc
  sortField: "id",
  pageNumber: 1,
  pageSize: 10
};
