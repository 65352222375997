import React from "react";
import { UserSourceCssClasses, UserSourceTitles } from '../../UiHelpers';


const SourceColumnFormatter = (cellContent, row) => {
  const getLabelCssClasses = () => {
    return `label label-lg label-light-${
      UserSourceCssClasses[row.source]
    } label-inline`;
  };
  return (
    <span className={getLabelCssClasses()}>
      {UserSourceTitles[row.source]}
    </span>
  );
};
export default SourceColumnFormatter;
