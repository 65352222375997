import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../helpers";

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu &&
            "menu-item-active"} menu-item-open menu-item-not-hightlighted`
      : "";
  };

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        <li
          className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")}/>
            </span>
            <span className="menu-text">Dashboard</span>
          </NavLink>
        </li>

        <li className="menu-section ">
          <h4 className="menu-text">İÇERİK YÖNETİMİ</h4>
          <i className="menu-icon flaticon-more-v2" />
        </li>

        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/content",
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/content">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Box2.svg")}/>
            </span>
            <span className="menu-text">Kategori İşlemleri</span>
            <i className="menu-arrow"/>
          </NavLink>
          <div className="menu-submenu ">
            <ul className="menu-subnav">
              <ul className="menu-subnav">
                <li
                  className={`menu-item ${getMenuItemActive(
                    "/content"
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/content/categories">
                    <i className="menu-bullet menu-bullet-dot">
                      <span/>
                    </i>
                    <span className="menu-text">Kategoriler</span>
                  </NavLink>
                  <NavLink className="menu-link" to="/content/sub_categories">
                    <i className="menu-bullet menu-bullet-dot">
                      <span/>
                    </i>
                    <span className="menu-text">Alt Kategoriler</span>
                  </NavLink>
                </li>
              </ul>
            </ul>
          </div>
        </li>
        <li className={`menu-item menu-item-submenu ${getMenuItemActive("/product_management", true)}`}
          aria-haspopup="true" data-menu-toggle="hover">
          <NavLink className="menu-link menu-toggle" to="/product_management">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Bag2.svg")}/>
            </span>
            <span className="menu-text">Ürün İşlemleri</span>
            <i className="menu-arrow"/>
          </NavLink>
          <div className="menu-submenu ">
            <ul className="menu-subnav">
              <ul className="menu-subnav">
                <li className={`menu-item ${getMenuItemActive(
                  "/product_management"
                )}`}
                aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/product_management/brands">
                    <i className="menu-bullet menu-bullet-dot">
                      <span/>
                    </i>
                    <span className="menu-text">Markalar</span>
                  </NavLink>
                  <NavLink className="menu-link" to="/product_management/product_types">
                    <i className="menu-bullet menu-bullet-dot">
                      <span/>
                    </i>
                    <span className="menu-text">Ürün Türleri</span>
                  </NavLink>
                  <NavLink className="menu-link" to="/product_management/attributes">
                    <i className="menu-bullet menu-bullet-dot">
                      <span/>
                    </i>
                    <span className="menu-text">Özellikler</span>
                  </NavLink>
                  <NavLink className="menu-link" to="/product_management/attribute_values">
                    <i className="menu-bullet menu-bullet-dot">
                      <span/>
                    </i>
                    <span className="menu-text">Özellik Değerleri</span>
                  </NavLink>
                  <NavLink className="menu-link" to="/product_management/products">
                    <i className="menu-bullet menu-bullet-dot">
                      <span/>
                    </i>
                    <span className="menu-text">Ürün Kartları</span>
                  </NavLink>
                  <NavLink className="menu-link" to="/product_management/stocks">
                    <i className="menu-bullet menu-bullet-dot">
                      <span/>
                    </i>
                    <span className="menu-text">Stok Kartları</span>
                  </NavLink>
                  <NavLink className="menu-link" to="/product_management/campaigns">
                    <i className="menu-bullet menu-bullet-dot">
                      <span/>
                    </i>
                    <span className="menu-text">Kampanyalar</span>
                  </NavLink>
                  <NavLink className="menu-link" to="/product_management/collections">
                    <i className="menu-bullet menu-bullet-dot">
                      <span/>
                    </i>
                    <span className="menu-text">Koleksiyonlar</span>
                  </NavLink>
                </li>
              </ul>
            </ul>
          </div>
        </li>
        <li className={`menu-item menu-item-submenu ${getMenuItemActive(
          "/seller_management",
          true
        )}`} aria-haspopup="true" data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to={'/seller_management'}>
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")}/>
            </span>
            <span className="menu-text">Satıcı/Bayi İşlemleri</span>
            <i className="menu-arrow"/>
          </NavLink>
          <div className="menu-submenu ">
            <ul className="menu-subnav">
              <ul className="menu-subnav">
                <li
                  className={`menu-item ${getMenuItemActive(
                    "/seller_management"
                  )}`}
                  aria-haspopup="true"
                >
                  {/*<NavLink className="menu-link" to="/seller_management/sellers">*/}
                  {/*  <i className="menu-bullet menu-bullet-dot">*/}
                  {/*    <span/>*/}
                  {/*  </i>*/}
                  {/*  <span className="menu-text">Satıcı Hesapları</span>*/}
                  {/*</NavLink>*/}
                  <NavLink className="menu-link" to="/seller_management/companies">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Firma Hesapları</span>
                  </NavLink>
                  <NavLink className="menu-link" to="/seller_management/addresses">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Adresler</span>
                  </NavLink>
                </li>
              </ul>
            </ul>
          </div>
        </li>
        <li className={`menu-item menu-item-submenu ${getMenuItemActive(
          "/order_management",
          true
        )}`} aria-haspopup="true" data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to={'/order_management'}>
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Commode2.svg")}/>
            </span>
            <span className="menu-text">Sipariş İşlemleri</span>
            <i className="menu-arrow"/>
          </NavLink>
          <div className="menu-submenu ">
            <ul className="menu-subnav">
              <ul className="menu-subnav">
                <li
                  className={`menu-item ${getMenuItemActive(
                    "/order_management"
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/order_management/orders">
                    <i className="menu-bullet menu-bullet-dot">
                      <span/>
                    </i>
                    <span className="menu-text">Siparişler</span>
                  </NavLink>
                </li>
              </ul>
            </ul>
          </div>
        </li>
        <li className={`menu-item menu-item-submenu ${getMenuItemActive(
          "/user_management",
          true
        )}`} aria-haspopup="true" data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/react-bootstrap">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Group.svg")}/>
            </span>
            <span className="menu-text">Kullanıcı İşlemleri</span>
            <i className="menu-arrow"/>
          </NavLink>
          <div className="menu-submenu ">
            <ul className="menu-subnav">
              <ul className="menu-subnav">
                <li
                  className={`menu-item ${getMenuItemActive(
                    "/user_management"
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/user_management/users">
                    <i className="menu-bullet menu-bullet-dot">
                      <span/>
                    </i>
                    <span className="menu-text">Kullanıcılar</span>
                  </NavLink>
                  <NavLink className="menu-link" to="/user_management/roles">
                    <i className="menu-bullet menu-bullet-dot">
                      <span/>
                    </i>
                    <span className="menu-text">Roller</span>
                  </NavLink>
                  <NavLink className="menu-link" to="/user_management/messages">
                    <i className="menu-bullet menu-bullet-dot">
                      <span/>
                    </i>
                    <span className="menu-text">Mesajlar</span>
                  </NavLink>
                </li>
              </ul>
            </ul>
          </div>
        </li>
      </ul>
    </>
  );
}
