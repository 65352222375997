import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import "./index.scss";
import "./assets/plugins/keenthemes-icons/font/ki.css";
import "./assets/plugins/flaticon/flaticon.css";
import "./assets/plugins/flaticon2/flaticon.css";
import "react-datepicker/dist/react-datepicker.css";
import 'react-bootstrap-typeahead/css/Typeahead.css';
import "@fortawesome/fontawesome-free/css/all.min.css";
import 'react-toastify/dist/ReactToastify.css';
import { SplashScreenProvider } from './components/SplashScreen';
import { LayoutProvider, SubheaderProvider } from './components';

ReactDOM.render(
  <LayoutProvider>
    <SubheaderProvider>
      <SplashScreenProvider>
        <App />
      </SplashScreenProvider>
    </SubheaderProvider>
  </LayoutProvider>
  ,
  document.getElementById('root')
);
