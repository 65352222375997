import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { useProductTypeUIContext } from '../UIContext';
import { delete_, post } from '../../../helpers';

const ProductTypeDeleteDialog = ({ id, show, onHide }) => {
  const brandUIContext = useProductTypeUIContext();
  const brandUIProps = useMemo(() => {
    return {
      setError: brandUIContext.setError,
      setState: brandUIContext.setState
    };
  }, [brandUIContext]);

  const { queryParams } = brandUIContext;

  useEffect(() => {
    if (!id) {
      onHide();
    }
  }, [id]);


  const deleteProductType = async () => {
    const { status, message } = await delete_(`product_type/delete/${id}`);
    if(!status){
      brandUIProps.setError(message);
      onHide();
      return;
    }
    const { product_types,status:_status, message:_message, total_count } = await post('/product_type/admin/list', { ...queryParams });
    if(!_status){
      brandUIProps.setError(_message);
      return;
    }
    await brandUIProps.setState({ product_types, total_count });
    onHide();
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
    >
      <Modal.Header closeButton>
        <Modal.Title>
            Durum Güncelle
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span>Seçtiğiniz markanın durumu değiştirilecek. Devam etmek istiyor musunuz?</span>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={onHide}
            className="btn btn-light btn-elevate"
          >
              İptal
          </button>
          <button
            type="button"
            onClick={deleteProductType}
            className="btn btn-danger btn-elevate"
          >
              Güncelle
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
export default ProductTypeDeleteDialog;
