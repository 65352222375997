import React from "react";
import { Col, Container, Row } from "react-bootstrap";

const UserCompany = ({ data }) =>{
  return (
    <div className="card card-custom">
      <div className="card-header py-3">
        <div className="card-title align-items-start flex-column">
          <h3 className="card-label font-weight-bolder text-dark">
            Firma Bilgileri
          </h3>
          <span className="text-muted font-weight-bold font-size-sm mt-1">
            Kullanıcı firma bilgilerini görüntüleyin
          </span>
        </div>
      </div>
      <div className="card-body">
        <Container>
          <Row>
            <Col sm={2}><p className={'text-black-50'}>Firma Adı:</p></Col>
            <Col sm={6}>{data.name}</Col>
          </Row>
          <Row>
            <Col sm={2}><p className={'text-black-50'}>Firma Kodu:</p></Col>
            <Col sm={6}>{data.code}</Col>
          </Row>
          <Row>
            <Col sm={2}><p className={'text-black-50'}>Oluşturulma Tarihi:</p></Col>
            <Col sm={6}>{data.create_date}</Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default UserCompany;
