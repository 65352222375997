import React, { useState, useMemo, useEffect, useRef } from "react";
import { Dropdown } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import io from "socket.io-client";
import SVG from "react-inlinesvg";
import objectPath from "object-path";
import { toAbsoluteUrl } from "../../../helpers";
import { useHtmlClassService } from "../../core/MetronicLayout";
import { DropdownTopbarItemToggler } from "./DropdownTopbarItemToggler";

const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false,
};

export function DebugDropdown() {
  const bgImage = toAbsoluteUrl("/media/misc/bg-1.jpg");
  const ps = useRef();
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      offcanvas:
        objectPath.get(uiService.config, "extras.notifications.layout") ===
        "offcanvas",
    };
  }, [uiService]);
  const scrollTop = () => {
    const curr = ps.current;
    if (curr) {
      curr.scrollTop = 0;
    }
  };
  const connection_url='https://b2bapp.ozsafirhali.com';
  const [messages, setMessages] = useState([]);
  const [isConnected, setIsConnected] = useState(false);
  let socket = undefined;

  useEffect(() => {
    socket = io(connection_url, {
      reconnection: true,
      reconnectionAttempts: Infinity,
      reconnectionDelay: 1000
    });
    socket.on('console', (data) => {
      setMessages((prev) => [...prev, data]);
    });
    socket.on('connect', () => {
      setIsConnected(true);
    });
  }, []);

  useEffect(() => {scrollTop();}, [messages]);
  return (
    <React.Fragment>
      {layoutProps.offcanvas &&
        <div className="topbar-item">
          <div
            className="btn btn-icon btn-clean btn-lg mr-1 pulse pulse-primary"
            id="kt_quick_notifications_toggle"
          >
            <span className="svg-icon svg-icon-xl svg-icon-primary">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Compiling.svg")} />
            </span>
            <span className="pulse-ring" />
          </div>
        </div>
      }
      {!layoutProps.offcanvas && 
        <Dropdown drop="down" alignRight>
          <Dropdown.Toggle
            as={DropdownTopbarItemToggler}
            id="kt_quick_notifications_toggle"
          >
            <div
              className="btn btn-icon btn-clean btn-lg mr-1 pulse pulse-primary"
              id="kt_quick_notifications_toggle"
            >
              <span className="svg-icon svg-icon-xl svg-icon-primary">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Compiling.svg")} />
              </span>
              <span className="pulse-ring" />
            </div>
          </Dropdown.Toggle>

          <Dropdown.Menu className="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg">
            <div
              className="d-flex flex-column pt-12 bgi-size-cover bgi-no-repeat rounded-top"
              style={{ backgroundImage: `url(${bgImage})` }}
            >
              <h4 className="d-flex flex-center rounded-top">
                <span className="text-white">Debug</span>
                <span className="btn btn-text btn-primary btn-sm btn-font-md ml-5">
                  {isConnected ? 'Bağlandı': 'Bağlantı hatası'}
                </span>
              </h4>

            </div>
            <PerfectScrollbar
              containerRef={el => ps.current = el}
              options={perfectScrollbarOptions}
              className="scroll pr-7 mr-n7"
              style={{ maxHeight: "300px", position: "relative" }}
            >
              <div className="d-flex align-items-center mb-6">
                <ul>{messages.map((m, ind) => <li key={ind}>{m}</li>)}</ul>
              </div>
            </PerfectScrollbar>
          </Dropdown.Menu>
        </Dropdown>
      }
    </React.Fragment>
  );
}
