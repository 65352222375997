import React from "react";
import { Link } from "react-router-dom";
import { Badge } from "react-bootstrap";

const KeyColumnFormatter = (cellContent, row) =>{
  const { id, buyer: { user,company }, key, date, is_new } = row;
  return (
    <React.Fragment>
      <Link to={`/order_management/orders/${id}/information`} className="text-dark-75 font-weight-bold text-hover-info mb-1">{key}</Link>
      {company && <div className="font-weight-light font-size-sm">
        {company.name}
      </div>}
      {user && <div className="font-weight-lighter font-size-xs">
        {user.name}
      </div>}
      <div className={'font-weight-light text-muted font-size-sm'}>{date}
        {is_new && <Badge className={'ml-2'} pill variant="danger">Yeni</Badge>}
      </div>
    </React.Fragment>
  );
};
export default KeyColumnFormatter;
