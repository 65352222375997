import React from "react";
import { Link } from "react-router-dom";
import { RatingIcons } from "../../../ProductSkus/component";

const NameColumnFormatter = (cellContent, row) =>{
  const { name, attributes_map, stock } = row;
  console.log(row);
  return (
    <React.Fragment>
      <div className="text-dark-75 font-weight-bold mb-1">{name}</div>
      <div className={'font-weight-light text-muted'}>
        {
          Object.keys(attributes_map).map((key, i) =>
            <span key={i}>
              <span>{key} : </span><span>{attributes_map[key]} </span>
            </span>
          )
        }
      </div>
      <RatingIcons rating={row.rating.score} />
      <div className="font-weight-light font-size-sm">
                Stok {stock.quantity} {stock.type} ( Birim: {stock.unit} Metrekare )
      </div>
      <div className="font-weight-light font-size-xs">
                Oluşturma Tarihi: {row.create_date}
      </div>
    </React.Fragment>
  );
};
export default NameColumnFormatter;
