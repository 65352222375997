import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ModalProgressBar } from "../../../components/common/controls";
import { useUserUIContext } from "../UIContext";
import { put } from "../../../helpers";
import { toast } from "react-toastify";

const AccountInformation = () => {
  const { userForEdit:user } = useUserUIContext();
  const [loading, setLoading] = useState(false);

  useEffect(() => {}, [user]);
  console.log(user);
  // Methods
  const saveUser = (values, setStatus, setSubmitting) => {
    setLoading(true);
    // user for update preparation
    setTimeout(() => {
      setLoading(false);
      setSubmitting(false);
      // Do request to your server for user update, we just imitate user update there, For example:
      // update(updatedUser)
      //  .then(()) => {
      //    setloading(false);
      //  })
      //  .catch((error) => {
      //    setloading(false);
      //    setSubmitting(false);
      //    setStatus(error);
      // });
    }, 1000);
  };
  // UI Helpers
  const initialValues = {
    email: user && user.email,
    communicationEmail: false,
    communicationSMS: false,
    communicationPhone: false,
  };
  const Schema = Yup.object().shape({
    email: Yup.string()
      .email("Geçersiz email formatı")
      .required("Email yazınız"),
    communicationEmail: Yup.bool(),
    communicationSMS: Yup.bool(),
    communicationPhone: Yup.bool(),
  });
  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };
  const formik = useFormik({
    initialValues,
    validationSchema: Schema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      saveUser(values, setStatus, setSubmitting);
    },
    onReset: (values, { resetForm }) => {
      resetForm();
    },
  });

  const sendActivationMail = async () => {
    const { status, message } = await put('/user/activation/'+user.id);
    console.log(status, message);
    if (!status) {
      toast.error(message);
      return;
    }
    toast.success('Mail başarıyla gönderildi.');
  };

  const confirmUserManually = async () => {
    const { status, message } = await put('/user/admin/confirm/'+user.id);
    console.log(status, message);
    if (!status) {
      toast.error(message);
      return;
    }
    toast.success('Kullanıcı onaylandı!');
  };

  return (
    <form className="card card-custom" onSubmit={formik.handleSubmit}>
      {loading && <ModalProgressBar />}
      <div className="card-header py-3">
        <div className="card-title align-items-start flex-column">
          <h3 className="card-label font-weight-bolder text-dark">
            Hesap Bilgileri
          </h3>
          <span className="text-muted font-weight-bold font-size-sm mt-1">
            Hesap bilgilerini güncelleyin
          </span>
        </div>
        <div className="card-toolbar">
          <button
            type="submit"
            className="btn btn-success mr-2"
            disabled={
              formik.isSubmitting || formik.touched && !formik.isValid
            }
          >
            Güncelle
            {formik.isSubmitting}
          </button>
          <Link to={'/user_management/users/'+user.id+'/overview'} className="btn btn-secondary">İptal</Link>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Form */}
      <div className="form">
        <div className="card-body">
          {/* begin::Heading */}
          <div className="row">
            <label className="col-xl-3" />
            <div className="col-lg-9 col-xl-6">
              <h5 className="font-weight-bold mb-6">Hesap:</h5>
            </div>
          </div>
          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">
              Email Adresi
            </label>
            <div className="col-lg-9 col-xl-6">
              <div className="input-group input-group-lg input-group-solid">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="fa fa-at" />
                  </span>
                </div>
                <input
                  type="text"
                  placeholder="Email"
                  disabled={true}
                  className={`form-control form-control-lg form-control-solid ${getInputClasses(
                    "email"
                  )}`}
                  name="email"
                  {...formik.getFieldProps("email")}
                />
                {formik.touched.email && formik.errors.email ? 
                  <div className="invalid-feedback">{formik.errors.email}</div>
                  : null}
              </div>
            </div>
          </div>
          <div className="form-group row align-items-center">
            <label className="col-xl-3 col-lg-3 col-form-label">
              İletişim
            </label>
            <div className="col-lg-9 col-xl-6">
              <div className="checkbox-inline">
                <label className="checkbox">
                  <input
                    type="checkbox"
                    name="communicationEmail"
                    checked={formik.values.communicationEmail}
                    onChange={formik.handleChange}
                  />
                  <span/>Email
                </label>
                <label className="checkbox">
                  <input
                    type="checkbox"
                    name="communicationSMS"
                    checked={formik.values.communicationSMS}
                    {...formik.getFieldProps("communicationSMS")}
                    onChange={formik.handleChange}
                  />
                  <span/>SMS
                </label>
                <label className="checkbox">
                  <input
                    type="checkbox"
                    name="communicationPhone"
                    checked={formik.values.communicationPhone}
                    {...formik.getFieldProps("communicationPhone")}
                    onChange={formik.handleChange}
                  />
                  <span/>Bildirim
                </label>
              </div>
            </div>
          </div>
          {/* begin::Form Group */}
          <div className="separator separator-dashed my-5" />
          {/* begin::Form Group */}
          <div className="row">
            <label className="col-xl-3" />
            <div className="col-lg-9 col-xl-6">
              <h5 className="font-weight-bold mb-6">Güvenlik:</h5>
            </div>
          </div>
          {/* begin::Form Group */}
          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">
              Giriş doğrulama
            </label>
            <div className="col-lg-9 col-xl-6">
              <button
                type="button"
                className="btn btn-light-primary font-weight-bold btn-sm"
              >
                Giriş doğrulama ayarları
              </button>
            </div>
          </div>
          {!user.is_confirmed && <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">
              Aktivasyon
            </label>
            <div className="col-lg-9 col-xl-6">
              <button
                onClick={sendActivationMail}
                type="button"
                className="btn btn-light-primary font-weight-bold btn-sm"
              >
                Aktivasyon Maili Gönder
              </button>
            </div>
          </div>}
          {!user.is_confirmed && <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">
              Manuel Onay
            </label>
            <div className="col-lg-9 col-xl-6">
              <button
                onClick={confirmUserManually}
                type="button"
                className="btn btn-light-primary font-weight-bold btn-sm"
              >
                Kullanıcıyı Onayla
              </button>
            </div>
          </div>}
          {/* begin::Form Group */}
          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">
             Hesabı sil
            </label>
            <div className="col-lg-9 col-xl-6">
              <button
                type="button"
                className="btn btn-light-danger font-weight-bold btn-sm"
              >
                Hesabı kalıcı olarak sil
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* end::Form */}
    </form>
  );
};
export default AccountInformation;
