import React from "react";
import { CampaignUIProvider } from './CampaignUIContext';
import { useHistory, Switch, Route } from 'react-router-dom';
import CampaignCard from './CampaignCard';
import { CampaignDeleteDialog } from './dialog';
import { ContentRoute } from '../../components';
import { CampaignAdd, CampaignInformation } from './index';

const CampaignPage = () => {
  const { push } = useHistory();
  const _onHide = () => push("/product_management/campaigns");
  const _handleEdit = ({ match }) => <CampaignAdd id={match && match.params.id}/>;
  const _handleDeleteModal = ({ match }) => <CampaignDeleteDialog show={match != null} id={match && match.params.id} onHide={_onHide} />;
  const _campaignInfo = ({ match }) => <CampaignInformation id={match && match.params.id}/>;
  return (
    <CampaignUIProvider>
      <Route path="/product_management/campaigns/:id/delete" render={_handleDeleteModal}/>
      <Switch>
        <ContentRoute path="/product_management/campaigns/new" component={CampaignAdd} />
        <ContentRoute path="/product_management/campaigns/:id/edit" component={_handleEdit} />
        <ContentRoute path="/product_management/campaigns/:id/information" component={_campaignInfo} />
        <ContentRoute component={CampaignCard} />
      </Switch>
    </CampaignUIProvider>
  );
};
export default CampaignPage;
