import React, { useMemo } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import objectPath from "object-path";
import { useHtmlClassService } from "../../core/MetronicLayout";
import { UserProfileDropdown } from "./dropdowns/UserProfileDropdown";
import { useStores } from '../../../store';

const QuickUserToggler = () => {
  const { userInfo: { user } } = useStores();
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      offcanvas:
        objectPath.get(uiService.config, "extras.user.layout") === "offcanvas",
    };
  }, [uiService]);
  return (
    <React.Fragment>
      {!layoutProps.offcanvas && <UserProfileDropdown />}
    </React.Fragment>
  );
};
export default QuickUserToggler;
