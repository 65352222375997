import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  ModalProgressBar,
} from '../../components/common/controls';
import { get, post, delete_ } from "../../helpers";
import { Col, Container, Row } from "react-bootstrap";
import styles from "../Orders/css/order.module.css";
import moment from "moment";
import { toast } from "react-toastify";
import { AsyncTypeahead } from "react-bootstrap-typeahead";

const CampaignInformation = ({ id }) => {
  const [tab, setTab] = useState("general");
  const [data, setData] = useState({});
  const [campaignProducts, setCampaignProducts] = useState([]);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);

  const moveToGeneralTab = () => setTab("general");
  const moveToProductTab = () => setTab("product");

  const getCampaign = async () => {
    if (id) {
      const { campaign } = await get('/campaign/admin/' + id);
      setData(campaign);
    }
  };

  const getProducts = async (productName) => {
    setLoading(true);
    const queryParams= {
      pageNumber:1,
      pageSize:10,
      sortField: "id",
      sortOrder: "desc",
      filter:{
        name: productName
      }
    };
    const { product_skus } = await post('/product/admin/sku/list', { ...queryParams });
    setProducts(product_skus);
    setLoading(false);
  };

  const getCampaignProducts = async () => {
    const { product_skus } = await get('/campaign/admin/product/list/'+parseInt(id));
    setCampaignProducts(product_skus);
  };

  const addCampaignProduct = async (product_id) => {
    const { status, message } = await post('/campaign/admin/product/add', { campaign_id: parseInt(id), product_id });
    if (status) {
      getCampaignProducts();
    } else {
      toast.info(message);
    }
  };

  const deleteCampaignProduct = async (product_id) => {
    const { status } = await delete_(`/campaign/admin/product/${parseInt(id)}/${product_id}`);
    if (status) {
      getCampaignProducts();
    }
  };

  const _handleProductChange = (item)=> {
    if(item.length > 0){
      addCampaignProduct(item[0].id);
    }
  };
  const filterBy = () => true;
  const _handleSearch = async (query) => {
    getProducts(query);
  };

  useEffect(() => {
    getCampaign();
    getCampaignProducts();
  }, []);

  return (
    <Card>
      {!data.id && <ModalProgressBar />}
      <CardHeader title={'Ürün Bilgileri'}/>
      <CardBody>
        {
          data.id &&
            <React.Fragment>
              <ul className="nav nav-tabs nav-tabs-line " role="tablist">
                <li className="nav-item" onClick={moveToGeneralTab}>
                  <a
                    className={`nav-link ${tab === "general" && "active"}`}
                    data-toggle="tab"
                    role="tab"
                    aria-selected={(tab === "general").toString()}
                  >
                                            Genel Bilgiler
                  </a>
                </li>
                <li className="nav-item" onClick={moveToProductTab}>
                  <a
                    className={`nav-link ${tab === "product" && "active"}`}
                    data-toggle="tab"
                    role="tab"
                    aria-selected={(tab === "product").toString()}
                  >
                    Ürünler
                  </a>
                </li>
              </ul>
              <div style={{ minHeight: 250 }} className="mt-5">
                <React.Fragment>
                  {tab === "general" &&
                    <Container>
                      <Row>
                        <Col sm={2}><p className={'text-black-50'}>Adı:</p></Col>
                        <Col sm={6}>{data.name}</Col>
                      </Row>
                      <Row>
                        <Col sm={2}><p className={'text-black-50'}>Sıra:</p></Col>
                        <Col sm={6}>{data.order}</Col>
                      </Row>
                      <Row>
                        <Col sm={2}><p className={'text-black-50'}>Ürün Sayısı:</p></Col>
                        <Col sm={6}>{data.product_count}</Col>
                      </Row>
                      <Row>
                        <Col sm={2}><p className={'text-black-50'}>Başlangıç Tarihi:</p></Col>
                        <Col sm={6}>{moment(data.start_date).format('DD/MM/YYYY')}</Col>
                      </Row>
                      <Row>
                        <Col sm={2}><p className={'text-black-50'}>Bitiş Tarihi:</p></Col>
                        <Col sm={6}>{moment(data.start_date).format('DD/MM/YYYY')}</Col>
                      </Row>
                    </Container>
                  }
                  {tab === "product" &&
                  <Container>
                    <Row>
                      <div className="form-group row">
                        <div className="col-lg-12">
                          <label>Ürün Adı</label>
                          <AsyncTypeahead
                            filterBy={filterBy}
                            id={'productSearch'}
                            isLoading={loading}
                            labelKey="name"
                            minLength={3}
                            onChange={_handleProductChange}
                            onSearch={_handleSearch}
                            options={products}
                            placeholder={'Ürün Adı'}
                          />
                        </div>
                      </div>
                    </Row>
                    {
                      campaignProducts.map(item =>
                        <Row className={styles.container}>
                          <Col className={'text-center'}>
                            <p className={'text-black-50'}>Ürün Adı</p>
                            <p>{item.name}</p>
                          </Col>
                          <Col className={'text-center'}>
                            <p className={'text-black-50'}>Fiyat</p>
                            <p>{item.price ?? '-'}</p>
                          </Col>
                          <Col className={'text-center'}>
                            <p className={'text-black-50'}>Stok ({item.stock.type})</p>
                            <p>{item.stock.quantity}</p>
                          </Col>
                          <Col className={styles.centerButton}>
                            <button onClick={ () => deleteCampaignProduct(item.id)}>Kaldır</button>
                          </Col>
                        </Row>
                      )
                    }
                  </Container>
                  }
                </React.Fragment>
              </div>
            </React.Fragment>
        }
      </CardBody>
    </Card>
  );
};
export default CampaignInformation;
