import React, { useEffect, useState } from "react";
import { headerSortingClasses, post, sortCaret } from "../../../helpers";
import { AddressColumnFormatter } from "../../Addresses/address-table/column-formatters";
import * as uiHelpers from "../../Users/UiHelpers";
import { DataTable } from "../../../components";

const UserAddress = ({ id }) =>{
  const [queryParams, setQueryParams] = useState({ filter: {
    key: "",
    user_id:id,
  },
  sortOrder: "desc", // asc||desc
  sortField: "id",
  pageNumber: 1,
  pageSize: 10 });
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [total_count, setTotalCount] = useState([]);
  const columns = [
    {
      dataField: "id",
      text: "ID",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      style: {
        width: "40px",
      },
    },
    {
      dataField: "title",
      text: "",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "name",
      text: "Adres",
      formatter: AddressColumnFormatter,
    },
    {
      dataField: "city.name",
      text: "İl",
    },
    {
      dataField: "county.name",
      text: "İlçe",
    },
  ];
  const handleTableChange = (type, { page, sizePerPage }) => {
    const pageNumber = page || 1;
    setQueryParams({ ...queryParams, pageNumber: pageNumber, pageSize: sizePerPage });
  };
  const getData = async () => {
    setLoading(true);
    const { addresses, total_count } = await post('/address/admin/list', { ...queryParams });
    setTotalCount(total_count);
    setData(addresses);
    setLoading(false);
  };

  useEffect(() => {
    getData();
  },[]);

  const paginationOptions = {
    custom: true,
    totalSize: total_count,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: queryParams.pageSize,
    page: queryParams.pageNumber,
  };
  return (
    <div className="card card-custom">
      <div className="card-header py-3">
        <div className="card-title align-items-start flex-column">
          <h3 className="card-label font-weight-bolder text-dark">
            Adresler
          </h3>
          <span className="text-muted font-weight-bold font-size-sm mt-1">
            Kullanıcı adreslerini görüntüleyin
          </span>
        </div>
      </div>
      <div className="card-body">
        <DataTable
          data={data}
          defaultSorted={uiHelpers.defaultSorted}
          columns={columns}
          paginationOptions={paginationOptions}
          handleTableChange={handleTableChange}
          listLoading={loading} />
      </div>
    </div>
  );
};

export default UserAddress;
