import React, { useEffect, useState } from 'react';
import styles from './css/product_sku.module.css';
import { post, toAbsoluteUrl, delete_, put } from "../../helpers";
import SVG from "react-inlinesvg";
import { toast } from "react-toastify";


const AddCard = ({ id, getProductSku }) => {
  const [files, setFiles] = useState([]);

  const uploadImages = async () => {
    toast.info('Yükleme başlatıldı.');
    const { status } = await post('product/admin/sku/add_image', { id, images: files });
    if (status) {
      getProductSku();
      toast.success('Yükleme başarıyla tamamlandı.');
    }
  };

  const onChange = async (e) => {
    const files_ = Array.from(e.target.files);
    const data = await Promise.all(files_.map(async (file, index) => {
      let file__ = await fileToBase64(file);
      file__ = file__.split(',')[1];
      return file__;
    }));
    setFiles(data);
  };
  const fileToBase64 = async (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (e) => reject(e);
    });


  useEffect(() => {
    if (files.length > 0) {
      uploadImages();
    }
  }, [files]);
  return (
    <>
      <input type="file" name="avatar" id="file" accept=".jpef, .png, .jpg" onChange={onChange} multiple
        style={{ display: 'none' }}/>
      <label htmlFor={'file'}>
        <div className={styles.addCard}>
          <SVG src={toAbsoluteUrl("/media/svg/icons/Files/Upload.svg")} style={{ height: 50, width: 50 }}/>
        </div>
      </label>
    </>

  );
};

const ImageCard = ({ image, getProductSku }) => {
  const handleDelete = async () => {
    toast.info('Silme başlatıldı.');
    const { status } = await delete_('product/admin/sku/delete_image/'+image.id);
    if (status) {
      getProductSku();
      toast.success('Silme başarıyla tamamlandı.');
    }
  };
  const handleShowCase = async () => {
    toast.info('Öne çıkartma başlatıldı.');
    const { status } = await put('/product/admin/sku/default_image/'+image.id);
    if (status) {
      getProductSku();
      toast.success('Öne çıkartma başarıyla tamamlandı.');
    }
  };
  return (
    <div
      className={styles.buttonContainer}
      style={{
        width:100,
        height:150,
        backgroundImage: "url(" + image.url + ")",
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        display:"flex",
        alignItems:'center',
        justifyContent: 'space-around',
        borderRadius: 10,
      }}>
      <span className="svg-icon svg-icon-xl svg-icon-danger" style={{ backgroundColor: '#ddd', padding: 10, borderRadius:10 }} onClick={handleDelete}>
        <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} style={{ height: 20, width: 20 }}/>
      </span>
      <span className="svg-icon svg-icon-xl svg-icon-primary" style={{ backgroundColor: '#ddd', padding: 10, borderRadius:10 }} onClick={handleShowCase}>
        <SVG src={toAbsoluteUrl("/media/svg/icons/General/Bookmark.svg")} style={{ height: 20, width: 20 }}/>
      </span>
    </div>
  );
};


const ImageContainer = ({ id, images, getProductSku }) => {
  return (
    <div className={styles.imageContainer}>
      <AddCard id={id} getProductSku={getProductSku}/>
      {
        images.map(image => <ImageCard image={image} getProductSku={getProductSku}/>)
      }
    </div>
  );
};
export default ImageContainer;
