import React from "react";
import paginationFactory, { PaginationProvider } from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import { NoRecordsFoundMessage } from "../helpers";
import { Pagination } from "./common/controls";


const DataTable = ({ paginationOptions,listLoading, defaultSorted, data, columns, handleTableChange }) => {
  return (
    <PaginationProvider pagination={paginationFactory(paginationOptions)}>
      {({ paginationProps, paginationTableProps }) => {
        return (
          <Pagination
            isLoading={listLoading}
            paginationProps={paginationProps}
          >
            <BootstrapTable
              remote
              keyField="id"
              wrapperClasses="table-responsive"
              classes="table table-head-custom table-head-bg table-vertical-center overflow-hidden"
              loading={ listLoading }
              bordered={false}
              data={data}
              columns={columns}
              defaultSorted={defaultSorted}
              onTableChange={handleTableChange}
              {...paginationTableProps}
              noDataIndication={ !listLoading && <NoRecordsFoundMessage /> } />
          </Pagination>
        );
      }}
    </PaginationProvider>

  );
};
export default DataTable;