import React, { useEffect, useState } from "react";
import {
  sortCaret,
  headerSortingClasses, post,
} from '../../../../helpers';
import * as uiHelpers from "../../UiHelpers";
import { DataTable, DefaultStatusColumnFormatter } from "../../../../components";
import {
  ImageColumnFormatter,
  NameColumnFormatter,
  SourceColumnFormatter
} from "../../../Users/user-table/column-formatters";

const CompanyUserTable = ({ company_id }) => {
  const [queryParams, setQueryParams] = useState({ filter: {
    name: "",
  },
  sortOrder: "asc", // asc||desc
  sortField: "id",
  pageNumber: 1,
  pageSize: 10 });
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [total_count, setTotalCount] = useState([]);
  const handleTableChange = (type, { page, sizePerPage }) => {
    const pageNumber = page || 1;
    setQueryParams({ ...queryParams, pageNumber: pageNumber, pageSize: sizePerPage });
  };
  useEffect(() => {
    const getUsers = async () => {
      setLoading(true);
      const { users, total_count, status } = await post('/user/admin/list', { ...queryParams, filter:{company_id} });
      setLoading(false);
      if(!status){
        return;
      }
      setTotalCount(total_count);
      setData(users);

    };
    getUsers();
  }, [queryParams]);

  const columns = [
    {
      dataField: "id",
      text: "ID",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      style: {
        width: "40px",
      },
    },
    {
      dataField: "image",
      text: "",
      formatter: ImageColumnFormatter,
      classes: "text-center",
      headerClasses: "text-center",
      style: {
        width: "60px",
      },
    },
    {
      dataField: "full_name",
      text: "Adı Soyadı",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: NameColumnFormatter,

    },
    {
      dataField: "email",
      text: "Email",
    },
    {
      dataField: "gsm",
      text: "Gsm",
    },
    {
      dataField: "is_active",
      text: "Durum",
      formatter: DefaultStatusColumnFormatter,
      classes: "text-center",
      headerClasses: "text-center",
      style: {
        width: "60px",
      },
    },
    {
      dataField: "source",
      text: "Kaynak",
      formatter: SourceColumnFormatter,
      classes: "text-center",
      headerClasses: "text-center",
      style: {
        width: "60px",
      },
    },
  ];
  const paginationOptions = {
    custom: true,
    totalSize: total_count,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: queryParams.pageSize,
    page: queryParams.pageNumber,
  };
  return (
    <React.Fragment>
      <div className="card card-custom">
        <div className="card-header py-3">
          <div className="card-title align-items-start flex-column">
            <h3 className="card-label font-weight-bolder text-dark">
                Kullanıcılar
            </h3>
            <span className="text-muted font-weight-bold font-size-sm mt-1">
            Kullanıcıları görüntüleyin
            </span>
          </div>
        </div>
        <div className="card-body" style={{ padding: '1rem 2.25rem' }}>
          <DataTable
            data={data}
            defaultSorted={uiHelpers.defaultSorted}
            columns={columns}
            paginationOptions={paginationOptions}
            handleTableChange={handleTableChange}
            listLoading={loading} />
        </div>
      </div>

    </React.Fragment>
  );
};
export default CompanyUserTable;
