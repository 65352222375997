import React, { useEffect, useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import {
  getHandlerTableChange,
  NoRecordsFoundMessage,
  sortCaret,
  headerSortingClasses, post,
} from '../../../helpers';
import * as uiHelpers from "../CampaignUiHelpers";
import { Pagination } from "../../../components/common/controls";
import { useCampaignUIContext } from '../CampaignUIContext';
import { useHistory } from "react-router-dom";
import { NameColumnFormatter } from './column-formatters';
import {DefaultActionsColumnFormatter, DefaultStatusColumnFormatter} from "../../../components";

const CampaignTable = () => {
  const history = useHistory();
  const campaignUIContext = useCampaignUIContext();
  const openEditDialog = (id) => {
    history.push(`/product_management/campaigns/${id}/edit`);
  };
  const openDeleteDialog = (id) => {
    history.push(`/product_management/campaigns/${id}/delete`);
  };
  const campaignUIProps = useMemo(() => {
    return {
      setQueryParams: campaignUIContext.setQueryParams,
      setState: campaignUIContext.setState,
      setError: campaignUIContext.setError,
      setListLoading: campaignUIContext.setListLoading,
    };
  }, [campaignUIContext]);

  const { state: { total_count, campaigns }, listLoading, queryParams } = campaignUIContext;
  useEffect(() => {
    const getVariants = async () => {
      campaignUIProps.setListLoading(true);
      const { campaigns, status, message, total_count } = await post('/campaign/admin/list', { ...queryParams });
      campaignUIProps.setListLoading(false);
      if(!status){
        campaignUIProps.setError(message);
        return;
      }
      campaignUIProps.setError(null);
      campaignUIProps.setState({ campaigns, total_count });
    };
    getVariants();
    return () => {
      campaignUIProps.setError(null);
    };
  }, [queryParams]);

  const columns = [
    {
      dataField: "id",
      text: "ID",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "name",
      text: "Ürün Adı",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: NameColumnFormatter,
    },
    {
      dataField: "order",
      text: "Sıra",
      sort: false,
      sortCaret: sortCaret,
    },
    {
      dataField: "is_active",
      text: "Durum",
      sort: false,
      sortCaret: sortCaret,
      formatter: DefaultStatusColumnFormatter,
      headerSortingClasses,
    },
    {
      dataField: "action",
      text: "İşlem",
      formatter: DefaultActionsColumnFormatter,
      formatExtraData: {
        openEditDialog,
        openDeleteDialog,
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
    },
  ];

  const customTotal = (from, to, size) => {
    return (
      <span className="react-bootstrap-table-pagination-total">
        { from } - { to } Toplam { size }
      </span>
    );
  };

  const paginationOptions = {
    custom: true,
    totalSize: total_count,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: queryParams.pageSize,
    page: queryParams.pageNumber,
    paginationTotalRenderer: customTotal,
  };
  return (
    <PaginationProvider pagination={paginationFactory(paginationOptions)}>
      {({ paginationProps, paginationTableProps }) => {
        return (
          <Pagination
            isLoading={listLoading}
            paginationProps={paginationProps}
          >
            <BootstrapTable
              wrapperClasses={'table-responsive'}
              classes={'table table-head-custom table-head-bg table-vertical-center overflow-hidden'}
              bootstrap4
              loading={ listLoading }
              bordered={false}
              remote
              keyField="id"
              data={campaigns === null ? [] : campaigns}
              columns={columns}
              defaultSorted={uiHelpers.defaultSorted}
              onTableChange={getHandlerTableChange(
                campaignUIProps.setQueryParams
              )}
              {...paginationTableProps}
              noDataIndication={ () => !listLoading && <NoRecordsFoundMessage /> } />
          </Pagination>
        );
      }}
    </PaginationProvider>
  );
};
export default CampaignTable;
