import React from "react";
import { ProductSkuUIProvider } from './UIContext';
import { useHistory, Switch, Route } from 'react-router-dom';
import ProductSkuCard from './ProductSkuCard';
import { ProductSkuDeleteDialog } from './dialog';
import { ContentRoute } from '../../components';
import { ProductSkuAdd, ProductSkuInformation } from './index';

const ProductSkuPage = () => {
  const { push } = useHistory();
  const _onHide = () => push("/product_management/stocks");
  const _handleEdit = ({ match }) => <ProductSkuAdd id={match && match.params.id}/>;
  const _handleDeleteModal = ({ match }) => <ProductSkuDeleteDialog show={match != null} id={match && match.params.id} onHide={_onHide} />;
  const _productSkuInfo = ({ match }) => <ProductSkuInformation id={match && match.params.id}/>;
  return (
    <ProductSkuUIProvider>
      <Route path="/product_management/stocks/:id/delete" render={_handleDeleteModal}/>
      <Switch>
        <ContentRoute path="/product_management/stocks/new" component={ProductSkuAdd} />
        <ContentRoute path="/product_management/stocks/:id/edit" render={_handleEdit} />
        <ContentRoute path="/product_management/stocks/:id" component={_productSkuInfo} />
        <ContentRoute component={ProductSkuCard} />
      </Switch>
    </ProductSkuUIProvider>
  );
};
export default ProductSkuPage;
