import React, { useEffect, useState } from "react";
import { ModalProgressBar, } from '../../components/common/controls';
import { get } from "../../helpers";
import {
  ChangePassword,
  ProfileCard,
  PersonalInformation,
  AccountInformation,
  UserCart,
  UserGeneralInfo,
  UserOrder,
  UserCompany,
  UserAddress,
  UserFavorite,
  UserLastVisit,
  UserSearchHistory,
  Role,
  UserNotifications,
  UserDevices
} from "./profile";
import { useUserUIContext } from "./UIContext";
import { Redirect, Route, Switch } from "react-router-dom";


const UserInformation = ({ user_id }) => {
  const [loading, setLoading] = useState(false);
  const { userForEdit:user , setUserForEdit } = useUserUIContext();

  const UserAddress_ = () => <UserAddress id={user?.id}/>;
  const UserCompany_ = () => <UserCompany data={user?.company}/>;
  const UserSearchHistory_ = () => <UserSearchHistory id={user?.id}/>;
  const UserOrder_ = () => <UserOrder id={user?.id}/>;
  const UserFavorite_ = () => <UserFavorite id={user?.id}/>;
  const UserNotifications_ = () => <UserNotifications id={user?.id}/>;
  const UserDevices_ = () => <UserDevices id={user?.id}/>;
  const UserLastVisit_ = () => <UserLastVisit id={user?.id}/>;
  const UserCart_ = () => <UserCart id={user?.id}/>;
  const Role_ = () => <Role role_ids={user?.role_ids} id={user?.id} company={user?.company} />;

  const getUser = async () => {
    if(user_id){
      setLoading(true);
      const { user } = await get('/user/admin/' + user_id);
      await setUserForEdit(user);
      setLoading(false);
    }

  };
  useEffect(() => {
    getUser();
  }, []);

  return (
    <React.Fragment>
      {loading && <ModalProgressBar />}
      <div className="d-flex flex-row">
        {user && <ProfileCard user={user} getUser={getUser}/>}
        <div className="flex-row-fluid ml-lg-8">
          <Switch>
            <Redirect
              from="/user_management/users/:id"
              exact={true}
              to="/user_management/users/:id/overview"
            />
            <Route
              path="/user_management/users/:id/overview"
              component={UserGeneralInfo}
            />
            <Route
              path="/user_management/users/:id/account-information"
              component={AccountInformation}
            />
            <Route
              path="/user_management/users/:id/change-password"
              component={ChangePassword}
            />
            <Route
              path="/user_management/users/:id/information"
              component={PersonalInformation}
            />
            <Route
              path="/user_management/users/:id/role"
              component={Role_}
            />
            {user && user.company && <React.Fragment>
              <Route
                path="/user_management/users/:id/cart"
                component={UserCart_}
              />
              <Route
                path="/user_management/users/:id/favorite"
                component={UserFavorite_}
              />
              <Route
                path="/user_management/users/:id/last-visit"
                component={UserLastVisit_}
              />
              <Route
                path="/user_management/users/:id/search-history"
                component={UserSearchHistory_}
              />
              <Route
                path="/user_management/users/:id/order"
                component={UserOrder_}
              />
              <Route
                path="/user_management/users/:id/company"
                component={UserCompany_}
              />
              <Route
                path="/user_management/users/:id/notifications"
                component={UserNotifications_}

              />
              <Route
                path="/user_management/users/:id/devices"
                component={UserDevices_}
              />
              <Route
                path="/user_management/users/:id/address"
                component={UserAddress_}
              />
            </React.Fragment>}
          </Switch>
        </div>
      </div>
    </React.Fragment>
  );
};
export default UserInformation;
