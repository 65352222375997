import React, { useEffect, useMemo, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import {
  NoRecordsFoundMessage,
  sortCaret,
  headerSortingClasses, post, getHandlerTableChange,
} from '../../../helpers';
import * as uiHelpers from "../UiHelpers";
import { Pagination } from "../../../components/common/controls";
import {
  NameColumnFormatter,
} from './column-formatters';

const ProductCollectionTable = ({ product_id }) => {
  const [queryParams, setQueryParams] = useState({ filter: {
    name: "",
  },
  sortOrder: "asc", // asc||desc
  sortField: "id",
  pageNumber: 1,
  pageSize: 10 });
  const [product_collections, setCollections] = useState([]);
  const [total_count, setTotalCount] = useState([]);
  useEffect(() => {
    const getCollections = async () => {

      const { product_collections, total_count, status } = await post('/product/collection/'+product_id, { ...queryParams });
      if(!status){

        return;
      }
      setTotalCount(total_count);
      setCollections(product_collections);

    };
    getCollections();
  }, []);

  const columns = [
    {
      dataField: "name",
      text: "Koleksiyon",
      sort: true,
      sortCaret: sortCaret,
      formatter: NameColumnFormatter,
      headerSortingClasses,
    },
  ];

  const customTotal = (from, to, size) => {
    return (
      <span className="react-bootstrap-table-pagination-total">
        { from } - { to } Toplam { size }
      </span>
    );
  };

  const paginationOptions = {
    custom: true,
    totalSize: total_count,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: 10,
    page: 1,
    paginationTotalRenderer: customTotal,
  };
  return (
    <PaginationProvider pagination={paginationFactory(paginationOptions)}>
      {({ paginationProps, paginationTableProps }) => {
        return (
          <Pagination
            paginationProps={paginationProps}
          >
            <BootstrapTable
              wrapperClasses="table-responsive"
              classes="table table-head-custom table-head-bg table-vertical-center overflow-hidden"
              bootstrap4
              bordered={false}
              remote
              keyField="id"
              data={product_collections === null ? [] : product_collections}
              columns={columns}
              onTableChange={getHandlerTableChange(
                setQueryParams
              )}
              defaultSorted={uiHelpers.defaultSorted}
              {...paginationTableProps}
              noDataIndication={ () => <NoRecordsFoundMessage /> } />
          </Pagination>
        );
      }}
    </PaginationProvider>
  );
};
export default ProductCollectionTable;
