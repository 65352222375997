import React from "react";
import { Link } from "react-router-dom";
import RatingIcons from "../../component/RatingIcons";
import { Badge } from "react-bootstrap";

const NameColumnFormatter = (cellContent, row) =>{
  const { name, attributes_map, stock, on_sale, tag } = row;
  return (
    <React.Fragment>
      <Link to={`/product_management/stocks/${row.id}`} className="text-dark-75 font-weight-bold text-hover-info mb-1">{name}</Link>
      <div className={'font-weight-light text-muted'}>
        {
          Object.keys(attributes_map).map((key, i) =>
            <span key={i}>
              <span>{key} : </span><span>{attributes_map[key]} </span>
            </span>
          )
        }
      </div>
      <RatingIcons rating={row.rating.score} />
      <div className="font-weight-light font-size-sm">
        Stok {stock.quantity} {stock.type} ( Birim: {stock.unit} Metrekare )
      </div>
      <div className="font-weight-light font-size-xs">
        Oluşturma Tarihi: {row.create_date}
      </div>
      <div className={'mt-2'}>
        {!on_sale ? <Badge pill variant="danger">Satışta Değil</Badge> : <Badge pill variant="success">Satışta</Badge>}
        {tag && <Badge pill className={'ml-2'} variant="danger">{tag}</Badge>}
      </div>
    </React.Fragment>
  );
};
export default NameColumnFormatter;
