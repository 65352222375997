import React, { useEffect, useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { CategoryForm } from './index';
import { useCategoryUIContext } from '../UIContext';
import { get, post, put } from '../../../helpers';

const CategoryAdd = ({ id, show, onHide }) => {
  const [title, setTitle] = useState('');
  const [categoryForEdit, setCategoryForEdit] = useState(undefined);
  const categoryUIContext = useCategoryUIContext();
  const { queryParams, initCategory } = categoryUIContext;

  const categoryUIProps = useMemo(() => {
    return {
      setError: categoryUIContext.setError,
      setState: categoryUIContext.setState,
    };
  }, [categoryUIContext]);

  const getCategory = async () => {
    if(!id){
      return;
    }
    const { category } = await get('category/'+id);
    setCategoryForEdit(category);
  };

  useEffect(() => {
    getCategory();
  }, [id]);

  useEffect(() => {
    let _title = id ? "" : "Yeni Kategori";
    if (categoryForEdit && id) {
      _title = `${categoryForEdit.name} Düzenle`;
    }
    setTitle(_title);
  }, [categoryForEdit]);

  const saveCategory = async (category) => {
    if (!id) {
      const { status, message } = await post('category/add', category);
      if(!status){
        categoryUIProps.setError(message);
        onHide();
        return;
      }
    } else {
      const { status, message } = await put('category/'+id, category);
      if(!status){
        categoryUIProps.setError(message);
        onHide();
        return;
      }
    }
    const { categories,status, message, total_count } = await post('/category/list', { ...queryParams });
    if(!status){
      categoryUIProps.setError(message);
      return;
    }
    categoryUIProps.setError(null);
    await categoryUIProps.setState({ categories, total_count });
    onHide();
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <CategoryForm
        saveCategory={saveCategory}
        category={categoryForEdit || initCategory}
        onHide={onHide}
      />
    </Modal>
  );
};
export default CategoryAdd;
