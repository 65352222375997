import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { useUserUIContext } from '../UIContext';
import { delete_, post } from '../../../helpers';

const UserDeleteDialog = ({ id, show, onHide }) => {
  const userUIContext = useUserUIContext();
  const userUIProps = useMemo(() => {
    return {
      setError: userUIContext.setError,
      setState: userUIContext.setState
    };
  }, [userUIContext]);

  const { queryParams } = userUIContext;

  useEffect(() => {
    if (!id) {
      onHide();
    }
  }, [id]);


  const deleteUser = async () => {
    const { status, message } = await delete_(`user/delete/${id}`);
    if(!status){
      userUIProps.setError(message);
      onHide();
      return;
    }
    const { users,status:_status, message:_message, total_count } = await post('/user/list', { ...queryParams });
    if(!_status){
      userUIProps.setError(_message);
      return;
    }
    await userUIProps.setState({ users, total_count });
    onHide();
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
    >
      <Modal.Header closeButton>
        <Modal.Title>
            Durum Güncelle
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span>Seçtiğiniz kullanıcının durumu değiştirilecek. Devam etmek istiyor musunuz?</span>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={onHide}
            className="btn btn-light btn-elevate"
          >
              İptal
          </button>
          <button
            type="button"
            onClick={deleteUser}
            className="btn btn-danger btn-elevate"
          >
              Güncelle
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
export default UserDeleteDialog;
