export const UserSourceCssClasses = ["","primary", "secondary", "success", "danger"];
export const UserSourceTitles = ["","WEB", "MOBILE", "CMS", "CLI"];
export const defaultSorted = [{ dataField: "id", order: "desc" }];
export const sizePerPageList = [
  { text: "3", value: 3 },
  { text: "5", value: 5 },
  { text: "10", value: 10 }
];
export const initialFilter = {
  filter: {
    name: "",
  },
  sortOrder: "desc", // asc||desc
  sortField: "id",
  pageNumber: 1,
  pageSize: 10
};
