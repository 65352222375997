import React from "react";

const NameColumnFormatter = (cellContent, row) =>{
  return (
    <React.Fragment>
      <a href={`/product_management/campaigns/${row.id}/information`} className="text-dark-75 font-weight-bolder text-hover-primary mb-1">{row.name}</a>
    </React.Fragment>
  );
};
export default NameColumnFormatter;
