import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { put, toAbsoluteUrl } from "../../../helpers";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import { MESSAGES } from "../../../constant/helper";

const CompanyProfile = ({ company, getCompany }) => {
  const { acceptedFiles, getInputProps } = useDropzone();
  const getUserPic = () => {
    if (!company?.image) {
      return "none";
    }
    return company.image;
  };
  const fileToBase64 = async (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (e) => reject(e);
    });
  const _handleUpload = async () => {
    toast.info(MESSAGES.uploadStart);
    const imageStr = await fileToBase64(acceptedFiles[0]);
    const credentials = {
      name: company.name,
      image: imageStr.split(',')[1]
    };
    const { status } = await put('/company/admin/'+company.id, credentials);
    if(!status){
      return;
    }
    toast.success(MESSAGES.uploadEnd);
    getCompany();
  };
  useEffect(() => {
    if (acceptedFiles.length > 0) {
      _handleUpload();
    }
  },[acceptedFiles]);
  return (
    <React.Fragment>
      {company &&
        <div className="flex-row-auto offcanvas-mobile w-200px w-xxl-300px" id="kt_profile_aside">
          <div className="card card-custom card-stretch">
            <div className="card-body" style={{ padding: '1rem' }}>
              <div className="d-flex align-items-center flex-column">
                <div className="image-input image-input-outline" id="kt_profile_avatar" style={{
                  backgroundImage: `url(${toAbsoluteUrl(
                    "/media/users/blank.png"
                  )}`,
                }}
                >
                  <div
                    className="image-input-wrapper"
                    style={{ backgroundImage: `url(${getUserPic()})` }}
                  />
                  <label
                    className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                    data-action="change"
                    data-toggle="tooltip"
                    title=""
                    data-original-title="Change avatar"
                  >
                    <i className="fa fa-pen icon-sm text-muted" />
                    <input
                      {...getInputProps()}
                      type="file"
                      accept=".png, .jpg, .jpeg"
                    />
                    <input type="hidden" name="profile_avatar_remove" />
                  </label>
                </div>

                <div className="mt-10">
                  <div className="font-weight-bold font-size-h5 text-dark-75">
                    {company.name}
                  </div>
                  <div className="text-muted">{company.code}</div>
                </div>
              </div>

              <div className="navi navi-bold navi-hover navi-active navi-link-rounded mt-md-5">
                <div className="navi-item mb-2">
                  <NavLink
                    to={'/seller_management/companies/'+company.id+'/overview'}
                    className="navi-link py-4"
                    activeClassName="active"
                  >
                    <span className="navi-icon mr-2">
                      <span className="svg-icon">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Design/Layers.svg"
                          )}
                        />
                      </span>
                    </span>
                    <span className="navi-text font-size-lg">Firma Önizleme</span>
                  </NavLink>
                </div>
              </div>
              <div className="navi navi-bold navi-hover navi-active navi-link-rounded">
                <div className="navi-item mb-2">
                  <NavLink
                    to={'/seller_management/companies/'+company.id+'/orders'}
                    className="navi-link py-4"
                    activeClassName="active"
                  >
                    <span className="navi-icon mr-2">
                      <span className="svg-icon">
                        <SVG src={toAbsoluteUrl("/media/svg/icons/Layout/Layout-top-panel-6.svg")} />
                      </span>
                    </span>
                    <span className="navi-text font-size-lg">Siparişler</span>
                  </NavLink>
                </div>
              </div>
              <div className="navi navi-bold navi-hover navi-active navi-link-rounded">
                <div className="navi-item mb-2">
                  <NavLink
                    to={'/seller_management/companies/'+company.id+'/address'}
                    className="navi-link py-4"
                    activeClassName="active"
                  >
                    <span className="navi-icon mr-2">
                      <span className="svg-icon">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Text/Article.svg"
                          )}
                        />
                      </span>
                    </span>
                    <span className="navi-text font-size-lg">Adresler</span>
                  </NavLink>
                </div>
              </div>
              <div className="navi navi-bold navi-hover navi-active navi-link-rounded">
                <div className="navi-item mb-2">
                  <NavLink
                    to={'/seller_management/companies/'+company.id+'/users'}
                    className="navi-link py-4"
                    activeClassName="active"
                  >
                    <span className="navi-icon mr-2">
                      <span className="svg-icon">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/General/User.svg"
                          )}
                        />
                      </span>
                    </span>
                    <span className="navi-text font-size-lg">Kullanıcılar</span>
                  </NavLink>
                </div>
              </div>
              <div className="navi navi-bold navi-hover navi-active navi-link-rounded">
                <div className="navi-item mb-2">
                  <NavLink
                    to={'/seller_management/companies/'+company.id+'/prices'}
                    className="navi-link py-4"
                    activeClassName="active"
                  >
                    <span className="navi-icon mr-2">
                      <span className="svg-icon">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Shopping/Dollar.svg"
                          )}
                        />
                      </span>
                    </span>
                    <span className="navi-text font-size-lg">Fiyat Listeleri</span>
                  </NavLink>
                </div>
              </div>
              <div className="navi navi-bold navi-hover navi-active navi-link-rounded">
                <div className="navi-item mb-2">
                  <NavLink
                    to={'/seller_management/companies/'+company.id+'/payment'}
                    className="navi-link py-4"
                    activeClassName="active"
                  >
                    <span className="navi-icon mr-2">
                      <span className="svg-icon">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Shopping/ATM.svg"
                          )}
                        />
                      </span>
                    </span>
                    <span className="navi-text font-size-lg">Ödeme Yöntemleri</span>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </React.Fragment>
  );
};
export default CompanyProfile;
