import React, { Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import {
  AuthPage,
  CategoryPage,
  DashboardPage,
  SubCategoryPage,
  CompanyPage,
  ProductAttributePage,
  ProductPage,
  RolePage,
  UserPage,
  ProductTypePage,
  ProductAttributeValuePage,
  ProductSkuPage,
  OrderPage,
  CampaignPage,
  CollectionPage, BrandPage, AddressPage,
} from './pages';
import { useStores } from './store';
import { ContentRoute, Layout } from './components';
import { SplashScreen } from './components';
import { observer } from 'mobx-react-lite';
import { MessagePage } from "./pages/Messages";

const Routes = observer(() => {
  const { userInfo } = useStores();
  const { tokens } = userInfo;
  const isAuthenticated = !!(tokens && tokens.access_token);

  return (
    <Switch>
      {!isAuthenticated ?
        <Route>
          <AuthPage />
        </Route>
        :
        <Layout>
          <Suspense fallback={<SplashScreen />}>
            <Switch>
              <Redirect exact from="/" to="/dashboard"/>
              <ContentRoute path="/dashboard" component={DashboardPage}/>
              <ContentRoute path="/content/sub_categories" component={SubCategoryPage}/>
              <ContentRoute path="/content/categories" component={CategoryPage}/>
              <ContentRoute path="/product_management/brands" component={BrandPage}/>
              <ContentRoute path="/product_management/product_types" component={ProductTypePage}/>
              <ContentRoute path="/product_management/attribute_values" component={ProductAttributeValuePage}/>
              <ContentRoute path="/product_management/attributes" component={ProductAttributePage}/>
              <ContentRoute path="/product_management/stocks" component={ProductSkuPage}/>
              <ContentRoute path="/product_management/products" component={ProductPage}/>
              <ContentRoute path="/product_management/campaigns" component={CampaignPage}/>
              <ContentRoute path="/product_management/collections" component={CollectionPage}/>
              <ContentRoute path="/order_management/orders" component={OrderPage}/>
              <ContentRoute path="/user_management/roles" component={RolePage}/>
              <ContentRoute path="/user_management/users" component={UserPage}/>
              <ContentRoute path="/user_management/messages" component={MessagePage}/>
              <ContentRoute path="/seller_management/companies" component={CompanyPage}/>
              <ContentRoute path="/seller_management/addresses" component={AddressPage}/>
            </Switch>
          </Suspense>
        </Layout>
      }
    </Switch>
  );
});
export default Routes;
