import React, { useEffect, useMemo } from "react";
import {
  getHandlerTableChange,
  sortCaret,
  headerSortingClasses, post,
} from '../../../helpers';
import * as uiHelpers from "../UiHelpers";
import { useAddressUIContext } from '../UIContext';
import { AddressColumnFormatter } from './column-formatters';
import { DataTable, DefaultActionsColumnFormatter, DefaultStatusColumnFormatter } from "../../../components";
import { useHistory } from "react-router-dom";

const AddressTable = () => {
  const { push } = useHistory();
  const UIContext = useAddressUIContext();
  const openEditDialog = (id) => {
    push(`/seller_management/addresses/${id}/edit`);
  };
  const openDeleteDialog = (id) => {
    push(`/seller_management/addresses/${id}/delete`);
  };
  const UIProps = useMemo(() => {
    return {
      setQueryParams: UIContext.setQueryParams,
      setState: UIContext.setState,
      setError: UIContext.setError,
      setListLoading: UIContext.setListLoading,
    };
  }, [UIContext]);

  const { state: { total_count, addresses }, listLoading, queryParams } = UIContext;
  useEffect(() => {
    const getAddresses = async () => {
      UIProps.setListLoading(true);
      const { addresses, status, message, total_count } = await post('/address/admin/list', { ...queryParams });
      UIProps.setListLoading(false);
      if(!status){
        UIProps.setError(message);
        return;
      }
      UIProps.setError(null);
      UIProps.setState({ addresses, total_count });
    };
    getAddresses();
    return () => {
      UIProps.setError(null);
    };
  }, [queryParams]);
  // Table columns
  const columns = [
    {
      dataField: "id",
      text: "ID",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      style: {
        width: "40px",
      },
    },
    {
      dataField: "title",
      text: "",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "name",
      text: "Adres",
      formatter: AddressColumnFormatter,
    },
    {
      dataField: "phone1",
      text: "Tel",
    },
    {
      dataField: "city.name",
      text: "İl",
    },
    {
      dataField: "county.name",
      text: "İlçe",
    },
    {
      dataField: "is_active",
      text: "Durum",
      formatter: DefaultStatusColumnFormatter,
      classes: "text-center",
      headerClasses: "text-center",
      style: {
        width: "60px",
      },
    },
    {
      dataField: "action",
      text: "İşlem",
      formatter: DefaultActionsColumnFormatter,
      formatExtraData: {
        openEditDialog,
        openDeleteDialog,
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        width: "100px",
      },
    },
  ];
  const paginationOptions = {
    custom: true,
    totalSize: total_count,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: queryParams.pageSize,
    page: queryParams.pageNumber,
  };

  return (
    <DataTable
      data={addresses || []}
      defaultSorted={uiHelpers.defaultSorted}
      columns={columns}
      paginationOptions={paginationOptions}
      handleTableChange={getHandlerTableChange(
        UIContext.setQueryParams
      )}
      listLoading={listLoading} />
  );
};
export default AddressTable;
