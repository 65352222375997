import React, { useEffect, useState } from "react";
import { headerSortingClasses, get, sortCaret } from "../../../helpers";
import {
  LogoColumnFormatter,
  NameColumnFormatter,
  StockStatusColumnFormatter
} from "../../ProductSkus/product-sku-table/column-formatters";
import { DataTable, DefaultStatusColumnFormatter } from "../../../components";
import * as uiHelpers from "../UiHelpers";

const UserLastVisit = ({ id }) =>{


  const handleTableChange = (type, { page, sizePerPage }) => {
    return null;
  };

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const getData = async () => {
    setLoading(true);
    const { product_skus } = await get('/last_visit/admin/list/' + id);
    setData(product_skus);
    setLoading(false);
  };

  const columns = [
    {
      dataField: "id",
      text: "ID",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      style: {
        width: "40px",
      },
    },
    {
      dataField: "image",
      text: "",
      formatter: LogoColumnFormatter,
      headerSortingClasses,
      style: {
        width: "80px",
      },
    },
    {
      dataField: "name",
      text: "Ürün Adı",
      sort: true,
      sortCaret: sortCaret,
      formatter: NameColumnFormatter,
      headerSortingClasses,
    },
    {
      dataField: "type.name",
      text: "Tür",
    },
    {
      dataField: "brand.name",
      text: "Marka",
    },
    {
      dataField: "stock.in_stock",
      text: "Stok",
      formatter: StockStatusColumnFormatter,
      classes: "text-center pr-0",
      headerClasses: "text-center pr-0",
      style: {
        width: "80px",
      },
    },
    {
      dataField: "is_active",
      text: "Durum",
      formatter: DefaultStatusColumnFormatter,
      classes: "text-center pr-0",
      headerClasses: "text-center pr-0",
      style: {
        width: "80px",
      },
    },
  ];

  useEffect(() => {
    getData();
  },[]);

  const paginationOptions = {
    custom: true,
    totalSize: 10,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: 10,
    page: 1,
  };

  return (
    <div className="card card-custom">
      <div className="card-header py-3">
        <div className="card-title align-items-start flex-column">
          <h3 className="card-label font-weight-bolder text-dark">
            Son Ziyaret Edilenler
          </h3>
          <span className="text-muted font-weight-bold font-size-sm mt-1">
            Kullanıcının ziyaret ettiği ürünleri görüntüleyin
          </span>
        </div>
      </div>
      <div className="card-body">
        <DataTable
          data={data || []}
          defaultSorted={uiHelpers.defaultSorted}
          columns={columns}
          paginationOptions={paginationOptions}
          handleTableChange={handleTableChange}
          listLoading={loading} />
      </div>
    </div>
  );
};

export default UserLastVisit;
