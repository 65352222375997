import React, { useEffect, useMemo, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from 'react-bootstrap-table2-paginator';
import {
  NoRecordsFoundMessage,
  sortCaret,
  headerSortingClasses, post, getHandlerTableChange,
} from '../../../helpers';
import * as uiHelpers from "../UiHelpers";
import {
  NameColumnFormatter,
} from './column-formatters';

const ProductCampaignTable = ({ product_id }) => {
  const [queryParams, setQueryParams] = useState({ filter: {
    name: "",
  },
  sortOrder: "asc", // asc||desc
  sortField: "id",
  pageNumber: 1,
  pageSize: 10 });
  const [product_campaigns, setCampaigns] = useState([]);
  const [total_count, setTotalCount] = useState([]);
  useEffect(() => {
    const getCampaigns = async () => {

      const { product_campaigns, total_count, status } = await post('/product/campaign/'+product_id, { ...queryParams });
      if(!status){

        return;
      }
      setTotalCount(total_count);
      setCampaigns(product_campaigns);

    };
    getCampaigns();
  }, []);

  const columns = [
    {
      dataField: "name",
      text: "Kampanya",
      sort: true,
      sortCaret: sortCaret,
      formatter: NameColumnFormatter,
      headerSortingClasses,
    },
    {
      dataField: "start_date",
      text: "Başlangıç",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "end_date",
      text: "Bitiş",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "status",
      text: "Durum",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
  ];

  return (
    <BootstrapTable
      wrapperClasses="table-responsive"
      classes="table table-head-custom table-head-bg table-vertical-center overflow-hidden"
      bootstrap4
      bordered={false}
      remote
      keyField="id"
      data={product_campaigns === null ? [] : product_campaigns}
      columns={columns}
      onTableChange={getHandlerTableChange(
        setQueryParams
      )}
      defaultSorted={uiHelpers.defaultSorted}
      pagination={ paginationFactory() }
      noDataIndication={<NoRecordsFoundMessage />}/>
  );
};
export default ProductCampaignTable;
