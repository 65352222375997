import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
} from '../../components/common/controls';
import { get, post, delete_ } from "../../helpers";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import styles from "../Orders/css/order.module.css";
import { toast } from "react-toastify";
import { AsyncTypeahead } from "react-bootstrap-typeahead";

const CollectionInformation = ({ id }) => {
  const [tab, setTab] = useState("general");
  const [data, setData] = useState({});
  const [collectionProducts, setCollectionProducts] = useState([]);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);

  const moveToGeneralTab = () => setTab("general");
  const moveToProductTab = () => setTab("product");

  const getCampaign = async () => {
    if (id) {
      const { collection } = await get('/collection/admin/' + id);
      setData(collection);
    }
  };

  const getProducts = async (productName) => {
    setLoading(true);
    const queryParams= {
      pageNumber:1,
      pageSize:10,
      sortField: "id",
      sortOrder: "desc",
      filter:{
        name: productName
      }
    };
    const { product_skus } = await post('/product/admin/sku/list', { ...queryParams });
    setProducts(product_skus);
    setLoading(false);
  };

  const getCollectionProducts = async () => {
    const { product_skus } = await get('/collection/admin/product/list/'+parseInt(id));
    setCollectionProducts(product_skus);
  };

  const addCollectionProduct = async (product_id) => {
    const { status, message } = await post('/collection/admin/product/add', { collection_id: parseInt(id), product_id });
    if (status) {
      getCollectionProducts();
    } else {
      toast.info(message);
    }
  };

  const deleteCollectionProduct = async (product_id) => {
    const { status } = await delete_(`/collection/admin/product/${parseInt(id)}/${product_id}`);
    if (status) {
      getCollectionProducts();
    }
  };

  const _handleProductChange = (item)=> {
    if(item.length > 0){
      addCollectionProduct(item[0].id);
    }
  };
  const filterBy = () => true;
  const _handleSearch = async (query) => {
    getProducts(query);
  };

  useEffect(() => {
    getCampaign();
    getCollectionProducts();
  }, []);

  return (
    <Card>
      <CardHeader title={'Ürün Bilgileri'}/>
      <CardBody>
        {
          data.id ?
            <React.Fragment>
              <ul className="nav nav-tabs nav-tabs-line " role="tablist">
                <li className="nav-item" onClick={moveToGeneralTab}>
                  <a
                    className={`nav-link ${tab === "general" && "active"}`}
                    data-toggle="tab"
                    role="tab"
                    aria-selected={(tab === "general").toString()}
                  >
                        Genel Bilgiler
                  </a>
                </li>
                <li className="nav-item" onClick={moveToProductTab}>
                  <a
                    className={`nav-link ${tab === "product" && "active"}`}
                    data-toggle="tab"
                    role="tab"
                    aria-selected={(tab === "product").toString()}
                  >
                        Ürünler
                  </a>
                </li>
              </ul>
              <div style={{ minHeight: 250 }} className="mt-5">
                <React.Fragment>
                  {tab === "general" &&
                      <Container>
                        <Row>
                          <Col sm={2}><p className={'text-black-50'}>Adı:</p></Col>
                          <Col sm={6}>{data.name}</Col>
                        </Row>
                      </Container>
                  }
                  {tab === "product" &&
                      <Container>
                        <Row>
                          <div className="form-group row">
                            <div className="col-lg-12">
                              <label>Ürün Adı</label>
                              <AsyncTypeahead
                                filterBy={filterBy}
                                id={'productSearch'}
                                isLoading={loading}
                                labelKey="name"
                                minLength={3}
                                onChange={_handleProductChange}
                                onSearch={_handleSearch}
                                options={products}
                                placeholder={'Ürün Adı'}
                              />
                            </div>
                          </div>
                        </Row>
                        {
                          collectionProducts.map(item =>
                            <Row className={styles.container}>
                              <Col className={'text-center'}>
                                <p className={'text-black-50'}>Ürün Adı</p>
                                <p>{item.name}</p>
                              </Col>
                              <Col className={'text-center'}>
                                <p className={'text-black-50'}>Fiyat</p>
                                <p>{item.price ?? '-'}</p>
                              </Col>
                              <Col className={'text-center'}>
                                <p className={'text-black-50'}>Stok ({item.stock.type})</p>
                                <p>{item.stock.quantity}</p>
                              </Col>
                              <Col className={styles.centerButton}>
                                <button onClick={ () => deleteCollectionProduct(item.id)}>Kaldır</button>
                              </Col>
                            </Row>
                          )
                        }
                      </Container>
                  }
                </React.Fragment>
              </div>
            </React.Fragment>
            :
            <Spinner animation="border"/>
        }
      </CardBody>
    </Card>
  );
};
export default CollectionInformation;
