import React from "react";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../helpers";

const SubCategoryStatusCssClasses = ["danger", "success"];

const DefaultStatusColumnFormatter = (cellContent, row) => {
  const getLabelCssClasses = () => {
    return `svg-icon svg-icon-md svg-icon-${
      SubCategoryStatusCssClasses[row.status]
    } label-inline`;
  };
  return (
    <span className={getLabelCssClasses()}>
      <SVG src={toAbsoluteUrl("/media/svg/icons/General/gen024.svg")}/>
    </span>
  );
};
export default DefaultStatusColumnFormatter;
