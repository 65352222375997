import React, { useState, useEffect, useRef } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar, FormAlert,
} from '../../components/common/controls';
import { useHistory } from 'react-router-dom';
import { CampaignForm } from './CampaignForm';
import { post, get, put } from '../../helpers';
import { useCampaignUIContext } from './CampaignUIContext';
import moment from 'moment';
import 'moment/locale/tr';


const CampaignAdd = ({ id }) => {
  const history = useHistory();
  const { initCampaign } = useCampaignUIContext();
  const [error, setError] = useState(null);
  const [campaignForEdit, setCampaignForEdit] = useState(undefined);
  const btnRef = useRef();
  const saveCampaignClick = () => {
    if (btnRef && btnRef.current) {
      btnRef.current.click();
    }
  };

  const backToCampaignsList = () => {
    history.push(`/product_management/campaigns`);
  };
  const saveCampaign = async (campaign) => {
    const _campaign = {
      ...campaign,
      start_date: moment(campaign.start_date).format(),
      end_date: moment(campaign.end_date).format(),
    }
    if (!id) {
      const { status, message } = await post('/campaign/admin/add', _campaign);
      if (!status) {
        setError(message);
        return;
      }
    }
    else{
      const { status, message } = await put('/campaign/admin/'+id, _campaign);
      if (!status) {
        setError(message);
        return;
      }
    }
    backToCampaignsList();
  };
  const getCampaign = async () => {
    if(!id){
      return;
    }
    const { campaign, status } = await get('/campaign/admin/'+ id);
    if (status) {
      const sd= campaign.start_date.split(' ');
      const ed= campaign.end_date.split(' ');

      const formattedSd = moment(`${sd[0]} ${sd[1]} ${sd[2]}`, 'Do MMMM YYYY').format('Do MM YYYY').split(' ')
      const formattedEd = moment(`${ed[0]} ${ed[1]} ${ed[2]}`, 'Do MMMM YYYY').format('Do MM YYYY').split(' ')

      const data = {
        ...campaign,
        start_date: new Date(formattedSd[2], formattedSd[1] -1, formattedSd[0]),
        end_date: new Date(formattedEd[2], formattedEd[1] -1, formattedEd[0]),
      };
      setCampaignForEdit(data);
    }
  };
  useEffect(() => {
  }, [campaignForEdit]);
  useEffect(() => {
    getCampaign();
  },[id]);

  return (
    <React.Fragment>
      <FormAlert visible={error !== null} message={error} />
      <Card>
        <CardHeader title={'Genel Bilgi'}>
          <CardHeaderToolbar>
            <button
              type="button"
              onClick={backToCampaignsList}
              className="btn btn-light"
            >
              <i className="fa fa-arrow-left" />
              Geri
            </button>
            <button
              type="submit"
              className="btn btn-primary ml-2"
              onClick={saveCampaignClick}
            >
              Kaydet
            </button>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <CampaignForm id={id} setError={setError} campaign={campaignForEdit || initCampaign} btnRef={btnRef} saveCampaign={saveCampaign} />
        </CardBody>
      </Card>
    </React.Fragment>
  );
};
export default CampaignAdd;
