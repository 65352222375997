import React, { useEffect, useMemo, useState } from 'react';
import { Formik } from "formik";
import Select from 'react-select';
import { isEqual } from "lodash";
import Dropdown from "react-bootstrap/Dropdown";
import { useSubCategoryUIContext } from '../UIContext';
import { post } from '../../../helpers';

const prepareFilter = (queryParams, values) => {
  const { status, searchText, parent_id } = values;
  const newQueryParams = { ...queryParams };
  const filter = {};
  if (status) filter.status = status;
  if (parent_id > 0) {
    filter.parent_id = parent_id !== "" ? [parent_id] : undefined;
  }
  if (searchText) {
    filter.name = searchText;
  }
  newQueryParams.filter = filter;
  return newQueryParams;
};

const SubCategoryFilter = () => {
  const [categories, setCategories] = useState([]);
  const subCategoryUiContext = useSubCategoryUIContext();
  const subCategoryUIProps = useMemo(() => {
    return {
      queryParams: subCategoryUiContext.queryParams,
      setQueryParams: subCategoryUiContext.setQueryParams,
    };
  }, [subCategoryUiContext]);
  const applyFilter = (values) => {
    const newQueryParams = prepareFilter(subCategoryUIProps.queryParams, values);
    if (!isEqual(newQueryParams, subCategoryUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      subCategoryUIProps.setQueryParams(newQueryParams);
    }
  };

  const getCategories = async () => {
    const { categories } = await post('/category/list', { });
    let categories_ = categories.map(item => {
      return { value: item.id, label: item.name };
    });
    categories_ = [{ value: 0, label: 'Tümü' }, ...categories_];
    setCategories(categories_);
  };

  useEffect(() => {
    getCategories();
  }, []);

  const initialValues = {
    status: "1",
    searchText: "",
  };
  const statusOptions = [
    { value: '', label: 'Tümü' },
    { value: '1', label: 'Aktif' },
    { value: '0', label: 'Pasif' }
  ];
  const _handleSubmit = (values) => applyFilter(values);

  return (
    <Formik initialValues={initialValues} onSubmit={_handleSubmit}>
      {({ values, handleSubmit, handleBlur, handleChange, setFieldValue }) =>
      {
        const _handleSelectChange = (item)=>{
          setFieldValue("status", item.value);
        };
        const _handleCategorySelectChange = (item)=>{
          setFieldValue("parent_id", item.value);
        };
        return(
          <form onSubmit={handleSubmit}>
            <div className="form-group row">
              <div className="col-lg-2">
                <input
                  type="text"
                  className="form-control"
                  name="searchText"
                  placeholder="Alt Kategori"
                  onBlur={handleBlur}
                  value={values.searchText}
                  onChange={(e) => {
                    setFieldValue("searchText", e.target.value);
                    handleSubmit();
                  }}
                />
              </div>
              <div className="col-lg-10">
                <Dropdown onClick={(e) => e.stopPropagation(e)} drop="down" className="float-right ml-2">
                  <Dropdown.Toggle>
                    Filtreleme Seçenekleri
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
                    <div className="navi navi-spacer-x-0 p-5">
                      <div className="form-group row">
                        <div className="col-lg-12 mt-2">
                          <Select
                            onChange={_handleCategorySelectChange}
                            options={categories}
                            placeholder={'Kategori'}
                          />
                          <small className="form-text text-muted">
                            Kategoriye <b>Göre</b>
                          </small>
                        </div>
                        <div className="col-lg-12 mt-2">
                          <Select
                            onChange={_handleSelectChange}
                            options={statusOptions}
                            placeholder={'Durum'}
                            value={statusOptions.filter(item => item.value === values.status)}
                          />
                          <small className="form-text text-muted">
                            Duruma <b>Göre</b>
                          </small>
                        </div>
                        <div className="col-lg-12 mt-2">
                          <button type="button" className="btn btn-outline-primary btn-block" onClick={handleSubmit}>
                            Uygula
                          </button>
                        </div>
                      </div>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </form>
        );}
      }
    </Formik>
  );
};
export default SubCategoryFilter;
