import React from 'react';
import { Card, CardBody, FormAlert } from '../../components/common/controls';
import { useProductAttributeValueUIContext } from './UIContext';
import ProductAttributeValueTable from './product-attributevalue-table/ProductAttributeValueTable';
import { ProductAttributeValueFilter } from './product-attributevalue-table';

const ProductAttributeValueCard = () => {
  const { error } = useProductAttributeValueUIContext();

  return (
    <React.Fragment>
      <FormAlert visible={error !== null} message={error} />
      <Card>
        <CardBody>
          <ProductAttributeValueFilter />
          <ProductAttributeValueTable />
        </CardBody>
      </Card>
    </React.Fragment>
  );
};
export default ProductAttributeValueCard;
