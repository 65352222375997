import React from "react";
import { Card, CardBody, FormAlert } from '../../components/common/controls';
import { useUserUIContext } from './UIContext';
import { UserTable, UserFilter } from './user-table';

const UserCard = () => {
  const { error, setError } = useUserUIContext();

  const _handleDismiss = () => {
    setError(null);
  };
  return (
    <React.Fragment>
      <FormAlert visible={error !== null} message={error} onClose={_handleDismiss}/>
      <Card>
        <CardBody>
          <UserFilter />
          <UserTable />
        </CardBody>
      </Card>
    </React.Fragment>
  );
};
export default UserCard;
