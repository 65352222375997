import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader, ModalProgressBar,
} from '../../components/common/controls';
import { get } from "../../helpers";
import { Col, Container, Row } from "react-bootstrap";
import { InformationStock } from "./detail";

const Tab = ({ tab, name, value, moveToTab }) => {
  return(
    <li className="nav-item" onClick={() => moveToTab(value)}>
      <a
        className={`nav-link ${tab === value && "active bg-info"}`}
        data-toggle="tab"
        role="tab"
        aria-selected={(tab === value).toString()}
      >
        {name}
      </a>
    </li>
  );
};

const ProductInformation = ({ id }) => {
  const [tab, setTab] = useState("summary");
  const [data, setData] = useState({});

  const moveToTab = (value) => setTab(value);

  const getProduct = async () => {
    if (id) {
      const { product } = await get('product/' + id);
      setData(product);
    }
  };

  useEffect(() => {
    getProduct();
  }, []);

  return (
    <Card>
      {!data.id && <ModalProgressBar />}
      <CardHeader title={data.name}/>
      <CardBody>
        {
          data.id &&
            <React.Fragment>
              <ul className="nav nav-pills " role="tablist">
                <Tab tab={tab} name={'Özet'} value={'summary'} moveToTab={moveToTab}/>
                <Tab tab={tab} name={'Özellikler'} value={'properties'} moveToTab={moveToTab}/>
                <Tab tab={tab} name={'Stok'} value={'stock'} moveToTab={moveToTab}/>
              </ul>
              <div style={{ minHeight: 250 }} className="mt-5">
                <React.Fragment>
                  {tab === "summary" &&
                    <Container>
                      <Row>
                        <Col sm={2}><p className={'text-black-50'}>Marka:</p></Col>
                        <Col sm={6}>{data.brand.name}</Col>
                      </Row>
                      <Row>
                        <Col sm={2}><p className={'text-black-50'}>Kategori:</p></Col>
                        <Col sm={6}>{data.category.name}</Col>
                      </Row>
                      <Row>
                        <Col sm={2}><p className={'text-black-50'}>Alt Kategori:</p></Col>
                        <Col sm={6}>{data.sub_category.name}</Col>
                      </Row>
                      <Row>
                        <Col sm={2}><p className={'text-black-50'}>Oluşuturulma Tarihi:</p></Col>
                        <Col sm={6}>{data.create_date}</Col>
                      </Row>
                    </Container>
                  }
                  {tab === "properties" &&
                  <Container>
                    {
                      Object.keys(data.attributes).map((key, index) =>
                        <Row key={index}>
                          <Col sm={2}><p className={'text-black-50'}>{key}:</p></Col>
                          <Col sm={6}>{data.attributes[key]}</Col>
                        </Row>
                      )
                    }
                  </Container>
                  }
                  {tab === "stock" &&
                  <InformationStock data={data.sku_list}/>
                  }
                </React.Fragment>
              </div>
            </React.Fragment>
        }
      </CardBody>
    </Card>
  );
};
export default ProductInformation;
